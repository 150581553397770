import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { context } from "../GlobalContext/GlobalContext";
import { MyBids } from "../Service/Service";

const Bid_property = () => {
  let globalData = useContext(context)
  const [bidList, setBidList] = useState([])
  let navigate = useNavigate()


  const Bids = () => {
    globalData.setLoader(true)
    MyBids(globalData.token).then(response => {
      if (response.status === 200) {
        globalData.setLoader(false)
        setBidList(response.data.data)
      }
    }).catch(err => {
      globalData.setLoader(false)
      if (err.response.status === 400) {
        toast.error(err.response.data.message, { position: "top-center" })
      }
      if (err.response.status === 401) {
        globalData.setToken('')
        localStorage.clear()
        toast.info("You session has expired please login again!.")
        navigate('/')
      }
    })
  }

  useEffect(() => {
    globalData.setLoader(true)
    {
      globalData.token &&
        Bids()
    }
    // function nextPage(){
    //   globalData.setLoader(true)
    //   window.location.href ="/";
    // }
// console.log("nnnnnnnnnnnnnn",((globalData.userType=="customer" || localStorage.getItem("userType")=="customer")  && (globalData.token || localStorage.getItem("token"))))
if(globalData.token || localStorage.getItem("token")){
  if((globalData.userType == "seller" || localStorage.getItem("userType") == "seller")){
    window.location.href ="/";
  }
}else{
  window.location.href ="/login";
}    


  }, [globalData.token])


  return (
    <>
      <section className="hero-banner">
        <Container>
          <p className="property-banner-heading">
            <img src={require("../assets/images/play.png")}></img>Properties
          </p>
          <h2>My Bids</h2>
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing
            <br /> and typesetting industry.{" "}
          </p> */}
        </Container>
      </section>
      <section className="favorite-property mt-5">
        <Container>
          <Row>
            {bidList.length > 0 && bidList.map((property) =>
              <Col lg={4} md={6} sm={6} className="mb-3" style={{ cursor: "pointer" }} onClick={() => navigate(`/property_regular?id=${property.id}`)}>
                <div className="favorite-proprty-one">
                  <div className="favorite-property-image">
                    <img src={require("../assets/images/p3.png")} alt="img"></img>
                  </div>
                  <h4>{property.name}</h4>
                  <p>
                    {property.description}
                  </p>
                  <h5>
                    <small>Bid amount:</small> ${Number(property.bid_price).toLocaleString('en-US')}
                  </h5>
                  <div className="service-property">
                    <div className="service-one">
                      <img src={require("../assets/images/bed1.png")}></img>
                      <div>
                        <p>{property.number_of_bedrooms}</p>
                      </div>
                    </div>
                    <div className="service-one">
                      <img src={require("../assets/images/bath1.png")}></img>
                      <div>
                        <p>{property.number_of_full_bathrooms + property.number_of_half_bathrooms}</p>
                      </div>
                    </div>
                    <div className="service-one">
                      <img src={require("../assets/images/balcony1.png")}></img>
                      <div>
                        <p>{property.number_of_balcony}</p>
                      </div>
                    </div>
                    <div className="service-one">
                      <img src={require("../assets/images/food1.png")}></img>
                      <div>
                        <p>{property.number_of_kitchen}</p>
                      </div>
                    </div>
                  </div>
                  {/* <hr />
                <div className="service-property">
                  <div className="service-one">
                    <h6>
                      12 <span>Days</span>
                    </h6>
                  </div>
                  <div className="service-one">
                    <h6>
                      03 <span>Hours</span>
                    </h6>
                  </div>
                  <div className="service-one">
                    <h6>
                      42 <span>Minutes</span>
                    </h6>
                  </div>
                  <div className="service-one">
                    <h6>
                      12 <span>Seconds</span>
                    </h6>
                  </div>
                </div>
                <hr />
                <p className="blue-text">
                  Book now <i className="fa fa-play" aria-hidden="true"></i>
                </p> */}
                </div>
              </Col>
            )}
          </Row>
          {bidList.length === 0 &&
            <h3 className="text-center py-4">No Property Found.</h3>
          }
        </Container>
      </section>

      <section className="house-details">
        <div className="bottom-banner">
          <Container>
            <div className="banner-botton-content">
              <p className="property-banner-heading">
                <img src={require("../assets/images/play.png")}></img>let’s take
                a tour
              </p>
              <h2>
                Property Smarter,<br></br> Quicker & Anywhere
              </h2>
              <Link to="">
                Join now <i className="fa fa-play" aria-hidden="true"></i>
              </Link>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};
export default Bid_property;
