import { useContext, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessModal from "../common/SuccessModal";
import { context } from "../GlobalContext/GlobalContext";
import { ResetPassword } from "../Service/Service";

const Createpassword = () =>{
    const[show,setShow]=useState(false)
    let loc = useLocation()
    const handleClose = (e) => {
        // e.preventDefault()
        setShow(false)
        navigate('/')

    }
    const handleShow = () => setShow(true);
    let globalData= useContext(context)
    const {register ,handleSubmit, formState:{errors},watch}=useForm()
    let navigate = useNavigate();
    function signin(){
        navigate('/')
    }

    const onSubmit=data=>{
        
        data['email']=loc.state.email
        ResetPassword(data).then(res=>{
            if(res.status===200){
                setShow(true)
                // globalData.setShow(true)
            }
        })
    }
    // useEffect(()=>{

    // },[show])
    return(
        <>
        <section className="login-area forgot-area">
                <div className="container">
                    <div className="login-box-area">
                        <div className="row justify-content-center">
                            <aside className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                            <div className="login-box">
                                <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                                    <h2 className="text-center">Create password ? </h2>
                                    <p className="text-center my-2">Your new password must be different from <br />previous used passwords.</p>
                                    <div className="my-4 pt-3">
                                        <label  className="form-label">New password</label>
                                        <input type="password" className="form-control"  placeholder="Enter new password" {...register("new_password",{required:"New Password is required."})} />
                                        {errors.new_password && <span style={{color:"red"}}>{errors.new_password.message}</span>}
                                    </div>
                                    <div className="my-4">
                                        <label  className="form-label" >Confirm password</label>
                                        <input type="password" className="form-control"  placeholder="Enter confirm password" {...register("confirm_password",{required:"Confirm Password is required." ,validate: value => value === watch("new_password") || "Password didn't match." })}/>
                                        {errors.confirm_password && <span style={{color:"red"}}>{errors.confirm_password.message}</span>}
                                    </div>
                                    <button className="btn sign-btn fill-btn w-100 ms-0 mt-3 mb-4 text-white">reset password</button>
                                </form>
                                    <hr />
                                    <p className="text-center py-2 flex-p">
                                        <img src={require('../assets/images/back.svg').default} alt="back" className="me-1" />
                                        Back to <span className="signup ms-1" onClick={signin}> Sign In</span>
                                    </p>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
                
            </section>
            {show !==false&&
                <SuccessModal show={show} onHide={()=>handleClose()} message="Password reset successfully."/>}
            
        </>
    )
}
export default Createpassword;