import React, { useState } from "react";
import { Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";

const Add_listing = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <section className="login-area forgot-area add-listing-page">
        <div className="container">
          <div className="login-box-area">
            <div className="row justify-content-center">
              <aside className="col-md-11">
                <div className="login-box">
                  <div className="login-form">
                    <h2 className="text-center">Add your listing</h2>
                    <Tabs
                      defaultActiveKey="sale"
                      transition={false}
                      id="listing-tab"
                      className="mb-3 listing-tab-top"
                    >
                      <Tab eventKey="sale" title="featured">
                        <Row>
                          <Col md={12}>
                            <div className="my-4 text-center">
                              <div className="upload-img">
                                <Form.Control type="file" />
                                <img
                                  src={require("../../assets/images/dummy.png")}
                                  className="upload-img"
                                ></img>
                                <p>Upload listing images/banners here</p>
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="my-4">
                              <label  className="form-label">
                                Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder="Enter address"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                List Price
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Enter list price"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Style
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Enter style"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Taxes
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Enter taxes"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Building Sqft and Dimensions
                              </label>
                              <Row>
                                <Col md={6}>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter sq"
                                  />
                                </Col>
                                <Col md={6}>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter ft"
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Total rooms Main unit
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Enter total rooms"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Bath
                              </label>
                              <Row>
                                <Col md={6}>
                                  <input
                                    type="Number of full bath"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter sq"
                                  />
                                </Col>
                                <Col md={6}>
                                  <input
                                    type="Number of half bath"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter ft"
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Cofo
                              </label>
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="Yes"
                                  name="group1"
                                  type="radio"
                                  id="radio17"
                                />
                                <Form.Check
                                  inline
                                  label="No"
                                  name="group1"
                                  type="radio"
                                  id="radio27"
                                />
                              </div>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Water view
                              </label>
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="Yes"
                                  name="group2"
                                  type="radio"
                                  id="radio15"
                                />
                                <Form.Check
                                  inline
                                  label="No"
                                  name="group2"
                                  type="radio"
                                  id="radio25"
                                />
                              </div>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Heat source
                              </label>
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="Yes"
                                  name="group24"
                                  type="radio"
                                  id="radio13"
                                />
                                <Form.Check
                                  inline
                                  label="No"
                                  name="group24"
                                  type="radio"
                                  id="radio23"
                                />
                              </div>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Basement
                              </label>
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="Yes"
                                  name="group25"
                                  type="radio"
                                  id="radio1"
                                />
                                <Form.Check
                                  inline
                                  label="No"
                                  name="group25"
                                  type="radio"
                                  id="radio2"
                                />
                              </div>
                            </div>

                            <div className="my-4">
                              <label  className="form-label">
                                Main Bath
                              </label>
                              <div className="mb-3 main-bath-check">
                                <Form.Check
                                  inline
                                  label="Full bath"
                                  type="checkbox"
                                  id="checkbox1"
                                />
                                <Form.Check
                                  inline
                                  label="3 quarter"
                                  type="checkbox"
                                  id="checkbox2"
                                />
                                <Form.Check
                                  inline
                                  label="Bidet"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Skylight"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Jacuzzi"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Half bath"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Separate shower"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                              </div>
                            </div>

                            <div className="my-4">
                              <label  className="form-label">
                                Exterior
                              </label>
                              <div className="mb-3 main-bath-check">
                                <Form.Check
                                  inline
                                  label="Balcony"
                                  type="checkbox"
                                  id="checkbox1"
                                />
                                <Form.Check
                                  inline
                                  label="Grill"
                                  type="checkbox"
                                  id="checkbox2"
                                />
                                <Form.Check
                                  inline
                                  label="Deck"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Patio"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Fence"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Shed Sprinkler
                                  system"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Garage door opener"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                              </div>
                            </div>

                            <div className="my-4">
                              <label  className="form-label">
                                Pool
                              </label>
                              <div className="mb-3 main-bath-check">
                                <Form.Check
                                  inline
                                  label="Above ground"
                                  type="checkbox"
                                  id="checkbox1"
                                />
                                <Form.Check
                                  inline
                                  label="In ground"
                                  type="checkbox"
                                  id="checkbox2"
                                />
                                <Form.Check
                                  inline
                                  label="Indoor"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                              </div>
                            </div>

                            <div className="my-4">
                              <label  className="form-label">
                                Heat source
                              </label>
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="City"
                                  name="group24"
                                  type="radio"
                                  id="radio13"
                                />
                                <Form.Check
                                  inline
                                  label="Cesspool"
                                  name="group24"
                                  type="radio"
                                  id="radio23"
                                />
                                <Form.Check
                                  inline
                                  label="Private"
                                  name="group24"
                                  type="radio"
                                  id="radio13"
                                />
                                <Form.Check
                                  inline
                                  label="Peptic"
                                  name="group24"
                                  type="radio"
                                  id="radio23"
                                />
                                <Form.Check
                                  inline
                                  label="Storm"
                                  name="group24"
                                  type="radio"
                                  id="radio13"
                                />
                                <Form.Check
                                  inline
                                  label="None"
                                  name="group24"
                                  type="radio"
                                  id="radio23"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="my-4">
                              <label  className="form-label">
                                Listing ID
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder="Enter parcel id"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Zoning
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Enter zoning"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Year built
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Enter zoning"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Condition
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Enter condition"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Lot Sqft and Dimensions
                              </label>
                              <Row>
                                <Col md={6}>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter sq"
                                  />
                                </Col>
                                <Col md={6}>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter ft"
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Condition
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Enter condition"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Heating & cooling
                              </label>
                              <Row>
                                <Col md={6}>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter heating"
                                  />
                                </Col>
                                <Col md={6}>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter cooling"
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Garage (location)
                              </label>
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="Yes"
                                  name="group1"
                                  type="radio"
                                  id="radio17"
                                />
                                <Form.Check
                                  inline
                                  label="No"
                                  name="group1"
                                  type="radio"
                                  id="radio27"
                                />
                              </div>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                A/C
                              </label>
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="Central"
                                  name="group2"
                                  type="radio"
                                  id="radio15"
                                />
                                <Form.Check
                                  inline
                                  label="Units"
                                  name="group2"
                                  type="radio"
                                  id="radio25"
                                />
                                <Form.Check
                                  inline
                                  label="None"
                                  name="group2"
                                  type="radio"
                                  id="radio25"
                                />
                              </div>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Heat system
                              </label>
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="Forced air"
                                  name="group24"
                                  type="radio"
                                  id="radio13"
                                />
                                <Form.Check
                                  inline
                                  label="Steam"
                                  name="group24"
                                  type="radio"
                                  id="radio23"
                                />
                                <Form.Check
                                  inline
                                  label="Hot water"
                                  name="group24"
                                  type="radio"
                                  id="radio23"
                                />
                                <Form.Check
                                  inline
                                  label="Other"
                                  name="group24"
                                  type="radio"
                                  id="radio23"
                                />
                              </div>
                            </div>

                            <div className="my-4">
                              <label  className="form-label">
                                Kitchen
                              </label>
                              <div className="mb-3 main-bath-check">
                                <Form.Check
                                  inline
                                  label="Eat in"
                                  type="checkbox"
                                  id="checkbox1"
                                />
                                <Form.Check
                                  inline
                                  label="Yard access"
                                  type="checkbox"
                                  id="checkbox2"
                                />
                                <Form.Check
                                  inline
                                  label="Gallery"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                              </div>
                            </div>

                            <div className="my-4">
                              <label  className="form-label">
                                Living room
                              </label>
                              <div className="mb-3 main-bath-check">
                                <Form.Check
                                  inline
                                  label="Combination"
                                  type="checkbox"
                                  id="checkbox1"
                                />
                                <Form.Check
                                  inline
                                  label="Formal"
                                  type="checkbox"
                                  id="checkbox2"
                                />
                                <Form.Check
                                  inline
                                  label="Sunken"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Fireplace"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Separate"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                              </div>
                            </div>

                            <div className="my-4">
                              <label  className="form-label">
                                Master bedroom
                              </label>
                              <div className="mb-3 main-bath-check">
                                <Form.Check
                                  inline
                                  label="Dressing room"
                                  type="checkbox"
                                  id="checkbox1"
                                />
                                <Form.Check
                                  inline
                                  label="Jacuzzi"
                                  type="checkbox"
                                  id="checkbox2"
                                />
                                <Form.Check
                                  inline
                                  label="Fireplace"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Suite"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Private bath"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Walk in closet"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="Shared bathroom"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                              </div>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Yard
                              </label>
                              <div className="mb-3 main-bath-check">
                                <Form.Check
                                  inline
                                  label="Back"
                                  type="checkbox"
                                  id="checkbox1"
                                />
                                <Form.Check
                                  inline
                                  label="Side"
                                  type="checkbox"
                                  id="checkbox2"
                                />
                                <Form.Check
                                  inline
                                  label="Front"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                                <Form.Check
                                  inline
                                  label="None"
                                  type="checkbox"
                                  id="checkbox3"
                                />
                              </div>
                            </div>

                            <div className="my-4">
                              <label  className="form-label">
                                Electric
                              </label>
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="110V"
                                  name="group24"
                                  type="radio"
                                  id="radio13"
                                />
                                <Form.Check
                                  inline
                                  label="220V"
                                  name="group24"
                                  type="radio"
                                  id="radio23"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="my-4">
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                              >
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                              </Form.Group>
                            </div>
                            <p
                              className="btn sign-btn fill-btn  text-white listing-submit"
                              onClick={handleShow}
                            >
                              submit
                            </p>
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="auction" title="online auction">
                        <Row>
                          <Col md={12}>
                            <div className="my-4 text-center">
                              <div className="upload-img">
                                <Form.Control type="file" />
                                <img
                                  src={require("../../assets/images/dummy.png")}
                                  className="upload-img"
                                ></img>
                                <p>Upload listing images/banners here</p>
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="my-4">
                              <label  className="form-label">
                                Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder="Enter address"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Sale Type
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Enter sale type"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                County
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Country Type"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                MLS ID
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Enter MLS id"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                SF
                              </label>
                              <Row>
                                <Col md={6}>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter sq"
                                  />
                                </Col>
                                <Col md={6}>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter ft"
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Bath
                              </label>
                              <Row>
                                <Col md={6}>
                                  <input
                                    type="Number of full bath"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter sq"
                                  />
                                </Col>
                                <Col md={6}>
                                  <input
                                    type="Number of half bath"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter ft"
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Cash only Purchase
                              </label>
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="Yes"
                                  name="group1"
                                  type="radio"
                                  id="radio17"
                                />
                                <Form.Check
                                  inline
                                  label="No"
                                  name="group1"
                                  type="radio"
                                  id="radio27"
                                />
                              </div>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Buyers Premium
                              </label>
                              <input
                                type="Enter taxes"
                                className="form-control"
                                id=""
                                placeholder="Enter MLS id"
                              />
                              <Form.Text
                                style={{ fontStyle: "italic" }}
                                className="text-muted"
                              >
                                *Greater of 5% of bid amount or $1000
                              </Form.Text>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="my-4">
                              <label  className="form-label">
                                Listing ID
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder="Enter parcel id"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Lot size
                              </label>
                              <input
                                type="Enter zoning"
                                className="form-control"
                                id=""
                                placeholder="Enter zoning"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Year built
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Enter zoning"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Bid deposit
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Bid deposit"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Bedrooms
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id=""
                                placeholder="Bedrooms"
                              />
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Occupied
                              </label>
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="Yes"
                                  name="group1"
                                  type="radio"
                                  id="radio17"
                                />
                                <Form.Check
                                  inline
                                  label="No"
                                  name="group1"
                                  type="radio"
                                  id="radio27"
                                />
                              </div>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Financing available
                              </label>
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="Yes"
                                  name="group1"
                                  type="radio"
                                  id="radio17"
                                />
                                <Form.Check
                                  inline
                                  label="No"
                                  name="group1"
                                  type="radio"
                                  id="radio27"
                                />
                              </div>
                            </div>
                            <div className="my-4">
                              <label  className="form-label">
                                Earnest money deposit
                              </label>
                              <input
                                type="Enter taxes"
                                className="form-control"
                                id=""
                                placeholder="Enter condition"
                              />
                              <Form.Text
                                style={{ fontStyle: "italic" }}
                                className="text-muted"
                              >
                                *Lesser of 3.0% of bid amount of $15,000
                              </Form.Text>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="my-4">
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                              >
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                              </Form.Group>
                            </div>
                            <p
                              className="btn sign-btn fill-btn  text-white listing-submit"
                              onClick={handleShow}
                            >
                              submit
                            </p>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center otp_popup_verify pt-4">
            <img
              src={require("../../assets/images/successful.svg").default}
              alt=""
            />
            <h5 className="mt-4">Your listing is under review.</h5>
            <p>
              Your listing information submitted to admin. Admin will review it.
            </p>
            <h6>Thank you</h6>
            <button
              className="btn okbtn text-white mb-4 mt-2"
              onClick={handleClose}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Add_listing;
