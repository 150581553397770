import React, { useState } from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const Property_person_auction = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showsucess, setShowsucess] = useState(false);
  const handleClosesucess = () => {
    setShowsucess(false);
  };
  const handleShowsucess = (e) => {
    e.preventDefault();
    handleClose();
    setShowsucess(true);
  };

  return (
    <>
      <section className="hero-banner">
        <Container>
          <p className="property-banner-heading">
            <img src={require("../assets/images/play.png")}></img>Properties
          </p>
          <h2>Properties</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing
            <br /> and typesetting industry.{" "}
          </p>
        </Container>
      </section>
      <div className="next-previous-tab">
        <Container>
          <p>
            <img src={require("../assets/images/home.png")}></img>{" "}
            <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
            <span>Properties</span>{" "}
            <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
            <span className="blue-text">Renovated Apartment</span>
          </p>
        </Container>
      </div>
      <Container>
        <Carousel responsive={responsive} className="properties-slider">
          <div>
            <img src={require("../assets/images/1.png")} alt="img" />
          </div>
          <div>
            <img src={require("../assets/images/3.png")} alt="img" />
          </div>
          <div>
            <img src={require("../assets/images/2.png")} alt="img" />
          </div>
          <div>
            <img src={require("../assets/images/1.png")} alt="img" />
          </div>
          <div>
            <img src={require("../assets/images/3.png")} alt="img" />
          </div>
          <div>
            <img src={require("../assets/images/2.png")} alt="img" />
          </div>
        </Carousel>
      </Container>

      <section className="house-details">
        <Container>
          <Row>
            <Col lg={8} md={12}>
              <div className="house-details-left-side">
                <Button
                  type="button"
                  className="add-favorite-btn"
                  variant="unset"
                >
                  add favorite
                </Button>
                <h2>Special house mix duplex</h2>
                <ul className="flex_mob">
                  <li>
                    <img
                      src={require("../assets/images/location.png")}
                      alt="img"
                    ></img>{" "}
                    Los angeles, CA
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/frame.png")}
                      alt="img"
                    ></img>{" "}
                    $9350/SqFt
                  </li>
                  <li className="color-green">
                    <i className="fa fa-circle" aria-hidden="true"></i>
                    In-Person Auction
                  </li>
                </ul>
                <h6>Description</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dumm text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries,{" "}
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dumm text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, Lorem
                  Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dumm
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries,{" "}
                </p>
                <hr></hr>
                <h6>Information</h6>
                <Row className="information-table">
                  <Col md={6} className="ps-1">
                    <Table borderless responsive>
                      <tbody>
                        <tr>
                          <td>Possession</td>
                          <td className="text-right">02/01/2023</td>
                        </tr>
                        <tr>
                          <td>Date Added</td>
                          <td className="text-right">10/01/2023</td>
                        </tr>
                        <tr>
                          <td>Lien</td>
                          <td className="text-right">$1,200</td>
                        </tr>
                        <tr>
                          <td>Plaintiff</td>
                          <td className="text-right">Defendent</td>
                        </tr>{" "}
                        <tr>
                          <td>Index number</td>
                          <td className="text-right">12</td>
                        </tr>{" "}
                        <tr>
                          <td>Plaintiff attorney</td>
                          <td className="text-right">Quarter</td>
                        </tr>{" "}
                        <tr>
                          <td>Auction notes</td>
                          <td className="text-right">Auctions</td>
                        </tr>{" "}
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={6} className="pe-1">
                    <Table borderless responsive>
                      <tbody>
                        <tr>
                          <td>Auction Date and Time</td>
                          <td className="text-right">02/01/2023 | 10:00 am</td>
                        </tr>{" "}
                        <tr>
                          <td>Auction location</td>
                          <td className="text-right">
                            Fort Lauderdale, FL 33308
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>Defendant</td>
                          <td className="text-right">Defendent</td>
                        </tr>{" "}
                        <tr>
                          <td>Judgment</td>
                          <td className="text-right">10/01/2023</td>
                        </tr>{" "}
                        <tr>
                          <td>Referee name</td>
                          <td className="text-right">Adam charles</td>
                        </tr>{" "}
                        <tr>
                          <td>Foreclosure type</td>
                          <td className="text-right">Foreclosure</td>
                        </tr>{" "}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <hr />
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="house-details-right-side">
                <div className="person-profile">
                  <div className="person-profile-image">
                    <img src={require("../assets/images/profile.png")}></img>
                  </div>
                  <h6>Eddie bremmer</h6>
                  <p>
                    <img src={require("../assets/images/location.png")} /> Los
                    angeles, CA
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dumm text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,{" "}
                  </p>
                  <p className="text-black mb-0">License Number</p>
                  <p>MH1420110062821</p>
                  <p className="text-black mb-0">Company</p>
                  <p>Adam real estaste pv. ltd.</p>
                  <div className="social-icons-box">
                    <Link to="">
                      <img src={require("../assets/images/message.png")} />
                    </Link>
                    <Link to="">
                      <img src={require("../assets/images/call.png")} />
                    </Link>
                  </div>
                </div>
                <div className="d-flex align-items-center feature-box">
                  <h6>Featured agent</h6>
                  <Link to="">View More</Link>
                </div>
                <Row className="mt-4">
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                </Row>
                <div className="auction-timer-box">
                  <p className="text-black text-center">Auction start in:</p>
                  <div className="auction-start-time">
                    <div className="timer-box">
                      <h3>12</h3>
                      <p>Days</p>
                    </div>
                    <div className="timer-box">
                      <h3>2</h3>
                      <p>Hours</p>
                    </div>
                    <div className="timer-box">
                      <h3>41</h3>
                      <p>Min</p>
                    </div>
                    <div className="timer-box">
                      <h3>21</h3>
                      <p>Sec</p>
                    </div>
                  </div>
                </div>
                <div className="base-price-box">
                  <p className="mb-0 text-black">Base Price:</p>
                  <h5>
                    <span>$</span>12,000
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="bottom-banner">
          <Container>
            <div className="banner-botton-content">
              <p className="property-banner-heading">
                <img src={require("../assets/images/play.png")}></img>let’s take
                a tour
              </p>
              <h2>
                Property Smarter,<br></br> Quicker & Anywhere
              </h2>
              <Link to="">
                Join now <i className="fa fa-play" aria-hidden="true"></i>
              </Link>
            </div>
          </Container>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
        className="details-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter your details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter name" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Phone number</Form.Label>
              <Form.Control type="number" placeholder="Enter phone number" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Address</Form.Label>
              <Form.Control type="number" placeholder="Enter address" />
            </Form.Group>
            <Button
              variant="unset"
              className="submit-bt"
              type="submit"
              onClick={(e) => handleShowsucess(e)}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showsucess}
        onHide={handleClosesucess}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center otp_popup_verify pt-4">
            <img
              src={require("../assets/images/successful.svg").default}
              alt=""
            />
            <h5 className="mt-4">You paid for your plan.</h5>
            <p>Our team will reach you soon !!</p>
            <button
              className="btn okbtn text-white mb-4 mt-2"
              onClick={handleClosesucess}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Property_person_auction;
