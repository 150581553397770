import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import creditImg from '../assets/images/credit.png';
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import creditCardType from 'credit-card-type';
import masterImg from '../assets/images/master.png';
import visaImg from '../assets/images/visa.png';
import dinerClubImg from '../assets/images/diners-club.png';
import americanExpImg from '../assets/images/american-express.png';
import discoverImg from '../assets/images/discover.png';
import jcbImg from '../assets/images/jcb.png';
import unionPayImg from '../assets/images/unionpay.png';
import maestroImg from '../assets/images/maestro.png';
import mirImg from '../assets/images/mir.png';
import eloImg from '../assets/images/elo-final.png';
// import creditImg from '../assets/images/credit.png';
import hiperCardImg from '../assets/images/hipercard.png';
import { context } from "../GlobalContext/GlobalContext";
import { BuySubscriptionPlan } from "../Service/Service";
import { toast } from "react-toastify";

const BuySubscription = () => {
  const [show, setShow] = useState(false);
  const globalData = useContext(context)
  const navigate = useNavigate()
  const loc = useLocation()
  const handleClose = () => {
    setShow(false);
    navigate('/sub-page')
  };


  const handleShow = () => setShow(true)
  const { register, formState: { errors }, handleSubmit, control } = useForm();

  const CheckCardType = (val) => {
    console.log(val.length);
    if (val.length >= 4) {

      let type = creditCardType(val)[0]?.type
      console.log(type);
      if (type === "mastercard") {
        document.getElementById("card-img").src = masterImg
      }
      if (type === "visa") {
        document.getElementById("card-img").src = visaImg
      }
      if (type === "american-express") {
        document.getElementById("card-img").src = americanExpImg
      }
      if (type === "diners-club") {
        document.getElementById("card-img").src = dinerClubImg
      }
      if (type === "discover") {
        document.getElementById("card-img").src = discoverImg
      }
      if (type === "jcb") {
        document.getElementById("card-img").src = jcbImg
      }
      if (type === "unionpay") {
        document.getElementById("card-img").src = unionPayImg
      }
      if (type === "maestro") {
        document.getElementById("card-img").src = maestroImg
      }
      if (type === "mir") {
        document.getElementById("card-img").src = mirImg
      }
      if (type === "elo") {
        document.getElementById("card-img").src = eloImg
      }
      if (type === "hiper" || type === "hipercard") {
        document.getElementById("card-img").src = hiperCardImg
      }
      if (type === undefined) {
        document.getElementById("card-img").src = creditImg
      }
    }
    else {
      document.getElementById("card-img").src = creditImg
    }
  }


  const onSubmit = (data) => {
    globalData.setLoader(true)
    console.log(moment(data.cardExpDate).format("MM/YYYY").split('/')[0], loc.state)
    const payload = {
      "price": loc.state,
      "currency_code": "$",
      "type": loc.state === 19.99 ? "Basic" : loc.state === 219.99 ? "Business" : loc.state === 399.99 ? "Pro" : "",
      "duration": loc.state === 29.99 ? "MONTHLY" : "YEARLY",
      "transaction_id": "",
      "card_no": data.cardNumber,
      "exp_month": moment(data.cardExpDate).format("MM/YYYY").split('/')[0],
      "exp_year": moment(data.cardExpDate).format("MM/YYYY").split('/')[1],
      "cvv": data.cvv,
      "name": data.name,
      "device_type": "web"
    }
    BuySubscriptionPlan(globalData.token, payload).then(response => {
      if (response.status === 200) {
        globalData.setLoader(false)
        globalData.setPlan(response.data.data.price)
        localStorage.setItem('plan', response.data.data.price)
        globalData.setPlanStartDate(response.data.data.start_date)
        localStorage.setItem('start_date', response.data.data.start_date)
        globalData.setPlanEndDate(response.data.data.end_date)
        localStorage.setItem('end_date', response.data.data.end_date)
        handleShow()
      }
    }).catch(err => {
      globalData.setLoader(false)
      if (err.response.status === 400) {
        toast.error(err.response.data.message)
      }
      if (err.respoonse.status === 401) {
        globalData.setToken('')
        localStorage.clear()
        toast.info("You session has expired please login again!.")
        navigate('/')
      }
    })
  }

  useEffect(() => {
    console.log(loc.state)
  }, [])
  return (
    <>
      <section className="login-area forgot-area mb-4">
        <Container>
          <div className="heading-area-top">
            <h2 className="text-center">Buy subscription plan</h2>
            <Row className="justify-content-center mt-5">
              {loc.state === 19.99 &&
                <Col lg={5} md={5}>
                  <div className="basic-plan-box selected" id='card1' >
                    <div className="basic-payment">
                      <div className="plan-text-picture">
                        <div className="plan-text">
                          <p>Basic</p>
                          <h3>
                            $ <b>29.99</b> /Month
                          </h3>
                          <div className="special-pricing">
                          <p><span>SPECIAL PRICING</span> Until 12/31/2022</p>
                          <h3>
                          $ <b>19.99</b> /Month
                        </h3>
                        </div>
                        </div>
                        <div className="diomand-picture">
                          <img
                            src={require("../assets/images/gem.png")}
                            alt="img"
                          ></img>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <ul className="plans-description">
                      <li>
                        <img
                          src={require("../assets/images/check.png")}
                          alt="img"
                        ></img>{" "}
                        Only information about online auctions will be included in the subscription plan.
                      </li>
                      <li>
                        <img
                          src={require("../assets/images/check.png")}
                          alt="img"
                        ></img>{" "}
                        Add bid on property detail included in the subscription plan.
                      </li>
                    </ul>
                  </div>
                </Col>}

              {loc.state === 219.99 &&
                <Col lg={5} md={5}>
                  <div className="basic-plan-box" id='card2' >
                    <i class="fa fa-check select-check" aria-hidden="true"></i>
                    <div className="basic-payment">
                      <div className="plan-text-picture">
                        <div className="plan-text">
                          <p>Business</p>
                          <h3>
                            $ <b>349.99</b> /Year
                          </h3>
                          <div className="special-pricing">
                          <p><span>SPECIAL PRICING</span> Until 12/31/2022</p>
                          <h3>
                          $ <b>219.99</b> /Year
                        </h3>
                        </div>
                        </div>
                        <div className="diomand-picture">
                          <img
                            src={require("../assets/images/gem.png")}
                            alt="img"
                          ></img>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <ul className="plans-description">
                      <li>
                        <img
                          src={require("../assets/images/check.png")}
                          alt="img"
                        ></img>{" "}
                        Only information about online auctions will be included in the subscription plan.
                      </li>
                      <li>
                        <img
                          src={require("../assets/images/check.png")}
                          alt="img"
                        ></img>{" "}
                        Add bid on property detail included in the subscription plan.
                      </li>
                    </ul>
                  </div>
                </Col>}

              {loc.state === 399.99 &&
                <Col lg={5} md={5}>
                  <div className="basic-plan-box" id='card3' >
                    <i class="fa fa-check select-check" aria-hidden="true"></i>
                    <div className="basic-payment">
                      <div className="plan-text-picture">
                        <div className="plan-text">
                          <p>Pro</p>
                          <h3 className="subscription-plan-price">
                            $ <b>399.99</b> /Year
                          </h3>
                        </div>
                        <div className="diomand-picture">
                          <img
                            src={require("../assets/images/gem.png")}
                            alt="img"
                          ></img>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <ul className="plans-description">
                      <li>
                        <img
                          src={require("../assets/images/check.png")}
                          alt="img"
                        ></img>{" "}
                        Only information about online auctions will be included in the subscription plan.
                      </li>
                      <li>
                        <img
                          src={require("../assets/images/check.png")}
                          alt="img"
                        ></img>{" "}
                        Add bid on property detail included in the subscription plan.
                      </li>
                      <li>
                        <img
                          src={require("../assets/images/check.png")}
                          alt="img"
                        ></img>{" "}
                        Agents wish to be at the top of the list.
                      </li>
                      {/* <Button
                                      type="button"
                                      className="choose-plan-bt"
                                      variant="unset"
                                    >
                                      choose plan
                                    </Button> */}
                    </ul>
                  </div>
                </Col>}

              <Col lg={5} md={5}>
                <div className="payment-card">
                  <div className="login-box login-form bids-form new-padding">
                    <div className="mt-4" style={{ position: "relative" }}>
                      <label className="form-label mb-0">
                        Card Number
                      </label>
                      <p>Enter the 16 digit card number on the card.</p>
                      <div className="info-details">
                        <input
                          type="text"
                          className="form-control card-input  "
                          id=""
                          placeholder="3223 **** **** ****"
                          maxLength={16}
                          {...register('cardNumber', { required: "Card number is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." }, onChange: e => CheckCardType(e.target.value) })}
                        />
                        {errors.cardNumber && <span style={{ color: "red" }}>{errors.cardNumber.message}</span>}
                        <img
                          id="card-img"
                          src={creditImg}
                          alt="image"
                          className="master-icon"
                        ></img>
                      </div>


                    </div>
                    <div className="mt-4">
                      <label className="form-label">
                        Your name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Enter name"
                        maxLength={30}
                        {...register('name', { required: "Name is required." })}
                      />
                      {errors.name && <span style={{ color: "red" }}>{errors.name.message}</span>}
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="mt-4">
                          <label className="form-label">
                            Exp Date
                          </label>
                          <Controller
                            control={control}
                            name="cardExpDate"
                            rules={{ required: "Expiry date is required." }}
                            render={({ field: { onChange, value } }) => (
                              <div className="col-md-8">
                                <DatePicker className="form-control" selected={value} dateFormat="MM/yyyy" placeholderText="09/2025" showMonthYearPicker onKeyDown={(e) => e.preventDefault()} minDate={new Date(moment(new Date()).subtract(1, 'month').endOf('month'))} onChange={(date) => onChange(date)} />
                                {errors.cardExpDate && <p style={{ color: "red", textAlign: "left" }}>{errors.cardExpDate.message}</p>}
                              </div>

                            )}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mt-4">
                          <label className="form-label">
                            CVV
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id=""
                            placeholder="***"
                            maxLength={4}
                            {...register('cvv', { required: "CVV is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                          />
                          {errors.cvv && <span style={{ color: "red" }}>{errors.cvv.message}</span>}
                        </div>
                      </Col>
                    </Row>
                    <p className="btn sign-btn fill-btn w-100 ms-0 mt-3 mb-4 text-white" onClick={handleSubmit(onSubmit)}>
                      pay now
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>


      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center otp_popup_verify pt-4">
            <img
              src={require("../assets/images/successful.svg").default}
              alt=""
            />
            <h5 className="mt-4">Successfully</h5>
            <p>You paid for your plan.</p>
            <button
              className="btn okbtn text-white mb-4 mt-2"
              onClick={handleClose}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BuySubscription;