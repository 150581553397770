import React, { useState } from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const Bids_details = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showsucess, setShowsucess] = useState(false);
  const handleClosesucess = () => {
    setShowsucess(false);
  };
  const handleShowsucess = (e) => {
    e.preventDefault();
    handleClose();
    setShowsucess(true);
  };

  return (
    <>
      <section className="hero-banner">
        <Container>
          <p className="property-banner-heading">
            <img src={require("../assets/images/play.png")}></img>Properties
          </p>
          <h2>Properties</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing
            <br /> and typesetting industry.{" "}
          </p>
        </Container>
      </section>
      <div className="next-previous-tab">
        <Container>
          <p>
            <img src={require("../assets/images/home.png")}></img>{" "}
            <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
            <span>My Bids</span>{" "}
            <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
            <span className="blue-text">Renovated Apartment</span>
          </p>
        </Container>
      </div>
      <Container>
        <Carousel responsive={responsive} className="properties-slider">
          <div>
            <img src={require("../assets/images/1.png")} alt="img" />
          </div>
          <div>
            <img src={require("../assets/images/3.png")} alt="img" />
          </div>
          <div>
            <img src={require("../assets/images/2.png")} alt="img" />
          </div>
          <div>
            <img src={require("../assets/images/1.png")} alt="img" />
          </div>
          <div>
            <img src={require("../assets/images/3.png")} alt="img" />
          </div>
          <div>
            <img src={require("../assets/images/2.png")} alt="img" />
          </div>
        </Carousel>
      </Container>

      <section className="house-details">
        <Container>
          <Row>
            <Col lg={8} md={12}>
              <div className="house-details-left-side">
                <Button
                  type="button"
                  className="add-favorite-btn"
                  variant="unset"
                >
                  add favorite
                </Button>
                <h2>Renovated Apartment</h2>
                <ul>
                  <li>
                    <img
                      src={require("../assets/images/location.png")}
                      alt="img"
                    ></img>{" "}
                    Los angeles, CA
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/frame.png")}
                      alt="img"
                    ></img>{" "}
                    $9350/SqFt
                  </li>
                </ul>
                <h6>Description</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dumm text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries,{" "}
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dumm text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, Lorem
                  Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dumm
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries,{" "}
                </p>
                <hr></hr>
                <h6>Features</h6>
                <div className="service-property">
                  <div className="service-one">
                    <img src={require("../assets/images/bed.png")}></img>
                    <div>
                      <p>Bedrooms</p>
                      <h6>2</h6>
                    </div>
                  </div>
                  <div className="service-one">
                    <img src={require("../assets/images/bath.png")}></img>
                    <div>
                      <p>bathrooms</p>
                      <h6>2</h6>
                    </div>
                  </div>
                  <div className="service-one">
                    <img src={require("../assets/images/balcony.png")}></img>
                    <div>
                      <p>balcony</p>
                      <h6>2</h6>
                    </div>
                  </div>
                  <div className="service-one">
                    <img src={require("../assets/images/food.png")}></img>
                    <div>
                      <p>kitchen</p>
                      <h6>2</h6>
                    </div>
                  </div>
                </div>

                <hr></hr>
                <h6>Information</h6>
                <Row className="information-table">
                  <Col md={6} className="ps-1">
                    <Table borderless responsive>
                      <tbody>
                        <tr>
                          <td>Possession</td>
                          <td className="text-right">02/01/2023</td>
                        </tr>
                        <tr>
                          <td>Parcel ID</td>
                          <td className="text-right">MH1420110062821</td>
                        </tr>
                        <tr>
                          <td>Zoning</td>
                          <td className="text-right">Industrial</td>
                        </tr>
                        <tr>
                          <td>Style</td>
                          <td className="text-right">House</td>
                        </tr>{" "}
                        <tr>
                          <td>Year built</td>
                          <td className="text-right">2012</td>
                        </tr>{" "}
                        <tr>
                          <td>Taxes</td>
                          <td className="text-right">$12,000</td>
                        </tr>{" "}
                        <tr>
                          <td>CofO</td>
                          <td className="text-right">Yes</td>
                        </tr>{" "}
                        <tr>
                          <td>Garage (location)</td>
                          <td className="text-right">Yes</td>
                        </tr>{" "}
                        <tr>
                          <td>Water view</td>
                          <td className="text-right">Yes</td>
                        </tr>{" "}
                        <tr>
                          <td>Basement</td>
                          <td className="text-right">Yes</td>
                        </tr>{" "}
                        <tr>
                          <td>Electric</td>
                          <td className="text-right">Yes</td>
                        </tr>{" "}
                        <tr>
                          <td>Bath</td>
                          <td className="text-right">Full Bath</td>
                        </tr>{" "}
                        <tr>
                          <td>Exterior</td>
                          <td className="text-right">Garage door opener</td>
                        </tr>
                        <tr>
                          <td>Master bedroom</td>
                          <td className="text-right">Shared bathroom</td>
                        </tr>{" "}
                        <tr>
                          <td>Yard</td>
                          <td className="text-right">Side, Back</td>
                        </tr>{" "}
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={6} className="pe-1">
                    <Table borderless responsive>
                      <tbody>
                        <tr>
                          <td>Building Sqft and Dimensions</td>
                          <td className="text-right">13 sqft, 13x69 ft</td>
                        </tr>{" "}
                        <tr>
                          <td>Lot Sqft and Dimensions</td>
                          <td className="text-right">13 sqft, 13x69 ft</td>
                        </tr>{" "}
                        <tr>
                          <td>Condition</td>
                          <td className="text-right">Good</td>
                        </tr>{" "}
                        <tr>
                          <td>Total Rooms Main unit</td>
                          <td className="text-right">House</td>
                        </tr>{" "}
                        <tr>
                          <td>Heating</td>
                          <td className="text-right">23°c</td>
                        </tr>{" "}
                        <tr>
                          <td>Cooling</td>
                          <td className="text-right">23°c</td>
                        </tr>{" "}
                        <tr>
                          <td>AC</td>
                          <td className="text-right">Central</td>
                        </tr>{" "}
                        <tr>
                          <td>Heat source</td>
                          <td className="text-right">Electric</td>
                        </tr>{" "}
                        <tr>
                          <td>Heat system</td>
                          <td className="text-right">Forced air</td>
                        </tr>{" "}
                        <tr>
                          <td>Kitchen</td>
                          <td className="text-right">Yard access</td>
                        </tr>{" "}
                        <tr>
                          <td>Living room</td>
                          <td className="text-right">Combination</td>
                        </tr>{" "}
                        <tr>
                          <td>Main Bath</td>
                          <td className="text-right">Separate shower,</td>
                        </tr>{" "}
                        <tr>
                          <td>Pool</td>
                          <td className="text-right">Above ground</td>
                        </tr>{" "}
                        <tr>
                          <td>Sewer</td>
                          <td className="text-right">Cesspool</td>
                        </tr>{" "}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <hr />
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="house-details-right-side">
                <div className="person-profile">
                  <div className="person-profile-image">
                    <img src={require("../assets/images/profile.png")}></img>
                  </div>
                  <h6>Eddie bremmer</h6>
                  <p>
                    <img src={require("../assets/images/location.png")} /> Los
                    angeles, CA
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dumm text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,{" "}
                  </p>
                  <p className="text-black mb-0">License Number</p>
                  <p>MH1420110062821</p>
                  <p className="text-black mb-0">Company</p>
                  <p>Adam real estaste pv. ltd.</p>
                  <div className="social-icons-box">
                    <Link to="">
                      <img src={require("../assets/images/message.png")} />
                    </Link>
                    <Link to="">
                      <img src={require("../assets/images/call.png")} />
                    </Link>
                  </div>
                </div>
                <div className="d-flex align-items-center feature-box">
                  <h6>Featured agent</h6>
                  <Link to="">View More</Link>
                </div>
                <Row className="mt-4">
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                    <div className="agent-profile-div">
                      <img
                        src={require("../assets/images/girl.png")}
                        alt="img"
                      />
                      <p>Jessica </p>
                    </div>
                  </Col>
                </Row>
                <div className="auction-timer-box">
                  <p className="text-black text-center">Auction start in:</p>
                  <div className="auction-start-time">
                    <div className="timer-box">
                      <h3>12</h3>
                      <p>Days</p>
                    </div>
                    <div className="timer-box">
                      <h3>2</h3>
                      <p>Hours</p>
                    </div>
                    <div className="timer-box">
                      <h3>41</h3>
                      <p>Min</p>
                    </div>
                    <div className="timer-box">
                      <h3>21</h3>
                      <p>Sec</p>
                    </div>
                  </div>
                </div>
                <div className="base-price-box base-enrty-amount">
                  <div>
                    <p className="mb-0 text-black">Base Price:</p>
                    <h5>
                      <span>$</span>12,000
                    </h5>
                  </div>
                  <div>
                    <p className="mb-0 text-black">Entry Fees</p>
                    <h5>
                      <span>$</span>500
                    </h5>
                  </div>
                  <div>
                    <p className="mb-0 text-black">My Bid Amount</p>
                    <h5>
                      <span>$</span>20,000
                    </h5>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="bottom-banner">
          <Container>
            <div className="banner-botton-content">
              <p className="property-banner-heading">
                <img src={require("../assets/images/play.png")}></img>let’s take
                a tour
              </p>
              <h2>
                Property Smarter,<br></br> Quicker & Anywhere
              </h2>
              <Link to="">
                Join now <i className="fa fa-play" aria-hidden="true"></i>
              </Link>
            </div>
          </Container>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
        className="details-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter your details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter name" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Phone number</Form.Label>
              <Form.Control type="number" placeholder="Enter phone number" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Address</Form.Label>
              <Form.Control type="number" placeholder="Enter address" />
            </Form.Group>
            <Button
              variant="unset"
              className="submit-bt"
              type="submit"
              onClick={(e) => handleShowsucess(e)}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showsucess}
        onHide={handleClosesucess}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center otp_popup_verify pt-4">
            <img
              src={require("../assets/images/successful.svg").default}
              alt=""
            />
            <h5 className="mt-4">You paid for your plan.</h5>
            <p>Our team will reach you soon !!</p>
            <button
              className="btn okbtn text-white mb-4 mt-2"
              onClick={handleClosesucess}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Bids_details;
