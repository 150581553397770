import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Select from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import { media_url, SignUp, SocialSignup } from "../Service/Service";

import Autocomplete from "react-google-autocomplete";
import { context } from '../GlobalContext/GlobalContext';
import PopUp from "../common/SweetAlert";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import userimg from '../assets/images/dummy.png';


const options = [
    { value: 'customer', label: 'Customer' },
    { value: 'agent', label: 'Broker' },
    { value: 'seller', label: 'Seller' }
]

const Singup = () => {
    let loc = useLocation()
    let globalData = useContext(context)

    let navigate = useNavigate();


    const [show, setShow] = useState(false);
    const handleClose = () => { navigate('/'); setShow(false) }
    const handleShow = () => setShow(true);
    const [imgUrl, setImgUrl] = useState('')
    function signin() {
        navigate('/login'+window.location.search)
    }

    const { register, handleSubmit, watch, formState: { errors }, setValue, control, reset, getValues } = useForm();

    const onSubmit = data => {
        // globalData.setLoader(true)
        delete data.confirmPass;

        if (loc.state?.res?.type !== "social") {
            if (data.user_type === "agent") {
                console.log('agent');
                console.log(data);

                const payload = new FormData()
                payload.append('image', data.image[0])
                payload.append('user_type', data.user_type)
                payload.append('name', data.name)
                payload.append('email', data.email)
                payload.append('phone_number', data.phone_number)
                payload.append('password', data.password)
                payload.append('address', data.address.formatted_address)
                payload.append('latitude', data.address.geometry.location.lat())
                payload.append('longitude', data.address.geometry.location.lng())
                data.address.address_components.map((address) =>
                    (address.types[0] === 'locality' || address.types[0] === 'sublocality') ? payload.append('city', address.long_name) : address.types[0] === "administrative_area_level_1" ? payload.append('state', address.long_name) : ''
                )
                payload.append('company_name', data.company_name)
                payload.append('license_number', data.license_number)
                payload.append('about', data.description)

                SignUp(payload).then(response => {
                    if (response.status === 200) {
                        globalData.setLoader(false)
                        globalData.setUserType(response.data.data.user_type)
                        localStorage.setItem('userType', response.data.data.user_type)
                        setShow(true)
                    }
                }).catch(err => {
                    toast.info(err.response.data.error)
                })
            }
            if (data.user_type === "seller") {
                console.log('noagent')
                const payload = {
                    "name": data.name,
                    "email": data.email,
                    'user_type': data.user_type,
                    "address": data.address.formatted_address,
                    "password": data.password,
                    "latitude": data.address.geometry.location.lat(),
                    "longitude": data.address.geometry.location.lng(),
                    "phone_number": data.phone_number,
                    "about": data.description
                }
                SignUp(payload).then(response => {
                    if (response.status === 200) {
                        globalData.setLoader(false)
                        globalData.setUserType(response.data.data.user_type)
                        localStorage.setItem('userType', response.data.data.user_type)
                        setShow(true)
                    }
                }).catch(err => {
                    toast.info(err.response.data.error)
                })
            }
            if (data.user_type === "customer") {
                console.log("customer");
                const payload = {
                    "name": data.name,
                    "email": data.email,
                    'user_type': data.user_type,
                    "password": data.password,
                }
                SignUp(payload).then(response => {
                    if (response.status === 200) {
                        globalData.setLoader(false)
                        globalData.setToken(response.data.data.token)
                        localStorage.setItem("token", response.data.data.token)
                        globalData.setName(response.data.data.name)
                        localStorage.setItem('name', response.data.data.name)
                        globalData.setImage(response.data.data.image !== null ? media_url + response.data.data.image : '')
                        localStorage.setItem('image', response.data.data.image !== null ? media_url + response.data.data.image : '')
                        globalData.setUserId(response.data.data.id)
                        localStorage.setItem('userid', response.data.data.id)
                        globalData.setEmail(response.data.data.email)
                        localStorage.setItem('email', response.data.data.email)
                        globalData.setUserType(response.data.data.user_type)
                        localStorage.setItem('userType', response.data.data.user_type)
                        toast.success('Sign up successfully.')
                        reset()
                        navigate('/subscriptions',{state:"customer"})
                    }
                }).catch(err => {
                    toast.info(err.response.data.message)
                })
            }
        }
        else {

            if (data.user_type === "customer") {
                let payload = {
                    "name": data.name,
                    "email": data.email,
                    "user_type": data.user_type
                }
                SocialSignup(loc.state?.res?.id, loc.state?.res?.token, payload).then(res => {
                    if (res.status === 200) {
                        globalData.setLoader(false)
                        globalData.setToken(res.data.data.token)
                        localStorage.setItem("token", res.data.data.token)
                        globalData.setName(res.data.data.name)
                        localStorage.setItem('name', res.data.data.name)
                        globalData.setUserId(res.data.data.id)
                        localStorage.setItem('userid', res.data.data.id)
                        globalData.setEmail(res.data.data.email)
                        localStorage.setItem('email', res.data.data.email)
                        globalData.setUserType(res.data.data.user_type)
                        localStorage.setItem('userType', res.data.data.user_type)
                        toast.success('Sign up successfully.')
                        reset()
                        navigate('/subscriptions',{state:"customer"})
                    }
                }).catch(err => {
                    toast.info(err.response.data.message)
                })
            }
            if (data.user_type === "agent") {
                const payload = new FormData()
                payload.append('image', data.image[0])
                payload.append('user_type', data.user_type)
                payload.append('name', data.name)
                payload.append('email', data.email)
                payload.append('phone_number', data.phone_number)
                payload.append('address', data.address.formatted_address)
                payload.append('latitude', data.address.geometry.location.lat())
                payload.append('longitude', data.address.geometry.location.lng())
                payload.append('company_name', data.company_name)
                payload.append('license_number', data.license_number)
                payload.append('about', data.description)
                SocialSignup(loc.state?.res?.id, loc.state?.res?.token, payload).then(response => {
                    if (response.status === 200) {
                        globalData.setLoader(false)
                        globalData.setUserType(response.data.data.user_type)
                        localStorage.setItem('userType', response.data.data.user_type)
                        setShow(true)
                    }
                }).catch(err => {
                    toast.info(err.response.data.message)
                })
            }
            if (data.user_type === "seller") {
                const payload = {
                    "name": data.name,
                    "email": data.email,
                    'user_type': data.user_type,
                    "address": data.address.formatted_address,
                    "latitude": data.address.geometry.location.lat(),
                    "longitude": data.address.geometry.location.lng(),
                    "phone_number": data.phone_number,
                    'about': data.description
                }
                SocialSignup(loc.state?.res?.id, loc.state?.res?.token, payload).then(response => {
                    if (response.status === 200) {
                        globalData.setLoader(false)
                        globalData.setUserType(response.data.data.user_type)
                        localStorage.setItem('userType', response.data.data.user_type)
                        setShow(true)
                    }
                }).catch(err => {
                    toast.info(err.response.data.message)
                })
            }
        }

    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#DADADA',
            cursor: 'pointer',
            // minHeight: '30px',
            // height: '30px',
            padding: '13px',
            '&:focus': { padding: '0', borderColor: '#DADADA', },
            '&:hover': { borderColor: '#DADADA', },
            boxShadow: state.isFocused ? null : null,

        }),
        valueContainer: (provided, state) => ({
            ...provided,
            // height: '30px',
            padding: '0'
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            padding: "0px"
        }),
        indicatorSeparator: styles => ({ ...styles, display: "none" }),


    }


    useEffect(() => {
        console.log(loc);
        setValue('name', loc.state?.res.name)
        setValue('email', loc.state?.res.email)
    }, [reset])

    return (
        <>
            <section className="login-area">
                <div className="container">
                    <div className="login-box-area">
                        <div className="row justify-content-center">
                            <aside className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                                <div className="login-box">
                                    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                                        <h2 className="text-center">Sign Up</h2>
                                        {watch('user_type') === "agent" &&
                                            <div className="my-4 text-center">
                                                <div className="upload-img">
                                                    <Form.Control type="file"  {...register('image', { required: "Image is required.", onChange: e => setImgUrl(URL.createObjectURL(e.target.files[0])) })} />
                                                    <img src={imgUrl || userimg} className="upload-img"></img>
                                                    <p>Upload picture here</p>
                                                    {errors.image && <span style={{ color: "red" }}>{errors.image.message}</span>}
                                                </div>
                                            </div>}
                                        <Controller
                                            control={control}
                                            name="user_type"
                                            rules={{ required: "Please select type." }}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div className="my-4">
                                                    <label className="form-label">Choose account type</label>

                                                    <Select options={options} styles={customStyles} onChange={(e) => onChange(e.value)} />
                                                    {errors.user_type && <span style={{ color: "red" }}>{errors.user_type.message}</span>}

                                                </div>)}
                                        />
                                        <div className="my-4">
                                            <label className="form-label" >Name</label>
                                            <input type="text" maxLength={30} className="form-control" id="" placeholder="eg. adam smith" {...register('name', { required: "Name is required." })} />
                                            {errors.name && <span style={{ color: "red" }}>{errors.name.message}</span>}
                                        </div>
                                        <div className="my-4">
                                            <label className="form-label" >Email address</label>
                                            <input type="email" maxLength={30} className="form-control" id="" placeholder="eg. adamsmith@gmail.com" {...register('email', { required: "Email is required.", pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/, message: "Invalid email." }, setValueAs: v => v.toLowerCase() })} />
                                            {errors.email && <span style={{ color: "red" }}>{errors.email.message}</span>}
                                        </div>
                                        {watch("user_type") !== "customer" &&
                                            <>
                                                <div className="my-4">
                                                    <label className="form-label">Contact</label>
                                                    <input type="text" className="form-control" id="" placeholder="eg. +11 234 456 3445" maxLength={12} {...register('phone_number', { required: "Contact is required.", pattern: { value: /^[0-9]*$/, message: "Kindly enter numbers." } })} />
                                                    {errors.phone_number && <span style={{ color: "red" }}>{errors.phone_number.message}</span>}
                                                </div>
                                                <Controller

                                                    render={({ field: { onChange, name, ref, value }, fieldState: { error } }) => {
                                                        return (
                                                            <div className="my-4">
                                                                <label className="form-label">
                                                                    Address
                                                                </label>
                                                                <Autocomplete ref={ref} apiKey="AIzaSyCTg77HJ0mIEmvnMn_lOHbd_x8QEk9zD0g" defaultValue={value} className="form-control" placeholder="eg. 857 Pineknoll St. Fort Lauderdale, FL 33308" onChange={() => setValue('address', '')} onPlaceSelected={(place) => onChange(place)} options={{

                                                                    types: ["geocode", "establishment"],
                                                                }} />

                                                                {error?.message && <span style={{ color: "red" }}>{error.message}</span>}
                                                            </div>
                                                        )
                                                    }}
                                                    control={control}
                                                    name="address"
                                                    rules={{ required: "Please select address." }}
                                                />
                                            </>
                                        }
                                        {loc.state === null &&
                                            <div className="my-4">
                                                <label className="form-label" >Password</label>
                                                <input type="password" minLength={8} maxLength={30} className="form-control" id="" placeholder="eg. ******************" {...register('password', { required: "Password is required." })} />
                                                {errors.password && <span style={{ color: "red" }}>{errors.password.message}</span>}
                                            </div>}
                                        {loc.state === null &&
                                            <div className="my-4">
                                                <label className="form-label" >Confirm password</label>
                                                <input type="password" minLength={8} maxLength={30} className="form-control" id="" placeholder="eg. ******************" {...register('confirmPass', { required: "Confirm password is required.", validate: value => value === watch("password") || "Password didn't match." })} />
                                                {errors.confirmPass && <span style={{ color: "red" }}>{errors.confirmPass.message}</span>}
                                            </div>}
                                        {watch("user_type") === "agent" &&
                                            <>
                                                <div className="my-4">
                                                    <label className="form-label">Company</label>
                                                    <input type="text" maxLength={30} className="form-control" id="" placeholder="eg. Adam real estate pvt. ltd." {...register('company_name', { required: "Comapny name is required." })} />
                                                    {errors.company_name && <span style={{ color: "red" }}>{errors.company_name.message}</span>}
                                                </div>
                                                <div className="my-4">
                                                    <label className="form-label">License Number</label>
                                                    <input type="text" maxLength={30} className="form-control" id="" placeholder="eg. 1234 4567 890 1234" {...register('license_number', { required: "License number is required." })} />
                                                    {errors.license_number && <span style={{ color: "red" }}>{errors.license_number.message}</span>}
                                                </div>
                                            </>}

                                        {(watch("user_type") === "seller" || watch("user_type") === "agent") &&
                                            <div className="my-3">
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlTextarea1"
                                                >
                                                    <Form.Label>About</Form.Label>
                                                    <Form.Control as="textarea" rows={3} maxLength={250} {...register('description', { required: "About field can't be empty." })} />
                                                </Form.Group>
                                                {errors.description && <span style={{ color: "red" }}>{errors.description.message}</span>}
                                            </div>
                                        }
                                        <button className="btn sign-btn fill-btn w-100 ms-0 my-3 text-white">Sign up</button>
                                    </form>
                                    <hr />
                                    <p className="text-center py-2">Already have an account?<span className="signup" onClick={signin}> Sign In</span></p>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="text-center otp_popup_verify pt-4">
                        <img src={require('../assets/images/successful.svg').default} alt="" />
                        <h5 className="mt-4">Your profile is under review</h5>
                        <p>Your profile information submitted to admin, please wait until the profile verification.</p>
                        <button className="btn okbtn text-white mb-4 mt-2" onClick={handleClose}>Go to login</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Singup;