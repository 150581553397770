import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { context } from "../../GlobalContext/GlobalContext";


const Subscriptions = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate()
  const globalData = useContext(context)
  const [price, setPrice] = useState(19.99)
  const loc = useLocation()
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const activeCard = (event, price) => {
    setPrice(price);
    var ele = document.querySelectorAll('.basic-plan-box')

    Array.from(ele).forEach((item) => {
      item.classList.remove('selected')
    })
    document.getElementById(event).classList.add('selected')
  }

  const selectedPlan = () => {

    if (Number(globalData.plan).toLocaleString("en-US") === '19.99') {
      document.getElementById('card1').classList.add('selected')
    }
    else if (Number(globalData.plan).toLocaleString("en-US") === '219.99') {
      document.getElementById('card2').classList.add('selected')
    }
    else if (Number(globalData.plan).toLocaleString("en-US") === '399.99') {
      document.getElementById('card3').classList.add('selected')
    }

  }

  useEffect(() => {
    console.log(globalData.plan);
    {
      globalData.plan &&
        selectedPlan()
    }


  }, [globalData.plan])

  return (
    <>
      <section className="login-area forgot-area mb-4">
        <Container>
          <div className="heading-area-top">
            <h2 className="text-center">Plans that helps you grow</h2>
            <p className="text-center my-2">
              Select the best offers that suits your need.
            </p>
            <Row className="justify-content-center mt-5">
              <Col lg={4} md={5}>
                <div className="basic-plan-box" id='card1' onClick={(e) => (globalData.plan === null || globalData.plan === 'null') ? activeCard("card1", 19.99) : e.preventDefault()}>
                  <i class="fa fa-check select-check" aria-hidden="true"></i>
                  <div className="basic-payment">
                    <div className="plan-text-picture">
                      <div className="plan-text">
                        <p>Basic</p>
                        <h3>
                          $ <b>29.99</b> /Month
                        </h3>
                        <div className="special-pricing">
                          <p><span>SPECIAL PRICING</span> Until 12/31/2022</p>
                          <h3>
                            $ <b>19.99</b> /Month
                          </h3>
                        </div>
                      </div>
                      <div className="diomand-picture">
                        <img
                          src={require("../../assets/images/gem.png")}
                          alt="img"
                        ></img>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <ul className="plans-description">
                    <li>
                      <img
                        src={require("../../assets/images/check.png")}
                        alt="img"
                      ></img>{" "}
                      Only information about online auctions will be included in the subscription plan.
                    </li>
                    <li>
                      <img
                        src={require("../../assets/images/check.png")}
                        alt="img"
                      ></img>{" "}
                      Add bid on property detail included in the subscription.
                    </li>
                    <li>
                      <img
                        src={require("../../assets/images/check.png")}
                        alt="img"
                      ></img>{" "}
                      Access all search listings.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={4} md={5}>
                <div className="basic-plan-box" id='card2' onClick={(e) => (globalData.plan === null || globalData.plan === 'null') ? activeCard('card2', 219.99) : e.preventDefault}>
                  <i class="fa fa-check select-check" aria-hidden="true"></i>
                  <div className="basic-payment">
                    <div className="plan-text-picture">
                      <div className="plan-text">
                        <p>Business</p>
                        <h3>
                          $ <b>349.99</b> /Year
                        </h3>
                        <div className="special-pricing">
                          <p><span>SPECIAL PRICING</span> Until 12/31/2022</p>
                          <h3>
                            $ <b>219.99</b> /Year
                          </h3>
                        </div>
                      </div>
                      <div className="diomand-picture">
                        <img
                          src={require("../../assets/images/gem.png")}
                          alt="img"
                        ></img>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <ul className="plans-description">
                    <li>
                      <img
                        src={require("../../assets/images/check.png")}
                        alt="img"
                      ></img>{" "}
                      Only information about online auctions will be included in the subscription plan.
                    </li>
                    <li>
                      <img
                        src={require("../../assets/images/check.png")}
                        alt="img"
                      ></img>{" "}
                      Add bid on property detail included in the subscription plan.
                    </li>
                    {/* <Button
                      type="button"
                      className="choose-plan-bt"
                      variant="unset"
                    >
                      choose plan
                    </Button> */}
                  </ul>
                </div>
              </Col>

            </Row>
            {globalData.userType !== "customer"&&
              <Row className="justify-content-center mt-5">
                <Col lg={4} md={5}>
                  <div className="basic-plan-box" id='card3' onClick={(e) => (globalData.plan === null || globalData.plan === 'null') ? activeCard('card3', 399.99) : e.preventDefault()}>
                    <i class="fa fa-check select-check" aria-hidden="true"></i>
                    <div className="basic-payment">
                      <div className="plan-text-picture">
                        <div className="plan-text">
                          <p>Pro</p>
                          <h3 className="subscription-plan-price">
                            $ <b>399.99</b> /Year
                          </h3>
                        </div>
                        <div className="diomand-picture">
                          <img
                            src={require("../../assets/images/gem.png")}
                            alt="img"
                          ></img>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <ul className="plans-description">
                      <li>
                        <img
                          src={require("../../assets/images/check.png")}
                          alt="img"
                        ></img>{" "}
                        Only information about online auctions will be included in the subscription plan.
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/check.png")}
                          alt="img"
                        ></img>{" "}
                        Add bid on property detail included in the subscription plan.
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/check.png")}
                          alt="img"
                        ></img>{" "}
                        Agents wish to be at the top of the list.
                      </li>
                      {/* <Button
                      type="button"
                      className="choose-plan-bt"
                      variant="unset"
                    >
                      choose plan
                    </Button> */}
                    </ul>
                  </div>
                </Col>
              </Row>
            }
            {(globalData.plan === null || globalData.plan === "null") &&
              <div className="next-skip-bt">
                <Button type="button" className="next-step" onClick={() => navigate('/buy_subscription', { state: price })} variant="unset">next step</Button>

                <Button type="button" className="skip-now" variant="unset" onClick={() => navigate('/')}>skip now</Button>
              </div>
            }
          </div>
        </Container>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center otp_popup_verify pt-4">
            <img
              src={require("../../assets/images/successful.svg").default}
              alt=""
            />
            <h5 className="mt-4">Successfully</h5>
            <p>Password reset successfully</p>
            <button
              className="btn okbtn text-white mb-4 mt-2"
              onClick={handleClose}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Subscriptions;
