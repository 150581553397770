import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { context } from "../GlobalContext/GlobalContext";
import { AddToFavourite, FavPropertyList, media_url } from "../Service/Service";




const Favorite = () => {
  const globalData = useContext(context)
  const [propertyList , setPropertyList]= useState([])
  const navigate = useNavigate()
  const PropertyList=()=>{
    FavPropertyList(globalData.token).then(response=>{
      if(response.status===200){
        setPropertyList(response.data.data)
      }
    }).catch(err=>{
      if(err.response.status===400){
        toast.error(err.response.data.message ,{position:"top-center"})
      }
      if(err.response.status===401){
        globalData.setToken('')
        localStorage.clear()
        toast.info("You session has expired please login again!.")
        navigate('/')
      }
    })
  }
  const RemoveFromFavorite=(e,id)=>{
    const payload={
      property:id
    }
    AddToFavourite(globalData.token,payload).then(response=>{
      console.log(response);
      if(response.status===200){
        PropertyList()
      }
    })
  }
  useEffect(()=>{
  {
    globalData.token &&
    PropertyList()
  }
  },[globalData.token])
  return (
    <>
      <section className="hero-banner favorite-banner">
        <Container>
          <p className="property-banner-heading">
            <img src={require("../assets/images/play.png")}></img>favorite properties
          </p>
          <h2>Favorite Properties</h2>
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing
            <br /> and typesetting industry.{" "}
          </p> */}
        </Container>
      </section>
      <section className="favorite-property mt-5">
        <Container>
        <Row>
            {propertyList.length>0 && propertyList.map((property)=>
            
            <Col md="4" className="mb-3">
            <div className="favorite-proprty-one">
              <span  onClick={()=>navigate(`/property_regular?id=${property.id}`)} style={{cursor:"pointer"}}>
              <div className="favorite-property-image">
                <img src={media_url +property.images[0]?.image} alt="img"></img>
              </div>
              <h4>{property.name}</h4>
              <p>
                {property.description}
              </p>
              <h5>
                <small>from </small>${property.property_type==="sale"?Number(property.list_price).toLocaleString('en-US'):Number(property.base_price).toLocaleString('en-Us')}
              </h5>
              <div className="service-property">
                <div className="service-one">
                  <img src={require("../assets/images/bed1.png")}></img>
                  <div>
                    <p>{property.number_of_bedrooms}</p>
                  </div>
                </div>
                <div className="service-one">
                  <img src={require("../assets/images/bath1.png")}></img>
                  <div>
                    <p>{property.number_of_full_bathrooms}</p>
                  </div>
                </div>
                <div className="service-one">
                  <img src={require("../assets/images/balcony1.png")}></img>
                  <div>
                    <p>{property.number_of_balcony}</p>
                  </div>
                </div>
                <div className="service-one">
                  <img src={require("../assets/images/food1.png")}></img>
                  <div>
                    <p>{property.number_of_kitchen}</p>
                  </div>
                </div>
              </div>
              </span>
              <p className="red-text" onClick={(e)=>RemoveFromFavorite(e,property.id)}><i className="fa fa-trash mr-2" aria-hidden="true"></i> Remove favorite</p>
            </div>
          </Col>
            
            )}
           
            
          </Row>
          {propertyList.length===0 &&
          <h3 className="text-center py-4">No Favorite Property.</h3>
          }
        </Container>
      </section>

      <section className="house-details">
        <div className="bottom-banner">
          <Container>
            <div className="banner-botton-content">
              <p className="property-banner-heading">
                <img src={require("../assets/images/play.png")}></img>let’s take
                a tour
              </p>
              <h2>
                Property Smarter,<br></br> Quicker & Anywhere
              </h2>
              <Link to="">
                Join now <i className="fa fa-play" aria-hidden="true"></i>
              </Link>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};
export default Favorite;
