import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  Container,
  Form,
  Modal,
  Pagination,
  Row,
  Table,

} from "react-bootstrap";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { Wrapper } from "@googlemaps/react-wrapper";
import { context } from "../GlobalContext/GlobalContext";
import { GuestSearchProperty, media_url, SearchProperty } from "../Service/Service";
import MyMap from "../Google Map/GoogleMap";
import MyMarker from "../Google Map/MapMarker";
import { toast } from 'react-toastify';
import Paginate from "../common/Pagination";
import PropertyTimer from "./PropertyTimer";
import dummyImg from '../assets/images/propti-dummy.png'
import Swal from 'sweetalert2'



const Properties = () => {

  let globalData = useContext(context)
  // const [searchParams, setSearchParams] = useSearchParams();
  const [query] = useSearchParams();
  const [tab, setTab] = useState(query.get('type'))
  const [myPropertyTab, setMyPropertyTab] = useState(query.get('myProp'))
  const [page, setPage] = useState(Number(query.get("page")))
  const [totalCount, setTotalCount] = useState(0)
  const [search, setSearch] = useState(false);
  const [markerLocation, setMarkerLocation] = useState([])
  const loc = useLocation()
  const navigate = useNavigate()
  {/* query.get('lat') || (loc.state ? loc.state.latitude :*/ }
  const [data, setData] = useState({
    address: query.get('address') || (loc.state ? loc.state.address : ''),
    latitude: query.get('lat') || (loc.state ? loc.state.latitude : '') || globalData.currentLocation.lat,
    longitude: query.get('long') || (loc.state ? loc.state.longitude : '') || globalData.currentLocation.lng,
    property_status: query.get('status') || 'all',
    auction_type: query.get('auction_type') || 'all'
  })
  const [center, setCenter] = useState({
    lat: Number(query.get('lat')) || 0,
    lng: Number(query.get('long')) || 0
  })

  const [zoom, setZoom] = useState(11)
  const [Propertylist, setPropertyList] = useState([])
  const [dragEndCenter, setDragEndCenter] = useState({})

  const HandleTabs = (event, type) => {
    setTab(type);
    setPage(1)
    if (globalData.userType !== "customer") {
      setMyPropertyTab('sale')
    }
    else {

      setMyPropertyTab("null")
    }
    setData({ ...data, property_status: 'all', auction_type: 'all' })
    var ele = document.getElementsByClassName("auction-bt")
    Array.from(ele).forEach((element) => { element.classList.remove('active') })
    event.target.classList.add("active")
    navigate(`/properties?type=${type}&myProp=${myPropertyTab}&lat=${data.latitude}&long=${data.longitude}&page=1`)
  }


  const MyPropertTabs = (event, type) => {
    setMyPropertyTab(type);
    setPage(1)
    setData({ ...data, property_status: "all" })
    var ele = document.getElementsByClassName("auc-bt")
    Array.from(ele).forEach((element) => { element.classList.remove('active') })
    event.target.classList.add("active")
    navigate(`/properties?type=${tab}&myProp=${type}&lat=${data.latitude}&long=${data.longitude}&status=${data.property_status}&page=1`)
  }

  const HandleSearch = (e, page) => {

    setPage(page)
    setSearch(!search)
    if (tab === "my_property") {

      navigate(`/properties?type=${tab}&myProp=${myPropertyTab}&status=${data.property_status}&address=${data.address}&lat=${data.latitude}&long=${data.longitude}&page=${1}`)
    }
    if (tab === "auction") {
      navigate(`/properties?type=${tab}&auction_type=${data.auction_type}&address=${data.address}&lat=${data.latitude}&long=${data.longitude}&page=${1}`)
    }
    if (tab === "sale") {

      navigate(`/properties?type=${tab}&address=${data.address}&lat=${data.latitude}&long=${data.longitude}&page=${1}`)
    }
  }

  const HandlePage = (page) => {
    setPage(page)
    if (tab === "my_property") {

      navigate(`/properties?type=${tab}&myProp=${myPropertyTab}&status=${data.property_status}&page=${page}`)
    }
    if (tab === "auction") {
      navigate(`/properties?type=${tab}&auction_type=${data.auction_type}&lat=${data.latitude}&long=${data.longitude}&page=${page}`)
    }
    if (tab === "sale") {

      navigate(`/properties?type=${tab}&lat=${data.latitude}&long=${data.longitude}&page=${page}`)
    }
  }

  const searchProperty = (status, auc_type, page) => {

    globalData.setLoader(true)
    if (globalData.userType === "customer") {

      SearchProperty(false, tab, data.auction_type, "listed", data.latitude, data.longitude, page, globalData.token).then(response => {
        if (response.status === 200) {
          globalData.setLoader(false)
          setTotalCount(response.data.totalItems)
          setCenter({ ...center, lat: response.data.data[0]?.latitude, lng: response.data.data[0]?.longitude })
          // setZoom(18)
          var temp = []
          response.data.data.map((property) => temp.push({ loc: { lat: property.latitude, lng: property.longitude }, id: property.id, title: property.name }), setMarkerLocation(temp))
          setPropertyList(response.data.data)
        }
      }).catch(err => {
        globalData.setLoader(false)
        if (err.response.status === 400) {
          toast.error(err.response.data.message)
        }
        if (err.response.status === 401) {
          globalData.setToken('')
          localStorage.clear()
          toast.info("You session has expired please login again!.")
          navigate('/')
        }
      })

    }
    if (globalData.userType === "seller") {

      SearchProperty(tab === "my_property" ? true : false, tab === "my_property" ? myPropertyTab : tab, auc_type === "" ? data.auction_type : auc_type, status === '' ? data.property_status : status, tab === "my_property" ? '' : data.latitude, tab === "my_property" ? '' : data.longitude, page, globalData.token).then(response => {
        if (response.status === 200) {
          globalData.setLoader(false)
          setTotalCount(response.data.totalItems)
          setCenter({ ...center, lat: response.data.data[0]?.latitude, lng: response.data.data[0]?.longitude })
          var temp = []
          response.data.data.map((property) => temp.push({ loc: { lat: property.latitude, lng: property.longitude }, id: property.id, title: property.name }), setMarkerLocation(temp))
          setPropertyList(response.data.data)
        }
      }).catch(err => {
        globalData.setLoader(false)
        if (err.response.status === 400) {
          toast.error(err.response.data.message)
        }
        if (err.response.status === 401) {
          globalData.setToken('')
          localStorage.clear()
          toast.info("You session has expired please login again!.")
          navigate('/')
        }
      })
    }
    if (globalData.userType === "agent") {

      SearchProperty(tab === "my_property" ? true : false, tab === "my_property" ? myPropertyTab : tab, auc_type === "" ? data.auction_type : auc_type, status === '' ? data.property_status : status, tab === "my_property" ? '' : data.latitude, tab === "my_property" ? '' : data.longitude, page, globalData.token).then(response => {
        if (response.status === 200) {
          globalData.setLoader(false)
          setTotalCount(response.data.totalItems)
          setCenter({ ...center, lat: response.data.data[0]?.latitude, lng: response.data.data[0]?.longitude })

          var temp = []
          response.data.data.map((property) => temp.push({ loc: { lat: property.latitude, lng: property.longitude }, id: property.id, title: property.name }), setMarkerLocation(temp))
          setPropertyList(response.data.data)
        }
      }).catch(err => {
        globalData.setLoader(false)

        if (err.response.status === 400) {
          toast.error(err.response.data.message)
        }
        if (err.response.status === 401) {

          globalData.setToken('')
          localStorage.clear()
          toast.info("You session has expired please login again!.")
          navigate('/')
        }
      })
    }
    if (globalData.userType === "" && query.get('type') != "my_property") {

      GuestSearchProperty(false, tab, data.auction_type, "listed", data.latitude, data.longitude, page).then(response => {
        if (response.status === 200) {
          globalData.setLoader(false)
          setTotalCount(response.data.totalItems)
          setCenter({ ...center, lat: response.data.data[0]?.latitude, lng: response.data.data[0]?.longitude })
          var temp = []
          response.data.data.map((property) => temp.push({ loc: { lat: property.latitude, lng: property.longitude }, id: property.id, title: property.name }), setMarkerLocation(temp))
          setPropertyList(response.data.data)
        }
      }).catch(err => {
        globalData.setLoader(false)
        if (err.response.status === 400) {
          toast.error(err.response.data.message)
        }
        // if (err.response.status === 401) {
        //   globalData.setToken('')
        //   localStorage.clear()
        //   toast.info("You session has expired please login again!.")
        //   navigate('/')
        // }
      })
    }
  }

  const BookProperty = (e, id, type) => {
    // alert()
    // searchParams.get("path")
    console.log("...........", e, id, type,)
    if (globalData.userType === '') {
      // navigate('/signup?path='+window.location.pathname + window.location.search)
      navigate('/login?path=' + window.location.pathname + window.location.search)
    }
    else {
      navigate(`/property_regular?id=${id}`, { state: type })
    }
  }

  //on map scroll function
  // const mapDrag = (mapdata) => {
  //   setDragEndCenter(mapdata)
  //   setData({ ...data, latitude: mapdata.lat, longitude: mapdata.lng })

  //   if (tab === "my_property") {
  //     navigate(`/properties?type=${tab}&myProp=${myPropertyTab}&status=${data.property_status}&lat=${mapdata.lat}&long=${mapdata.lng}&page=${page}`)
  //   }
  //   if (tab === "auction") {
  //     navigate(`/properties?type=${tab}&auction_type=${data.auction_type}&lat=${mapdata.lat}&long=${mapdata.lng}&page=${page}`)
  //   }
  //   if (tab === "sale") {
  //     navigate(`/properties?type=${tab}&lat=${mapdata.lat}&long=${mapdata.lng}&page=${page}`)
  //   }

  // }




  useEffect(() => {


    const error = (err) => {

      if (err.message !== undefined) {
        // Swal.fire('', "Please enable location permission.", 'warning')
        Swal.fire({
          icon: "info",
          html:
            // <div className="alert-message">
            "You have denied us to access your location." +
            "<p class='message-alert'>1. Go to browser settings.</p>" +
            "<p>2. Allow your location.</p>"


          // </div>
        })
        navigate('/')
      }

    }
    const success = (pos) => {
      // console.log(pos);
    }
    navigator.geolocation.getCurrentPosition(success, error)
    {
      (globalData.token != '' || globalData.userType === '') &&
        searchProperty('', '', page)

    }

  }, [tab, myPropertyTab, data.auction_type, page, data.property_status, globalData.token, search, query])


  return (
    <>
      <section className="hero-banner">
        <Container>
          <p className="property-banner-heading">
            <img src={require("../assets/images/play.png")}></img>Properties
          </p>
          <h2>{tab !== "my_property" ? 'Properties' : ''}</h2>
          {/* <p>
              Lorem Ipsum is simply dummy text of the printing
              <br /> and typesetting industry.{" "}
            </p> */}
        </Container>
      </section>
      <Container>
        <div className="properties-tab-search-area">
          <Row>
            <Col lg={4} md={12}>

              <div className="search-input">
                <Autocomplete apiKey="AIzaSyCTg77HJ0mIEmvnMn_lOHbd_x8QEk9zD0g" className="form-control" options={{ types: ["geocode", "establishment"] }} placeholder="Search for location"
                  onPlaceSelected={(place) => {
                    setData({ ...data, address: place.formatted_address, latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() })
                  }}
                  value={data.address}
                  // onKeyDown={(e)=>e.preventDefault()}
                  onChange={(e) => setData({ ...data, address: e.target.value, latitude: '', longitude: '' })} />
                {data.address !== '' &&
                  <img src={require("../assets/images/cancel.svg").default} onClick={() => setData({ ...data, address: '', latitude: globalData.currentLocation.lat, longitude: globalData.currentLocation.lng })} className="position_img" />}
                <img src={require("../assets/images/search.png")} onClick={(e) => HandleSearch(e, 1)} />

              </div>
            </Col>


            <Col lg={8} md={12}>
              <div className="tab-area-sale">
                {(globalData.userType === "seller" || globalData.userType === "agent") &&
                  <Button type="button" className={`auction-bt ${query.get('type') === "my_property" && "active"}`} onClick={(e) => HandleTabs(e, "my_property")} variant="unset">
                    MY PROPERTIES
                  </Button>
                }
                <Button type="button" variant="unset" onClick={(e) => HandleTabs(e, "sale")} className={`auction-bt ${query.get('type') === "sale" && "active"}`}>
                  FEATURED
                </Button>
                <Button type="button" variant="unset" onClick={(e) => HandleTabs(e, "auction")} className={`auction-bt ${query.get('type') === "auction" && "active"}`}>
                  AUCTION
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      {/* MY PROPERTIES SECTION FOR SELLER & BROKER */}
      <section className="map-proprty-area">
        <Container fluid>
          <Row>
            <Col lg={12} className="mb-2">
              <div className="property-button-selects">
                {(tab === "my_property" && globalData.userType !== "customer" && globalData.userType !== "") &&
                  <>
                    <Button type="button" variant="unset" onClick={(e) => MyPropertTabs(e, "sale")} className={`auc-bt ${query.get('myProp') === "sale" && "active"}`}>
                      REGULAR PROPERTIES
                    </Button>
                    <Button type="button" variant="unset" onClick={(e) => MyPropertTabs(e, "auction")} className={`auc-bt ${query.get('myProp') === "auction" && "active"}`}>
                      AUCTION PROPERTIES
                    </Button>

                    <Form.Select aria-label="Default select example" value={data.property_status} className="auction-select" onChange={(e) => { setData({ ...data, property_status: e.target.value }); navigate(`/properties?type=${tab}&myProp=${myPropertyTab}&lat=${data.latitude}&long=${data.longitude}&status=${e.target.value}&page=${page}`) }}>
                      <option value="all">All </option>
                      <option value="listed">Listed </option>
                      <option value="under_review">Under Reviewed </option>
                    </Form.Select>
                  </>
                }
                {tab === "auction" &&
                  <Form.Select aria-label="Default select example" value={data.auction_type} className="auction-select" onChange={(e) => { setData({ ...data, auction_type: e.target.value }); navigate(`/properties?type=${tab}&lat=${data.latitude}&long=${data.longitude}&auction_type=${e.target.value}&page=${1}`) }}>
                    <option value="all">All Auction</option>
                    <option value="in_person">In Person</option>
                    <option value="online">Online Auctions</option>
                  </Form.Select>
                }
              </div>
            </Col>
            <Col lg={6} md={12} className="p-0">


              {/* GOOGlE MAP */}

              <div className="map-side">
                <Wrapper apiKey={"AIzaSyCTg77HJ0mIEmvnMn_lOHbd_x8QEk9zD0g"}>
                  <MyMap center={center} zoom={zoom} > {/* mapDrag={(data) => mapDrag(data)}*/}
                    {/*Propertylist.length > 0 && Propertylist.map((property, index) =>*/}

                    {/* <MyMarker key={property.id} position={{ lat: property.latitude, lng: property.longitude }} icon={{ path: window.google.maps.SymbolPath.CIRCLE, fillColor: "red", fillOpacity: 1.0, strokeColor: "red", strokeWeight: 0, scale: 8 }} index={index} propId={property.id} locations={markerLocation} length={totalCount} title={property.name} /> */}
                    {markerLocation.length > 0 &&
                      <MyMarker markerData={markerLocation} length={totalCount} />
                    }
                    {/* )} */}
                  </MyMap>
                </Wrapper>
              </div>

            </Col>


            {/* PrOPERTY LISTING */}

            <Col lg={6} md={12} className="p-0">
              <section className="0 position_relative">
                <Row>

                  {Propertylist?.length > 0 ? Propertylist?.map((property) =>
                    <Col md="6" className="mb-3" key={property.id}>
                      <div className="favorite-proprty-one" onClick={(e) => BookProperty(e, property.id, property.property_type)}>
                        <div className="favorite-property-image" style={{ cursor: 'pointer' }} >
                          <img
                            src={property.images[0]?.image ? media_url + property.images[0]?.image : dummyImg}
                            alt="img"
                          ></img>
                        </div>
                        <h4>{property.name}</h4>
                        <p>
                          {property.description}
                        </p>
                        <div className="pize-auction">
                          {property.auction_type !== "in_person" &&
                            <h5>
                              <small>from </small>${property.property_type === "sale" ? Number(property.list_price).toLocaleString('en-Us') : Number(property.base_price).toLocaleString('en-US')}
                            </h5>}
                          {property.auction_type === "in_person" &&
                            <span className="color-green">
                              <i className="fa fa-circle" aria-hidden="true"></i>
                              In-Person Auction
                            </span>}
                        </div>
                        {property.auction_type !== "in_person" &&
                          <div className="service-property">
                            <div className="service-one">
                              <img src={require("../assets/images/bed1.png")}></img>
                              <div>
                                <p>{property.number_of_bedrooms}</p>
                              </div>
                            </div>
                            <div className="service-one">
                              <img
                                src={require("../assets/images/bath1.png")}
                              ></img>
                              <div>
                                <p>{property.number_of_full_bathrooms + property.number_of_half_bathrooms}</p>
                              </div>
                            </div>
                            <div className="service-one">
                              <img
                                src={require("../assets/images/balcony1.png")}
                              ></img>
                              <div>
                                <p>{property.number_of_balcony}</p>
                              </div>
                            </div>
                            <div className="service-one">
                              <img
                                src={require("../assets/images/food1.png")}
                              ></img>
                              <div>
                                <p>{property.number_of_kitchen}</p>
                              </div>
                            </div>
                          </div>}
                        {
                          new Date(property.show_start_time) > new Date() ?
                            (query.get('type') === "auction") &&
                            <>
                              <h5><small>Auction start in:</small></h5>
                              <PropertyTimer date={property.show_start_time} />
                            </>
                            :
                            (query.get('type') === "auction") &&
                            <>
                              {
                                new Date(property?.show_end_time) > new Date() ?
                                  <>
                                    <h5><small>Auction ends in:</small></h5>
                                    <PropertyTimer date={property?.show_end_time} />
                                  </>
                                  :
                                  <>
                                    <h5><small>Auction ended</small></h5>
                                    <hr />

                                    <div className="service-property">
                                      <div className="service-one">
                                        <h6>
                                          0<span>Days</span>
                                        </h6>
                                      </div>
                                      <div className="service-one">
                                        <h6>
                                          0<span>Hours</span>
                                        </h6>
                                      </div>
                                      <div className="service-one">
                                        <h6>
                                          0<span>Minutes</span>
                                        </h6>
                                      </div>
                                      <div className="service-one">
                                        <h6>
                                          0<span>Seconds</span>
                                        </h6>
                                      </div>
                                    </div>
                                    < hr />
                                  </>
                              }

                            </>

                        }
                        <p className="blue-text" onClick={(e) => BookProperty(e, property.id, property.property_type)}>
                          View Details{" "}
                          <i className="fa fa-play" aria-hidden="true"></i>
                        </p>
                      </div>
                    </Col>

                  )
                    :
                    <p className="nofound">No Property Found</p>
                  }

                </Row>
              </section>
              {Propertylist.length >= 5 &&
                <div className="pagination-property text-center mt-3">
                  <Paginate active={page} total={totalCount} HandlePagination={(e) => HandlePage(e)} />
                </div>}
            </Col>
            <></>
          </Row>
        </Container>
      </section>
      <div className="bottom-banner">
        <Container>
          <div className="banner-botton-content">
            <p className="property-banner-heading">
              <img src={require("../assets/images/play.png")}></img>let’s take a
              tour
            </p>
            <h2>
              Property Smarter,<br></br> Quicker & Anywhere
            </h2>
            <Link to="">
              Join now <i className="fa fa-play" aria-hidden="true"></i>
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Properties;
