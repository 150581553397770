import { getValue } from "@testing-library/user-event/dist/utils";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const Bid = () => {
  const { register, formState: { errors },handleSubmit ,getValues,setError,clearErrors} = useForm();
  const loc = useLocation()
  const [query] = useSearchParams()

  const[highestPrice , setHighestPrice]=useState('')

  let navigate = useNavigate();

  const onSubmit=data=>{
    console.log(data);
    // if(getValues('bidAmount')<highestPrice){
    //   setError("bidAmount", { type: "focus" ,message:"Bid amount should be greater than highest price."},{shouldFocus:true})
    //   return false;
    // }
    // clearErrors()
    navigate(`/payment_process?id=${query.get('id')}`,{state:{"amount":data.bidAmount,"entry_fee":loc.state.entry_fee}})
  }
  let socket = new WebSocket(`wss:api.realzon.net/ws/bid/${query.get('id')}/`)  
  console.log(socket);
  socket.onmessage = (event)=> {
    console.log('hi',loc)
  setHighestPrice(JSON.parse(event.data).bid_price);
  };
  
 
  return (
    <>
      <section className="login-area forgot-area">
        <div className="container">
          <div className="login-box-area">
            <div className="row justify-content-center">
              <aside className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                <div className="login-box login-form bids-form">
                  <h2 className="text-center mb-5">Bids</h2>
                  <div className="bid-page-area">
                    <div>
                      <p>Property Name</p>
                      <p>Property Location</p>
                      <p>Possession</p>
                      <p>Base Price</p>
                    </div>
                    <div className="text-right text-black">
                      <p>{loc.state.name}</p>
                      <p>{loc.state.location}</p>
                      <p>{moment(loc.state.possesionDate).format('DD/MM/YYYY')}</p>
                      <p>${Number(loc.state.basePrice).toLocaleString('en-US')}</p>
                    </div>
                  </div>
                  <p className="mt-4">Highest price</p>
                  <h5>
                    <span>$</span>{Number(highestPrice).toLocaleString('en-US')}
                  </h5>
                  <p style={{ fontStyle: "italic" }}>current price by other bidders.</p>

                  <div className="my-4 pt-3">
                    <label className="form-label">
                      Place bid amount
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Enter place bid amount"
                      {...register('bidAmount', { required: "Bid amount is required.", pattern: { value: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/, message: "Only numeric characters are allowed." } ,validate:val=>val>Number(highestPrice) || "Bid amount should be greater than highest price."})}
                    />
                    {errors.bidAmount && <span style={{ color: "red" }}>{errors.bidAmount.message}</span>}
                  </div>
                  <p className="btn sign-btn fill-btn w-100 ms-0 mt-3 mb-4 text-white" onClick={handleSubmit(onSubmit)}>
                    Next
                  </p>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};
export default Bid;
