import { useEffect, useState } from "react"
import { context } from "./GlobalContext"


const Context = (props) => {
    
    const [token, setToken]=useState('')
    const [name, setName]=useState('')
    const [image, setImage]=useState('')
    const [show, setShow] = useState(false);
    const [loader , setLoader]=useState(false)
    const [email, setEmail]=useState('')
    const [userId, setUserId]=useState('')
    const [userType, setUserType]=useState('')
    const [plan, setPlan] = useState(null)
    const [planStartDate, setPlanStartDate] = useState(null)
    const [planEndDate, setPlanEndDate] = useState(null)
    const [currentLocation, setCurrentLocation] = useState({lat:0,lng:0})
    const [fireBaseToken, setFireBaseToken] = useState('')
    useEffect(()=>{
        const success=(pos) =>{
            console.log(pos.coords);
            const crd = pos.coords;
            setCurrentLocation({...currentLocation,lat:crd.latitude,lng:crd.longitude})
          }
        
          navigator.geolocation.getCurrentPosition(success)
        if(localStorage.getItem('token')!=='' && localStorage.getItem('token')!==null){
            setToken(localStorage.getItem('token'))
            setUserType(localStorage.getItem('userType'))
            setUserId(localStorage.getItem('userid'))
            setName(localStorage.getItem('name'))
            setImage(localStorage.getItem("image"))
            setEmail(localStorage.getItem('email'))
            setPlan(localStorage.getItem('plan'))
            setPlanStartDate(localStorage.getItem('start_date'))
            setPlanEndDate(localStorage.getItem('end_date'))
         
        }

        
        
    },[])

    
    var data={
        token,setToken,
        name,setName,
        image, setImage,
        email,setEmail,
        userId,setUserId,
        show,setShow,
        loader , setLoader,
        userType, setUserType,
        plan,setPlan,
        currentLocation, setCurrentLocation,
        planStartDate, setPlanStartDate,
        planEndDate, setPlanEndDate,
        fireBaseToken, setFireBaseToken

    }
    return (
        <context.Provider value={data}>
            {props.children}
        </context.Provider>
    );
}
export default Context;