import { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { context } from '../GlobalContext/GlobalContext';

const SuccessModal = (props) => {
    
    // let globalData= useContext(context)
    return (
        <Modal show={props.show} onHide={() => props.onHide()} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="text-center otp_popup_verify pt-4">
                    <img src={require('../assets/images/successful.svg').default} alt="" />
                    <h5 className="mt-4">Successfully</h5>
                    <p>{props.message}</p>
                    <button className="btn okbtn text-white mb-4 mt-2" onClick={()=>props.onHide() } >OK</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default SuccessModal;