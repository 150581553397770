import { useContext, useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useForm ,Controller} from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import creditCardType from 'credit-card-type';
import masterImg from '../assets/images/master.png';
import visaImg from '../assets/images/visa.png';
import dinerClubImg from '../assets/images/diners-club.png';
import americanExpImg from '../assets/images/american-express.png';
import discoverImg from '../assets/images/discover.png';
import jcbImg from '../assets/images/jcb.png';
import unionPayImg from '../assets/images/unionpay.png';
import maestroImg from '../assets/images/maestro.png';
import mirImg from '../assets/images/mir.png';
import eloImg from '../assets/images/elo-final.png';
import creditImg from '../assets/images/credit.png';
import hiperCardImg from '../assets/images/hipercard.png';
import moment from "moment";
import { context } from "../GlobalContext/GlobalContext";
import { PlaceBid } from "../Service/Service";
import { toast } from "react-toastify";
const Payment_process = () => {
  const { register, formState: { errors }, handleSubmit,control } = useForm();
  const loc = useLocation()
  const [query] = useSearchParams()
  const [show, setShow] = useState(false);
  const handleClose = () => {
    navigate('/')
    setShow(false);
  };
  let globalData=useContext(context)
  const handleShow = () => setShow(true);
  let navigate=useNavigate()


  const CheckCardType = (val) => {
    console.log(val.length);
    if (val.length >= 4) {

      let type = creditCardType(val)[0]?.type
      console.log(type);
      if (type === "mastercard") {
        document.getElementById("card-img").src = masterImg
      }
      if(type==="visa"){
        document.getElementById("card-img").src = visaImg
      }
      if(type==="american-express"){
        document.getElementById("card-img").src = americanExpImg
      }
      if(type==="diners-club"){
        document.getElementById("card-img").src = dinerClubImg
      }
      if(type==="discover"){
        document.getElementById("card-img").src = discoverImg
      }
      if(type==="jcb"){
        document.getElementById("card-img").src = jcbImg
      }
      if(type==="unionpay"){
        document.getElementById("card-img").src = unionPayImg
      }
      if(type==="maestro"){
        document.getElementById("card-img").src = maestroImg
      }
      if(type==="mir"){
        document.getElementById("card-img").src = mirImg
      }
      if(type==="elo"){
        document.getElementById("card-img").src = eloImg
      }
      if(type==="hiper" || type==="hipercard"){
        document.getElementById("card-img").src = hiperCardImg
      }
      if(type===undefined){
        document.getElementById("card-img").src = creditImg
      }
    }
    else{
      document.getElementById("card-img").src = creditImg
    }

  }

  const onSubmit=(data)=>{
    globalData.setLoader(true)
    console.log(moment(data.cardExpDate).format("MM/YYYY").split('/')[0],loc.state)
    const payload={
      "fees":Number(loc.state.entry_fee),
      "name":data.name,
      "card_number":data.cardNumber,
      "exp_month":moment(data.cardExpDate).format("MM/YYYY").split('/')[0],
      "exp_year":moment(data.cardExpDate).format("MM/YYYY").split('/')[1],
      "cvv":data.cvv,
      "property":query.get('id'),
      "existing_card":"",
      "bid_price":loc.state.amount
  }
  PlaceBid(globalData.token,payload).then(response=>{
    if(response.status===200){
      globalData.setLoader(false)
      handleShow()
    }
  }).catch(err=>{
    globalData.setLoader(false)
    if (err.response.status === 400) {
      toast.error(err.response.data.message)
    }
    if (err.respoonse.status === 401) {
      globalData.setToken('')
      localStorage.clear()
      toast.info("You session has expired please login again!.")
      navigate('/')
    }
  })
  }

  

  return (
    <>
      <section className="login-area forgot-area">
        <div className="container">
          <div className="login-box-area">
            <div className="row justify-content-center">
              <aside className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                <div className="login-box login-form bids-form">
                  <h2 className="text-center mb-5">Payment Process</h2>
                  <div className="mt-4" style={{ position: "relative" }}>
                    <label className="form-label mb-0">
                      Card Number
                    </label>
                    <p>Enter the 16 digit card number on the card.</p>
                    <div className="info-details">
                    <input
                      type="text"
                      className="form-control card-input  "
                      id=""
                      placeholder="3223 **** **** ****"
                      maxLength={16}
                      {...register('cardNumber', { required: "Card number is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." }, onChange: e => CheckCardType(e.target.value) })}
                    />
                    {errors.cardNumber && <span style={{ color: "red" }}>{errors.cardNumber.message}</span>}
                    <img
                      id="card-img"
                      src={creditImg}
                      alt="image"
                      className="master-icon"
                    ></img>
                    </div>
              

                  </div>
                  <div className="mt-4">
                    <label className="form-label">
                      Your name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Enter name"
                      maxLength={30}
                      {...register('name', { required: "Name is required." })}
                    />
                    {errors.name && <span style={{ color: "red" }}>{errors.name.message}</span>}
                  </div>
                  <Row>
                    <Col md={6}>
                      <div className="mt-4">
                        <label className="form-label">
                          Exp Date
                        </label>
                        <Controller
                          control={control}
                          name="cardExpDate"
                          rules={{ required: "Expiry date is required." }}
                          render={({ field: { onChange, value } }) => (
                            <div className="col-md-8">
                              <DatePicker className="form-control" selected={value} dateFormat="MM/yyyy" placeholderText="09/2025" showMonthYearPicker onKeyDown={(e)=>e.preventDefault()} minDate={new Date(moment(new Date()).subtract(1,'month').endOf('month'))}  onChange={(date) => onChange(date) } />
                              {errors.cardExpDate && <p style={{ color: "red", textAlign: "left" }}>{errors.cardExpDate.message}</p>}
                            </div>

                          )}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-4">
                        <label className="form-label">
                          CVV
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id=""
                          placeholder="***"
                          maxLength={4}
                          {...register('cvv', { required: "CVV is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                        />
                        {errors.cvv && <span style={{ color: "red" }}>{errors.cvv.message}</span>}
                      </div>
                    </Col>
                  </Row>
                  <p className="mb-0 mt-4 pt-2">
                    Entry fees{" "}
                    <img src={require("../assets/images/info.png")}></img>
                  </p>
                  <h5>
                    <span>$</span>{Number(loc.state.entry_fee).toLocaleString('en-US')}
                  </h5>

                  <p className="btn sign-btn fill-btn w-100 ms-0 mt-3 mb-4 text-white" onClick={handleSubmit(onSubmit)}>
                    pay now
                  </p>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center otp_popup_verify pt-4">
            <img
              src={require("../assets/images/successful.svg").default}
              alt=""
            />
            <h5 className="mt-4">Successful!</h5>
            <p> Your transaction has been completed successfully.</p>
            <button
              className="btn okbtn text-white mb-4 mt-2"
              onClick={handleClose}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Payment_process;
