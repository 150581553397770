import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { context } from "../GlobalContext/GlobalContext";
import { AgentList, media_url, MotargeDetails, attorneys } from "../Service/Service";

const Agent_listing = () => {
    let globalData = useContext(context);
    const [mortageList, setMortageList] = useState([])
    const [agentList, setAgentList] = useState({});
    const [atterneyList, setAtterneyList] = useState([])
    const [key, setKey] = useState('agent')
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('agent')

    // agent listing

    const GetAgentList = () => {
        globalData.setLoader(true);
        AgentList()
            .then((response) => {
                if (response.status === 200) {
                    globalData.setLoader(false);
                    setAgentList(response.data.data);
                }
            })
            .catch((err) => {
                globalData.setLoader(false);
                if (err.response.status === 400) {
                    toast.error(err.response.data.message);
                }
                if (err.response.status === 401) {
                    globalData.setToken("");
                    localStorage.clear();
                    toast.info("You session has expired please login again!.");
                    navigate("/");
                }
            });
    };

    // mortage listing

    const mortageListing = () => {
        globalData.setLoader(true)
        MotargeDetails().then((res) => {
            if (res.status === 200) {
                globalData.setLoader(false);
                console.log("res........", res?.data?.data)
                setMortageList(res?.data?.data)
            }
        })
            .catch((error) => {
                globalData.setLoader(false);
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                }
                if (error.response.status === 401) {
                    globalData.setToken("");
                    localStorage.clear();
                    toast.info("You session has expired please login again!.");
                    navigate("/");
                }
            })
    }

    // attorneys listing

    const AttorneysListing = () => {
        globalData.setLoader(true)
        attorneys().then((res) => {
            if (res.status === 200) {
                globalData.setLoader(false);
                console.log("res......aaaaaaaaaaaaaa..", res)
                setAtterneyList(res?.data?.data)
            }
        })
            .catch((error) => {
                globalData.setLoader(false);
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                }
                if (error.response.status === 401) {
                    globalData.setToken("");
                    localStorage.clear();
                    toast.info("You session has expired please login again!.");
                    navigate("/");
                }
            })
    }



    useEffect(() => {
        // globalData.setLoader(true)
        console.log(globalData.token);
        if (activeTab == "agent") {
            GetAgentList();
        } else
            if (activeTab == "mortage") {
                mortageListing();
            } else
                if (activeTab == "attorney") {
                    AttorneysListing();
                }

        let data = window.location;

        if (globalData.token || localStorage.getItem("token")) {
            if (
                globalData.userType == "seller" ||
                localStorage.getItem("userType") == "seller"
            ) {
                window.location.href = "/";
            }
        } 
        
    }, []);

    function tabChnage(tabName) {
        setActiveTab(tabName)
        if (tabName == "agent") {
            GetAgentList();
        } else
            if (tabName == "mortage") {
                mortageListing();
            } else
                if (tabName == "attorney") {
                    AttorneysListing();
                }


    }

    return (
        <>
            <section className="hero-banner  about-bg">
                <Container>
                    <p className="property-banner-heading">
                        <img src={require("../assets/images/play.png")}></img>featured agent
                    </p>
                    <h2>Featured agent</h2>
                    {/* <p>
                        Lorem Ipsum is simply dummy text of the printing
                        <br /> and typesetting industry.{" "}
                    </p> */}
                </Container>
            </section>
            <Container>
                <div className="properties-tab-search-area">
                    <Row className="justify-content-center">
                        <Col lg={8} md={12}>
                            <div className="tab-area-sale">
                                {/* {(globalData.userType === "seller" ||
                                    globalData.userType === "agent") && (
                                        <Button type="button" className="" onClick="" variant="unset">
                                            MY PROPERTIES
                                        </Button>
                                    )} */}

                                {/* <Button type="button" variant="unset" onClick="" className="auction-bt active">
                  AGENTS
                </Button>
                <Button type="button" variant="unset" onClick="" className="auction-bt">
                  MORTGAGE
                </Button>
                <Button type="button" variant="unset" onClick="" className="auction-bt">
                  ATTORNEYS
                </Button> */}
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    debugger
                                    onSelect={(k) => { setKey(k); tabChnage(k); }}
                           
                                >
                                    <Tab eventKey="agent" title="AGENTS">
                                    </Tab>
                                    <Tab eventKey="mortage" title="MORTGAGE">
                                    </Tab>
                                    <Tab eventKey="attorney" title="ATTORNEYS">
                                    </Tab>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Container className="agents-section">
                {activeTab == "agent" && <h6 className="mb-3">Featured agent</h6>}
                <Row>
                    {activeTab == "agent" && <>
                        {agentList["featured_agents"]?.length > 0 ? (
                            agentList["featured_agents"].map((agent, index) => (
                                <Col
                                    style={{ cursor: "pointer" }}
                                    key={index}
                                    md={6}
                                    onClick={() => {
                                        globalData.token
                                            ? navigate(`/agent_profile?id=${agent.id}`)
                                            : navigate("/login?path=" + window.location.pathname);
                                    }}
                                >
                                    <div className="agent-info mb-4">
                                        <div className="agent-image">
                                            <img src={media_url + agent.image} alt="" />
                                        </div>
                                        <div className="agent-description">
                                            <h6>{agent.name}</h6>
                                            <p>
                                                {agent.city},{agent.state}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        ) : (
                            <p className="text-center">No agents</p>
                        )}
                        <Col md={12}>
                            <h6 className="mb-3">Agent</h6>
                        </Col>
                        {agentList["agents"]?.length > 0 ? (
                            agentList["agents"].map((agent, index) => (
                                <Col className="mb-3"
                                    style={{ cursor: "pointer" }}
                                    key={index}
                                    md={6}
                                    onClick={() => {
                                        globalData.token
                                            ? navigate(`/agent_profile?id=${agent.id}`)
                                            : navigate("/login?path=" + window.location.pathname);
                                    }}
                                >
                                    <div className="agent-info">
                                        <div className="agent-image">
                                            <img src={media_url + agent.image} alt="" />
                                        </div>
                                        <div className="agent-description">
                                            <h6>{agent.name}</h6>
                                            <p>{agent.address}</p>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        ) : (
                            <p className="text-center">No Mortgage company</p>
                        )}
                    </>}


                    {activeTab == "mortage" && <Col md={12}>
                        <h6 className="mb-3">Mortgage</h6>
                    </Col>}
                    {activeTab == "mortage" && <>
                        {mortageList.length > 0
                            ? mortageList?.map((motage, key) => {
                                return (
                                    <Col md={6}>
                                        <div className="agent-info mb-4" onClick={() => {
                                            // navigate(`/mortgage_company?id=${motage?.id}`)
                                            globalData.token
                                            ? navigate(`/mortgage_company?id=${motage?.id}`)
                                            : navigate("/login?path=" + window.location.pathname);

                                        }}

                                       
                                        >
                                            <div className="agent-image">
                                                <img src={media_url + motage.image} alt="" />
                                            </div>
                                            <div className="agent-description">
                                                <h6>{motage?.name}</h6>
                                                <p> Company name:- {motage?.company_name}</p>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                            :
                            <>
                                <p className="text-center">No Mortgage </p>
                            </>
                        } </>}




                    {activeTab == "attorney" && <Col md={12}>
                        <h6 className="mb-3">Attorneys</h6>
                    </Col>}


                    {activeTab == "attorney" && <>
                        {atterneyList.length > 0 ? atterneyList?.map((atterney, key) => {
                            return (
                                <Col md={6}>
                                    <div className="agent-info" onClick={() => {
                                        // navigate(`/attorney?id=${atterney?.id}`)
                                        globalData.token
                                        ? navigate(`/attorney?id=${atterney?.id}`)
                                        : navigate("/login?path=" + window.location.pathname);
                                    }}>
                                        <div className="agent-image">
                                            <img src={media_url + atterney.image} alt="" />
                                        </div>
                                        <div className="agent-description">
                                            <h6>{atterney?.name}</h6>
                                            <p>Company name:- {atterney?.company_name}</p>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                            :
                            <>
                                <p className="text-center">No Attorneys </p>
                            </>
                        }
                    </>}




                </Row>
            </Container>
        </>
    );
};
export default Agent_listing;
