import { useContext, useEffect, useState } from "react";
import { web_url } from "../Service/Service";
import MyMap from "./GoogleMap";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useBootstrapMinBreakpoint } from "react-bootstrap/esm/ThemeProvider";
import { context } from "../GlobalContext/GlobalContext";
import { useNavigate } from "react-router-dom";
import markerimg from '../assets/images/circle.png'

const MyMarker = (options) => {
  let globalData = useContext(context)
  let navigate = useNavigate()
  

  var markers = []


 

  useEffect(() => {
    console.log(options.markerData);
    const locations = options.markerData
    let map = options.map
    // var temp = []
    markers = locations?.map((data, i) => {
      const markerele = new window.google.maps.Marker({
        position: data.loc,
        title: data.title,
        
        icon: markerimg
      });
      options.map.panTo(markerele.getPosition())
      options.map.setZoom(18)
      markerele.addListener("click", () => {
        if (globalData.token) {
          window.open(`${window.location.origin}/property_regular?id=${data.id}`, '_blank')
        }
        else {
          navigate('/signup')
        }
      });

      return markerele
    });







    //   render: ({ count, position }) =>
    //     new window.google.maps.Marker({
    //       label: { text: String(count), color: "white", fontSize: "15px" ,fontWeight:"bold"},
    //       icon:{ path: window.google.maps.SymbolPath.CIRCLE, fillColor: "red", fillOpacity: 0.0, strokeColor: "red", strokeWeight: 20, scale: 2 },
    //       position,
    //       // adjust zIndex to be above other markers
    //       zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
    //     }),
    // };
    if (markers.length === locations.length) {
      
      var markerCluster = new MarkerClusterer({ markers, map });
      
    }

    return () => {
      if (markerCluster && markers) {
        console.log(markers)
        markers = []
        markerCluster.clearMarkers()
        

      }
    }
  }, [options]);



};
export default MyMarker;
