import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import Autocomplete from "react-google-autocomplete";
import "react-multi-carousel/lib/styles.css";
import { context } from "../GlobalContext/GlobalContext";
import { useForm } from "react-hook-form";
import { ContactUs, media_url, RecentProperties } from "../Service/Service";
import { toast } from "react-toastify";

const Home = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [recentProperty, setRecentProperty] = useState([])
  const globalData = useContext(context);

  const navigate = useNavigate();

  const [data, setData] = useState({
    address: "",
    latitude: globalData.currentLocation.lat,
    longitude: globalData.currentLocation.lng,
  });
  const onSubmit = (data) => {
    ContactUs(globalData.token, data)
      .then((response) => {
        if (response.status === 200) {
          // handleShow()
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.error(err.response.data.message);
        }
        if (err.response.status === 401) {
          globalData.setToken("");
          localStorage.clear();
          toast.info("You session has expired please login again!.");
          navigate("/");
        }
      });
  };

  const homeSearch = () => {
    navigate(
      `/properties?&type=${(globalData.userType === "customer" || globalData.userType === '') ? "sale" : "my_property"
      }&lat=${data.latitude}&long=${data.longitude}&page=1`,
      { state: data }
    );
  };

  const recentProperties = () => {
    RecentProperties().then(res => {
      if (res.status === 200) {
        setRecentProperty(res.data.data)
      }
    })
  }
  const BookProperty = (e, id, type) => {
    if (globalData.userType === '') {
      navigate('/signup')
    }
    else {
      navigate(`/property_regular?id=${id}`, { state: type })
    }
  }
  const exploreAllProperties = () => {
    if (globalData.userType === "agent" || globalData.userType === "seller") {
      navigate(`/properties?type=my_property&myProp=sale&lat=${globalData.currentLocation.lat}&long=${globalData.currentLocation.lng}&&page=1`);
    }
    else {
      navigate(`/properties?type=sale&lat=${globalData.currentLocation.lat}&long=${globalData.currentLocation.lng}&page=1`);
    }

  }
  useEffect(() => {
    recentProperties()
  }, [])
  return (
    <>
      <section className="home-banner">
        <Container>
          <Row>
            <Col md={6}>
              <h5>The Best Properties Place </h5>
              <h1>
                The Perfect <br />
                Choice for your <br />
                Future
              </h1>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing
                <br /> and typesetting industry.
              </p> */}

              <InputGroup className="mb-3 search-bt-box">
                <InputGroup.Text>
                  <img
                    src={require("../assets/images/vc-home.png")}
                    alt="img"
                  ></img>
                </InputGroup.Text>
                {/* <Form.Control placeholder="Search for location" /> */}
                <Autocomplete
                  apiKey="AIzaSyCTg77HJ0mIEmvnMn_lOHbd_x8QEk9zD0g"
                  className="form-control"
                  options={{ types: ["geocode", "establishment"] }}
                  placeholder="Search for location"
                  onPlaceSelected={(place) => {
                    setData({
                      ...data,
                      address: place.formatted_address,
                      latitude: place.geometry.location.lat(),
                      longitude: place.geometry.location.lng(),
                    });
                  }}
                  value={data.address}
                  onChange={(e) =>
                    setData({
                      ...data,
                      address: e.target.value,
                      latitude: "",
                      longitude: "",
                    })
                  }
                />
                <InputGroup.Text
                  className="search-bt-home"
                  onClick={() => homeSearch()}
                >
                  Search Now!
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col md={6}>
              <div className="homehero-img">
                <img
                  src={require("../assets/images/home_img.png")}
                  alt="img"
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="about-section">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="mobile-img">
                <img
                  src={require("../assets/images/left2.png")}
                  alt="img"
                  className="left-vector"
                  style={{ zIndex: "-1" }}
                ></img>
                <img
                  src={require("../assets/images/laptop.png")}
                  alt="img"
                ></img>
              </div>
            </Col>
            <Col md={6}>
              <div className="app-store-box-left-side">
                <p className="property-banner-heading text-black">
                  <img
                    src={require("../assets/images/play.png")}
                    alt="img"
                  ></img>
                  About us
                </p>
                <h2>
                  Smart Living Style <br />
                  for Smart People
                </h2>
                <p>
                  REALZON is real estate platform that helps buyers and sellers
                  find both auction and MLS listings in the New York City area.
                </p>
                <p>
                  Find Foreclosure and Bank-owned properties at below market
                  value as well as bid on online auctions.
                </p>
                <p>
                  Remote bid; Register and bid on bank owned properties from
                  your device.
                </p>
                <p>
                  Map Search between MLS “regular market valued houses and
                  auction houses allows you easily to see comparable assets for
                  your investment decision.
                </p>
                <p>
                  In person Auction ; Register and get all auction dates, court
                  Location, Type of foreclosure, lien amount and all details in
                  all 5 boroughs.
                </p>
                <Link to='/about' className="blue-text" n>
                  Read More <i className="fa fa-play" aria-hidden="true"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="home-property">
        <Container>
          <p className="property-banner-heading">
            <img src={require("../assets/images/play.png")} alt="img"></img>
            our properties
          </p>
          <h2>
            Our Popular Properties{" "}
            <span className="blue-text white-text" onClick={exploreAllProperties}>
              Explore all <i className="fa fa-play" aria-hidden="true"></i>
            </span>
          </h2>

          <Carousel responsive={responsive} className="properties-slider">
            {recentProperty.length > 0 && recentProperty.map((property) =>
              <div className="favorite-proprty-one" onClick={(e)=>BookProperty(e, property.id, property.property_type)}>
                <div className="favorite-property-image">
                  <img src={media_url + property.images[0].image} alt="img"></img>
                </div>
                <h4>{property.name}</h4>
                <p>
                  {property.description}
                </p>
                <h5>
                  <small>from </small>${Number(property.list_price).toLocaleString('en-US')}
                </h5>
                <div className="service-property">
                  <div className="service-one">
                    <img
                      src={require("../assets/images/bed1.png")}
                      alt="img"
                    ></img>
                    <div>
                      <p>{property.number_of_bedrooms}</p>
                    </div>
                  </div>
                  <div className="service-one">
                    <img
                      src={require("../assets/images/bath1.png")}
                      alt="img"
                    ></img>
                    <div>
                      <p>{property.number_of_half_bathrooms + property.number_of_full_bathrooms}</p>
                    </div>
                  </div>
                  <div className="service-one">
                    <img
                      src={require("../assets/images/balcony1.png")}
                      alt="img"
                    ></img>
                    <div>
                      <p>{property.number_of_balcony}</p>
                    </div>
                  </div>
                  <div className="service-one">
                    <img
                      src={require("../assets/images/food1.png")}
                      alt="img"
                    ></img>
                    <div>
                      <p>{property.number_of_kitchen}</p>
                    </div>
                  </div>
                </div>
                <p className="blue-text mt-3" onClick={(e) => BookProperty(e, property.id, property.property_type)}>
                View Details <i className="fa fa-play" aria-hidden="true"></i>
                </p>
              </div>
            )}


          </Carousel>
        </Container>
      </section>

      <section className="all-feattures-area">
        <img
          src={require("../assets/images/left2.png")}
          alt="img"
          className="left-vector left-vector-copy"
          style={{ zIndex: "-1" }}
        ></img>
        <img
          src={require("../assets/images/left2.png")}
          alt="img"
          className="right-vector right-vector-copy"
          style={{ zIndex: "-1" }}
        ></img>
        <p className="property-banner-heading text-black text-center">
          <img src={require("../assets/images/play.png")} alt="img"></img>
          features
        </p>
        <h2 className="text-center">We have Variety of Features</h2>

        <Container>
          <Row>
            <Col lg={3} md={6} sm={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/features-subscription.png")}
                  alt="img"
                ></img>
                <h5>Subscription plan</h5>
                <p>
                  No more Trips to courthouses, newspapers, etc with our online
                  foreclosure listings. Filter and search for distressed properties that
                  are in the late stages of a repossession process.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/subscription.png")}
                  alt="img"
                ></img>
                <h5> Remote bid</h5>
                <p>
                  Register and bid on bank owned properties from your device.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/property.png")}
                  alt="img"
                ></img>
                <h5>In person Auction</h5>
                <p>
                  Register and get all auction dates, court Location, Type of foreclosure, lien amount and all details in all 5 boroughs.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/price.png")}
                  alt="img"
                ></img>
                <h5>Quick Access</h5>
                <p>
                  Access your favorite or saved properties from any device when you log in to your profile.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="bottom-banner">
        <Container>
          <div className="banner-botton-content">
            <p className="property-banner-heading">
              <img src={require("../assets/images/play.png")} alt="img"></img>
              let’s take a tour
            </p>
            <h2>
              Property Smarter,<br></br> Quicker & Anywhere
            </h2>
            <Link to="">
              Join now <i className="fa fa-play" aria-hidden="true"></i>
            </Link>
          </div>
        </Container>
      </div>
      <section className="about-table-section">
        <Container>
          <div className="capalites-left-side app-area-heading">
            <h2>Competitive Landscape</h2>
          </div>
          <Table borderless className="table-section">
            <thead style={{ borderBottom: "1px solid #13497b" }}>
              <tr>
                <th></th>
                <th>
                  <img src={require("../assets/images/logo-1.png")} alt="img" />
                </th>
                <th>
                  {" "}
                  <img src={require("../assets/images/logo-2.png")} alt="img" />
                </th>
                <th>
                  {" "}
                  <img src={require("../assets/images/logo-3.png")} alt="img" />
                </th>
                <th>
                  <img src={require("../assets/images/logo-4.png")} alt="img" />
                </th>
                <th>
                  <img src={require("../assets/images/logo-5.png")} alt="img" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-spacing">Home for sales & rent</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
              </tr>
              <tr>
                <td className="text-spacing">E-Z Seacrh</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
              </tr>
              <tr>
                <td className="text-spacing">Mobile App</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
              </tr>
              <tr>
                <td className="text-spacing">Online Broker Agent</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
              </tr>
              <tr>
                <td className="text-spacing">Online Real Estate Auction</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
              </tr>
              <tr>
                <td className="text-spacing">Map Search</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
              </tr>
              <tr>
                <td className="text-spacing">Customers Profiles</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
              </tr>
              <tr>
                <td className="text-spacing">Auction/Foreclosure Listings</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </section>
      <section className="app-store-box">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="app-store-box-left-side">
                <p className="property-banner-heading text-black">
                  <img
                    src={require("../assets/images/play.png")}
                    alt="img"
                  ></img>
                  Available on
                </p>
                <h2>
                  We are also available
                  <br /> on App Stores.
                </h2>
                <p>
                  Mobile app gives you access to easily search, bid and win
                  properties on the go. Find houses by location, MLS access,
                  Court houses auction dates and location, property details and
                  many more.
                </p>
                <Button type="button" variant="unser" onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.app.realzon ')}>
                  <img
                    src={require("../assets/images/googleplay.png")}
                    alt="img"
                  ></img>
                </Button>
                <Button type="button" variant="unser" onClick={()=>window.open('https://apps.apple.com/us/app/realzon/id1636682391')}>
                  <img
                    src={require("../assets/images/appstore.svg").default}
                    alt="img"
                  ></img>
                </Button>
              </div>
            </Col>
            <Col md={6}>
              <div className="mobile-img">
                <img
                  src={require("../assets/images/mobile2.png")}
                  alt="img"
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="contact-form">
        <Container>
          {/* <Row className="align-items-center">
            <Col lg={6} md={6} className="pe-0">
              <div className="form-side">
                <p className="property-banner-heading">
                  <img src={require("../assets/images/play.png")}></img>contact
                  us
                </p>
                <h2>Stay in Touch</h2>
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>First name</Form.Label>
                        <Form.Control type="text" placeholder="First name" />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control type="text" placeholder="Last name" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Email address" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                  <Button
                    type="button"
                    variant="unset"
                    className="contact-submit"
                  >
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
            <Col lg={6} md={6} className="ps-0">
              <div className="contact-image">
                <img
                  src={require("../assets/images/contact.png")}
                  alt="image"
                ></img>
              </div>
            </Col>
          </Row> */}
          <Row className="align-items-center">
            <Col md={6} className="pe-md-0 pe-sm-1">
              <div className="form-side">
                <p className="property-banner-heading">
                  <img
                    src={require("../assets/images/play.png")}
                    alt="img"
                  ></img>
                  contact us
                </p>
                <h2>Stay in Touch</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          maxLength={15}
                          {...register("name", {
                            required: "Please enter your name.",
                          })}
                        />
                        {errors.name && (
                          <span style={{ color: "red" }}>
                            {errors.name.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Email address"
                          {...register("email", {
                            required: "Email is required.",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/,
                              message: "Invalid email.",
                            },
                            setValueAs: (v) => v.toLowerCase(),
                          })}
                        />
                        {errors.email && (
                          <span style={{ color: "red" }}>
                            {errors.email.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Contact number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Contact number"
                      maxLength={12}
                      {...register("contact_number", {
                        required: "Contact is required.",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Kindly enter numbers.",
                        },
                      })}
                    />
                    {errors.contact_number && (
                      <span style={{ color: "red" }}>
                        {errors.contact_number.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      maxLength={300}
                      {...register("message", {
                        required: "Message is required.",
                      })}
                    />
                    {errors.message && (
                      <span style={{ color: "red" }}>
                        {errors.message.message}
                      </span>
                    )}
                  </Form.Group>
                  <button className="contact-submit">Submit</button>
                </form>
              </div>
            </Col>
            <Col md={6} className="ps-0">
              <div className="contact-image">
                <img
                  src={require("../assets/images/contact.png")}
                  alt="img"
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <div
          className="seller-bottom-box text-center"

        >
          <h3>Begin your Selling Journey on Realzon</h3>
          <Button type="button" className="next-step" variant="unset">
            become a seller
          </Button>
          <img
            src={require("../assets/images/right.png")}
            className="right-vector"
            alt="img"
          ></img>
          <img
            src={require("../assets/images/left.png")}
            className="left-vector"
            alt="img"
          ></img>
        </div>
      </Container>
    </>
  );
};

export default Home;
