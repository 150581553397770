import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const Features = () => {
  return (
    <>
      <section className="hero-banner  about-bg">
        <Container>
          <p className="property-banner-heading">
            <img src={require("../assets/images/play.png")}></img>features
          </p>
          <h2>Variety of features</h2>
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing
            <br /> and typesetting industry.{" "}
          </p> */}
        </Container>
      </section>
      <section className="all-feattures-area">
        <Container>
          <Row>
          <Col lg={4} md={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/features-subscription.png")}
                  alt="img"
                ></img>
                <h5> Subscription plan</h5>
                <p>
                No more Trips to courthouses, newspapers, etc with our online foreclosure listings. Filter and search for distressed properties that are in the late stages of a repossession process.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/subscription.png")}
                  alt="img"
                ></img>
                <h5> Remote bid</h5>
                <p>
                  Register and bid on bank owned properties from your device.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/review.png")}
                  alt="img"
                ></img>
                <h5> Map Search between MLS:</h5>
                <p>
                  Regular market valued houses and auction houses allow you
                  easily to see comparable assets for your investment decision.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/property.png")}
                  alt="img"
                ></img>
                <h5>In person Auction</h5>
                <p>
                  Register and get all auction dates, court Location, Type of
                  foreclosure, lien amount and all details in all 5 boroughs.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/price.png")}
                  alt="img"
                ></img>
                <h5>Quick Access</h5>
                <p>
                  Access your favorite or saved properties from any device when
                  you log in to your profile.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/detailed-view.png")}
                  alt="img"
                ></img>
                <h5>Detailed View</h5>
                <p>
                  Review property details, photos, map location on property
                  detail page.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/notification.png")}
                  alt="img"
                ></img>
                <h5>Push notifications</h5>
                <p>
                  Receive notifications for all types of auction status or news
                  for your favorite properties.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/professional.png")}
                  alt="img"
                ></img>
                <h5>Professionals</h5>
                <p>Reach Brokers and Agents for more information or help.</p>
              </div>
            </Col>
            <Col lg={4} md={6} className="mb-3">
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/exclusive.png")}
                  alt="img"
                ></img>
                <h5>Exclusive</h5>
                <p>Access exclusive MLS properties in all 5 boroughs.</p>
              </div>
            </Col>
            <Col lg={4} md={6} style= {{margin: "0 auto"}}>
              <div className="feature-one text-center">
                <img
                  src={require("../assets/images/easy-selling.png")}
                  alt="img"
                ></img>
                <h5>Easy Selling</h5>
                <p>Register to list your own property.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <div
          className="seller-bottom-box text-center"
          
        >
          <h3>Begin your Selling Journey on Realzon</h3>
          <Button type="button" className="next-step" variant="unset">
            become a seller
          </Button>
          <img
            src={require("../assets/images/right.png")}
            className="right-vector"
          ></img>
          <img
            src={require("../assets/images/left.png")}
            className="left-vector"
          ></img>
        </div>
      </Container>
    </>
  );
};
export default Features;
