import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import dummy from "../../assets/images/dummy.png"
import Autocomplete from "react-google-autocomplete";
import { AddProperty } from "../../Service/Service";
import { useNavigate } from "react-router-dom";
import { context } from "../../GlobalContext/GlobalContext";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from "moment";


const Add_property = () => {
    let navigate = useNavigate()
    let globalData = useContext(context)
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        if (globalData.userType === "agent" || globalData.userType === "seller") {
            navigate(`/properties?type=my_property&myProp=sale&page=1`);
        }
        else {
            navigate(`/properties?type=sale&page=1`);
        }

    }
    const handleShow = () => setShow(true);
    const [type, setType] = useState('sale')
    const [AucImgErr, setAucImgErr] = useState('')
    const [saleImgErr, setSaleImgErr] = useState('')
    const [auctionImgUrl, setAuctionImgUrl] = useState([])
    const [saleImgUrl, setSaleImgUrl] = useState([])
    const [images, setImages] = useState([])
    const [saleimages, setSaleImages] = useState([])
    const { register, handleSubmit, watch, formState: { errors }, setValue, control, getValues, setError } = useForm();
    const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, setValue: setValue1, control: control1, watch: watch1 } = useForm();

    //ONLINE AUCTION PROPERTY SUBMIT
    const onSubmit = data => {
        console.log(data.startTime)
        if (moment(data.startDate).format("DD MMM YYYY") === moment(new Date()).format("DD MMM YYYY") && data.startTime < new Date()) {
            setError("startTime", { type: "focus", message: "Time should be greater than current time." }, { shouldFocus: true })
            return false;
        }
        if (moment(data.startDate).format("DD MMM ,YYYY") === moment(data.endDate).format("DD MMM ,YYYY") && data.endTime <= data.startTime) {
            // alert('')
            setError("endTime", { type: "focus", message: "End time should not be equal to or less than start time." }, { shouldFocus: true })
            return false;
        }
        console.log(data);
        if (images.length <= 0) {

            document.getElementById('auc33').focus({ preventScroll: false })
            setAucImgErr('please upload images.')
            return false;
        }
        globalData.setLoader(true)
        const payload = new FormData();

        images.map((item, index) => payload.append(`images${index}`, images[index]))
        payload.append("name", data.name);
        payload.append('image_count', images.length)
        payload.append("address", data.address.formatted_address);
        payload.append("latitude", data.address.geometry.location.lat());
        payload.append("longitude", data.address.geometry.location.lng());
        payload.append("number_of_bedrooms", data.bedRooms);
        payload.append("number_of_half_bathrooms", data.fullBath);
        payload.append("number_of_full_bathrooms", data.halfBath);
        payload.append("description", data.description);
        payload.append("listing_id", data.propertyId)
        payload.append("sf_area", data.sqft);
        payload.append("sf_dimensions", data.dimension);
        payload.append("built_year", data.yearBuilt);
        payload.append('lot_dimensions', data.lotSize)
        payload.append("sale_type", data.saleType);
        payload.append("occupied", data.occupied);
        payload.append("country", data.country);
        payload.append("mls_id", data.mlsId);
        payload.append("bid_deposit", data.bidRegister);
        payload.append("buyers_premium", data.buyerPremium);
        payload.append("earnest_money_deposit", data.earnestMoney);
        payload.append("cash_only_purchase", data.cashOnlyPurchase);
        payload.append("finance_available", data.financingAvailable);
        payload.append("start_date", moment(data.startDate).format("YYYY-MM-DD"))
        payload.append("start_time", moment(data.startTime).format("HH:mm"))
        payload.append("end_date", moment(data.endDate).format("YYYY-MM-DD"))
        payload.append("end_time", moment(data.endTime).format("HH:mm"))
        payload.append('possession_date', moment(data.possesionDate).format("YYYY-MM-DD"))
        payload.append("number_of_kitchen", data.kitchenNumber);
        payload.append("number_of_balcony", data.balconyNumber);
        payload.append("property_type", "auction")

        AddProperty(payload, globalData.token).then(response => {
            if (response.status === 200) {
                globalData.setLoader(false)
                handleShow()
            }
        }).catch(err => {
            globalData.setLoader(false)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                globalData.setToken('')
                localStorage.clear()
                toast.info("You session has expired please login again!.")
                navigate('/login')
            }
        })
    }

    // SALE PROPERTY SUBMIT
    const onSaleSubmit = data => {
        console.log(data);
        if (saleimages.length <= 0) {
            document.getElementById('sale22').focus({ preventScroll: false })
            setSaleImgErr('please upload images.')
            return false
        }
        globalData.setLoader(true)
        let payload = new FormData()
        payload.append("image_count", saleimages.length);
        saleimages.map((item, index) => payload.append(`images${index}`, item));
        payload.append("name", data.name);
        payload.append("address", data.saleAddress.formatted_address);
        payload.append("latitude", data.saleAddress.geometry.location.lat());
        payload.append("longitude", data.saleAddress.geometry.location.lng());
        payload.append("number_of_bedrooms", data.saleBedroom);
        payload.append("number_of_half_bathrooms", data.saleHalfBath);
        payload.append("number_of_full_bathrooms", data.saleFullBath);
        payload.append("description", data.description);
        payload.append("total_sqft_area", data.saleSqft);
        payload.append("dimensions", data.saleDimension);
        payload.append("total_lot_sqft_area", data.lotSqft);
        payload.append("lot_dimensions", data.lotDimension);
        payload.append("built_year", data.yearBuilt);
        payload.append("parcel_id", data.parcelId);
        payload.append("list_price", data.saleListPrice);
        payload.append("zoning", data.saleZoning);
        payload.append("cofo", data.cofo);
        payload.append("style", data.saleStyle);
        payload.append("taxes", data.saleTax);
        payload.append("condition", data.saleCondition);
        payload.append("total_rooms_in_main_unit", data.totalRoomMainUnit);
        payload.append("garage", data.garage);
        payload.append("water_view", data.waterView);
        payload.append("heating", data.saleHeating);
        payload.append("cooling", data.saleCooling);
        payload.append("ac", data.ac);
        payload.append("heat_source", data.heatSource);
        payload.append("heat_system", data.heatSystem);
        payload.append("basement", data.basement);
        if(data.basement==='true'){
            payload.append("basement_type", data.basement_type);

        }
        payload.append("sewer", data.sewer);
        payload.append("electricity", data.electric);
        payload.append("kitchen", data.kitchen);
        payload.append("living_room", data.livingRoom);
        payload.append("main_bath", data.mainBath);
        payload.append("master_bedroom", data.masterBedRoom);
        payload.append("exterior", data.exterior);
        payload.append("yard", data.yard);
        payload.append("pool", data.pool);
        payload.append('possession_date', moment(data.possesionDate).format("YYYY-MM-DD"))
        payload.append("number_of_kitchen", data.kitchenNumber);
        payload.append("number_of_balcony", data.balconyNumber);
        payload.append("property_type", "sale")

        AddProperty(payload, globalData.token).then(response => {
            if (response.status === 200) {
                globalData.setLoader(false)
                handleShow()
            }
        }).catch(err => {
            globalData.setLoader(false)
            toast.error(err.response.data.message)
        })

    }

    const handleImageUrl = (e) => {
        if (e.target.files.length > 0) {
            var temp1 = [...images]
            var temp = [...auctionImgUrl]
            for (var i = 0; i < e.target.files.length; i++) {

                temp1.push(e.target.files[i])

                setImages(temp1)

                temp.push(URL.createObjectURL(e.target.files[i]))

                setAuctionImgUrl(temp)
            }

        }

    }

    const handleSaleImageUrl = (e) => {
        console.log(e.target.files[0])
        if (e.target.files.length > 0) {
            var temp1 = [...saleimages]
            var temp = [...saleImgUrl]
            for (var i = 0; i < e.target.files.length; i++) {

                temp1.push(e.target.files[i])

                setSaleImages(temp1)

                temp.push(URL.createObjectURL(e.target.files[i]))

                setSaleImgUrl(temp)
            }
            console.log(temp1);
            console.log(temp);
        }
    }

    const DeleteSaleImages = (e, index) => {
        var temp = [...saleimages]
        var temp1 = [...saleImgUrl]
        temp.splice(index, 1)
        temp1.splice(index, 1)
        setSaleImages(temp)
        setSaleImgUrl(temp1)
    }

    const DeleteImages = (e, index) => {
        var temp = [...images]
        var temp1 = [...auctionImgUrl]
        temp.splice(index, 1)
        temp1.splice(index, 1)
        setImages(temp)
        setAuctionImgUrl(temp1)
    }



    useEffect(() => {
        // globalData.setLoader(true)
        console.log('add')
    

          if(globalData.token || localStorage.getItem("token")){
            if((globalData.userType == "customer" || localStorage.getItem("userType") == "customer")){
              window.location.href ="/";
            }
          }else{
            window.location.href ="/login";
          }    
    }, [])

    return (
        <>
            <section className="login-area forgot-area add-listing-page add_property">
                <div className="container">
                    <div className="login-box-area">
                        <div className="row justify-content-center">
                            <aside className="col-md-11">
                                <div className="login-box">
                                    <div className="login-form">
                                        <h2 className="text-center">Add your {globalData.userType === "seller" ? 'property' : 'listing'}</h2>
                                        <Tabs
                                            defaultActiveKey="sale"
                                            transition={false}
                                            id="listing-tab"
                                            value={type}
                                            className="mb-3 listing-tab-top"
                                            onSelect={(key) => { setType(key); }}
                                        >
                                            <Tab eventKey="sale" title="FEATURED" >
                                                <form id="sale" onSubmit={handleSubmit1(onSaleSubmit)}>
                                                    <Row>
                                                        <Col md={12}>
                                                            {saleImgUrl.length<4 &&
                                                            <div className="my-3 text-center">
                                                                <div className="upload-img">
                                                                    <Form.Control id='sale22' type="file"  onChange={(e) => handleSaleImageUrl(e)} onClick={(e) => e.target.value = ''} />

                                                                    <img
                                                                        id="sale22"
                                                                        src={dummy}
                                                                        className="upload-img"

                                                                    ></img>
                                                                    <p>Upload {globalData.userType === "seller" ? 'property' : 'listing'} images/banners here</p>
                                                                </div>
                                                                {saleImgErr && <span style={{ color: "red" }}>{saleImgErr}</span>}
                                                            </div>}
                                                            <div className="uploaded-pictures">

                                                                {saleImgUrl.map((img, index) =>
                                                                    <div className="picture-one-new" style={{ position: "relative" }}>
                                                                        <img
                                                                            src={img}
                                                                            className="upload-img"

                                                                        ></img>
                                                                        <i class="fa fa-times" aria-hidden="true" onClick={(e) => DeleteSaleImages(e, index)}></i>
                                                                    </div>
                                                                )

                                                                }

                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Property Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    maxLength={30}
                                                                    {...register1("name", { required: "Name is required.", })}
                                                                    placeholder="Enter Name"
                                                                />
                                                                {errors1.name && <span style={{ color: "red" }}>{errors1.name.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <Controller

                                                                render={({ field: { onChange: onChange1, name, ref }, fieldState: { error: error1 } }) => (
                                                                    <div className="my-3">
                                                                        <label className="form-label">
                                                                            Address
                                                                        </label>
                                                                        <Autocomplete apiKey="AIzaSyCTg77HJ0mIEmvnMn_lOHbd_x8QEk9zD0g" className="form-control" options={{

                                                                            types: ["geocode", "establishment"],
                                                                        }} placeholder="eg. 857 Pineknoll St. Fort Lauderdale, FL 33308" onChange={() => setValue1('saleAddress', '')} onPlaceSelected={(place) => onChange1(place)} />

                                                                        {error1?.message && <span style={{ color: "red" }}>{error1.message}</span>}
                                                                    </div>
                                                                )}
                                                                control={control1}
                                                                name="saleAddress"
                                                                rules={{ required: "Please select address." }}
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Parcel ID
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={30}
                                                                    id=""
                                                                    {...register1('parcelId', { required: "Parcel id is required.", pattern: { value: /^[0-9-]*$/, message: "Only numeric characters are allowed." } })}
                                                                    placeholder="Enter parcel id"
                                                                />
                                                                {errors1.parcelId && <span style={{ color: "red" }}>{errors1.parcelId.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    List Price
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={10}
                                                                    id=""
                                                                    placeholder="Enter list price"
                                                                    {...register1("saleListPrice", { required: "List price is required.", pattern: { value: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/, message: "Only numeric characters are allowed." } })}
                                                                />
                                                                {errors1.saleListPrice && <span style={{ color: "red" }}>{errors1.saleListPrice.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            {/* // <div className="my-3"> */}
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Zoning
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={30}
                                                                    id=""
                                                                    {...register1('saleZoning', { required: "Zoning is required." })}
                                                                    placeholder="Enter zoning"
                                                                />
                                                                {errors1.saleZoning && <span style={{ color: "red" }}>{errors1.saleZoning.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Style
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    maxLength={30}
                                                                    placeholder="Enter style"
                                                                    {...register1("saleStyle", { required: 'Style is required.' , pattern: { value: /^[A-Za-z ]*$/, message: "Only alphabets are allowed." }})}
                                                                />
                                                                {errors1.saleStyle && <span style={{ color: "red" }}>{errors1.saleStyle.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Year built
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={4}
                                                                    id=""
                                                                    {...register1('yearBuilt', { required: "Year is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                    placeholder="Enter Year built"
                                                                />
                                                                {errors1.yearBuilt && <span style={{ color: "red" }}>{errors1.yearBuilt.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Taxes
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    placeholder="Enter taxes"
                                                                    {...register1('saleTax', { required: "Taxes is required.", pattern: { value: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/, message: "Only numeric characters are allowed." } })}
                                                                />
                                                                {errors1.saleTax && <span style={{ color: "red" }}>{errors1.saleTax.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>


                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Condition
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={30}
                                                                    id=""
                                                                    {...register1('saleCondition', { required: "Condition is required." })}
                                                                    placeholder="Enter condition"
                                                                />
                                                                {errors1.saleCondition && <span style={{ color: "red" }}>{errors1.saleCondition.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Building Sqft and Dimensions
                                                                </label>
                                                                <Row className="align-items-center ">
                                                                    <Col className="pe-0">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id=""
                                                                            maxLength={20}
                                                                            placeholder="Enter Sqft"
                                                                            {...register1('saleSqft', { required: "Sqft is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                        />
                                                                        {errors1.saleSqft && <span style={{ color: "red" }}>{errors1.saleSqft.message}</span>}
                                                                    </Col>
                                                                    <Col md={1} className="text-center x-cross">
                                                                        <span className="cross-sign">X</span>
                                                                    </Col>
                                                                    <Col className="ps-0">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id=""
                                                                            maxLength={20}
                                                                            placeholder="Enter dimension"
                                                                            {...register1('saleDimension', { required: "Dimensions are required.", pattern: { value: /^\-?[0-9]+(?:x[0-9]{1,18})?$/, message: "Only numeric characters are allowed." } })}
                                                                        />
                                                                        {errors1.saleDimension && <span style={{ color: "red" }}>{errors1.saleDimension.message}</span>}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Lot Sqft and Dimensions
                                                                </label>
                                                                <Row className="align-items-center">
                                                                    <Col className="pe-0">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            maxLength={20}
                                                                            id=""
                                                                            {...register1('lotSqft', { required: "Sqft is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                            placeholder="Enter sq"
                                                                        />
                                                                        {errors1.lotSqft && <span style={{ color: "red" }}>{errors1.lotSqft.message}</span>}
                                                                    </Col>
                                                                    <Col md={1} className="text-center x-cross">
                                                                        <span className="cross-sign">X</span>
                                                                    </Col>
                                                                    <Col className="pe-0">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            maxLength={20}
                                                                            id=""
                                                                            {...register1('lotDimension', { required: "Dimension is required.", pattern: { value: /^\-?[0-9]+(?:x[0-9]{1,18})?$/, message: "Only numeric characters are allowed." } })}
                                                                            placeholder="Enter dimension "
                                                                        />
                                                                        {errors1.lotDimension && <span style={{ color: "red" }}>{errors1.lotDimension.message}</span>}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Total rooms Main unit
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={2}
                                                                    id=""
                                                                    placeholder="Enter total rooms"
                                                                    {...register1('totalRoomMainUnit', { required: "Rooms main unit is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                />
                                                                {errors1.totalRoomMainUnit && <span style={{ color: "red" }}>{errors1.totalRoomMainUnit.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>



                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Bedrooms
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={2}
                                                                    id=""
                                                                    {...register1('saleBedroom', { required: "Bedrooms is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                    placeholder="Enter bedrooms"
                                                                />
                                                                {errors1.saleBedroom && <span style={{ color: "red" }}>{errors1.saleBedroom.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Bath
                                                                </label>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            maxLength={2}
                                                                            id=""
                                                                            placeholder="Number of full bath"
                                                                            {...register1("saleFullBath", { required: "Number of full bath is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                        />
                                                                        {errors1.saleFullBath && <span style={{ color: "red" }}>{errors1.saleFullBath.message}</span>}
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            maxLength={2}
                                                                            id=""
                                                                            placeholder="Number of half bath"
                                                                            {...register1("saleHalfBath", { required: "Number of half is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                        />
                                                                        {errors1.saleHalfBath && <span style={{ color: "red" }}>{errors1.saleHalfBath.message}</span>}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Kitchen
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={2}
                                                                    id=""
                                                                    {...register1('kitchenNumber', { required: "Kitchens is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                    placeholder="Enter number of kitchen"
                                                                />
                                                                {errors1.kitchenNumber && <span style={{ color: "red" }}>{errors1.kitchenNumber.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Balcony
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={2}
                                                                    id=""
                                                                    {...register1('balconyNumber', { required: "Balcony is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                    placeholder="Enter number of balcony"
                                                                />
                                                                {errors1.balconyNumber && <span style={{ color: "red" }}>{errors1.balconyNumber.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>


                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Heating & cooling
                                                                </label>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            maxLength={3}
                                                                            id=""
                                                                            {...register1('saleHeating', { required: "Heating is required." })}
                                                                            placeholder="Enter heating"
                                                                        />
                                                                        {errors1.saleHeating && <span style={{ color: "red" }}>{errors1.saleHeating.message}</span>}
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            maxLength={3}
                                                                            id=""
                                                                            {...register1('saleCooling', { required: "cooling is required." })}
                                                                            placeholder="Enter cooling"
                                                                        />
                                                                        {errors1.saleCooling && <span style={{ color: "red" }}>{errors1.saleCooling.message}</span>}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label>
                                                                        Possession Date :
                                                                    </label>
                                                                </div>
                                                                <Controller
                                                                    control={control1}
                                                                    name="possesionDate"
                                                                    rules={{ required: "Possesion date is required." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="col-md-8">
                                                                            <DatePicker className="form-control" selected={value} onKeyDown={(e)=>e.preventDefault()} placeholderText="MM/DD/YYYY" minDate={new Date()} onChange={(date) => onChange(date)} />
                                                                            {errors1.possesionDate && <p style={{ color: "red", textAlign: "left" }}>{errors1.possesionDate.message}</p>}
                                                                        </div>

                                                                    )}
                                                                />
                                                            </div>
                                                        </Col>


                                                        <Col md={6}>

                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Cofo
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="cofo"
                                                                    rules={{ required: "Please select one." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Yes"
                                                                                value={true}
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group1"
                                                                                type="radio"
                                                                                id="radio17"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="No"
                                                                                value={false}
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group1"
                                                                                type="radio"
                                                                                id="radio27"
                                                                            />
                                                                        </div>
                                                                    )} />
                                                                {errors1.cofo && <span style={{ color: "red" }}>{errors1.cofo.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>



                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Garage (location)
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="garage"
                                                                    rules={{ required: "Please select one." }}
                                                                    render={({ field: { onChange } }) => (
                                                                        <div className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Yes"
                                                                                value={true}
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group41"
                                                                                type="radio"
                                                                                id="radio67"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="No"
                                                                                value={false}
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group41"
                                                                                type="radio"
                                                                                id="radio66"
                                                                            />
                                                                        </div>
                                                                    )} />
                                                                {errors1.garage && <span style={{ color: "red" }}>{errors1.garage.message}</span>}


                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Water view
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="waterView"
                                                                    rules={{ required: "Please select one." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Yes"
                                                                                value={true}
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group2"
                                                                                type="radio"
                                                                                id="radio15"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="No"
                                                                                name="group2"
                                                                                value={false}
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                type="radio"
                                                                                id="radio25"
                                                                            />
                                                                        </div>)} />
                                                                {errors1.waterView && <span style={{ color: "red" }}>{errors1.waterView.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>


                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    A/C
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="ac"
                                                                    rules={{ required: "Please select one." }}
                                                                    render={({ field: { onChange } }) => (
                                                                        <div className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Central"
                                                                                value="CENTRAL"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group31"
                                                                                type="radio"
                                                                                id="radio75"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Units"
                                                                                value="UNITS"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group31"
                                                                                type="radio"
                                                                                id="radio76"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="None"
                                                                                value="NONE"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group31"
                                                                                type="radio"
                                                                                id="radio77"
                                                                            />
                                                                        </div>
                                                                    )} />
                                                                {errors1.ac && <span style={{ color: "red" }}>{errors1.ac.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Heat source
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="heatSource"
                                                                    rules={{ required: "Please select one." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Electric"
                                                                                value="ELECTRIC"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group3"
                                                                                type="radio"
                                                                                id="radio13"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Gas"
                                                                                value="GAS"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group3"
                                                                                type="radio"
                                                                                id="radio23"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Oil"
                                                                                value="OIL"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group3"
                                                                                type="radio"
                                                                                id="radio24"
                                                                            />
                                                                        </div>)} />
                                                                {errors1.heatSource && <span style={{ color: "red" }}>{errors1.heatSource.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>


                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Heat system
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="heatSystem"
                                                                    rules={{ required: "Please select one." }}
                                                                    render={({ field: { onChange } }) => (
                                                                        <div className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Forced air"
                                                                                value="FORCED_AIR"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group24"
                                                                                type="radio"
                                                                                id="radio83"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Steam"
                                                                                value="STEAM"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group24"
                                                                                type="radio"
                                                                                id="radio84"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Hot water"
                                                                                value="HOT_WATER"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group24"
                                                                                type="radio"
                                                                                id="radio85"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Other"
                                                                                value="OTHER"
                                                                                name="group24"
                                                                                type="radio"
                                                                                id="radio86"
                                                                            />
                                                                        </div>)} />
                                                                {errors1.heatSystem && <span style={{ color: "red" }}>{errors1.heatSystem.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Basement
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="basement"
                                                                    rules={{ required: "Please select one." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Yes"
                                                                                value={true}
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group4"
                                                                                type="radio"
                                                                                id="radio1"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="No"
                                                                                value={false}
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group4"
                                                                                type="radio"
                                                                                id="radio2"
                                                                            />
                                                                        </div>)} />
                                                                {errors1.basement && <span style={{ color: "red" }}>{errors1.basement.message}</span>}
                                                                {watch1("basement") == "true" &&
                                                                    <Controller
                                                                        control={control1}
                                                                        name="basement_type"
                                                                        rules={{ required: "Please select one." }}
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <div className="mb-3 three-radio-input">
                                                                                <Form.Check
                                                                                    inline
                                                                                    label="Half Finished"
                                                                                    value='HALF_FINISHED'
                                                                                    onChange={(e) => onChange(e.target.value)}
                                                                                    name="group33"
                                                                                    type="radio"
                                                                                    id="half"
                                                                                />
                                                                                <Form.Check
                                                                                    inline
                                                                                    label="Finished"
                                                                                    name="group33"
                                                                                    value='FINISHED'
                                                                                    onChange={(e) => onChange(e.target.value)}
                                                                                    type="radio"
                                                                                    id="gas"
                                                                                />
                                                                                <Form.Check
                                                                                    inline
                                                                                    label="Full"
                                                                                    name="group33"
                                                                                    value='FULL'
                                                                                    onChange={(e) => onChange(e.target.value)}
                                                                                    type="radio"
                                                                                    id="oil1"
                                                                                />
                                                                            </div>)} />}
                                                                {errors1.basement_type && <span style={{ color: "red" }}>{errors1.basement_type.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>


                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Kitchen
                                                                </label>

                                                                <div className="mb-3 main-bath-check">
                                                                    <Form.Check
                                                                        inline
                                                                        label="Eat in"
                                                                        value="eat_in"
                                                                        {...register1("kitchen", { required: 'Please select atleast one of them.' })}
                                                                        type="checkbox"
                                                                        id="check1"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Yard access"
                                                                        value="yard_access"
                                                                        {...register1("kitchen", { required: 'Please select atleast one of them.' })}
                                                                        type="checkbox"
                                                                        id="check2"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Gallery"
                                                                        value='galary'
                                                                        {...register1("kitchen", { required: 'Please select atleast one of them.' })}
                                                                        type="checkbox"
                                                                        id="check3"
                                                                    />
                                                                </div>
                                                                {errors1.kitchen && <span style={{ color: "red" }}>{errors1.kitchen.message}</span>}
                                                            </div>

                                                        </Col>
                                                        <Col md={6}>


                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Main Bath
                                                                </label>

                                                                <div className="mb-3 main-bath-check">
                                                                    <Form.Check
                                                                        inline
                                                                        label="Full bath"
                                                                        value="full_bath"
                                                                        {...register1('mainBath', { required: "please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox1"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="3 quarter"
                                                                        value="3_quarter"
                                                                        {...register1('mainBath', { required: "please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox2"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Bidet"
                                                                        value="bidet"
                                                                        {...register1('mainBath', { required: "please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox3"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Skylight"
                                                                        value="skylight"
                                                                        {...register1('mainBath', { required: "please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox4"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Jacuzzi"
                                                                        value="jacuzzi"
                                                                        {...register1('mainBath', { required: "please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox5"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Half bath"
                                                                        value="half_bath"
                                                                        {...register1('mainBath', { required: "please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox6"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Separate shower"
                                                                        value="seperate_shower"
                                                                        {...register1('mainBath', { required: "please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox7"
                                                                        className="w-100"
                                                                    />
                                                                </div>
                                                                {errors1.mainBath && <span style={{ color: "red" }}>{errors1.mainBath.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Living room
                                                                </label>

                                                                <div className="mb-3 main-bath-check">
                                                                    <Form.Check
                                                                        inline
                                                                        label="Combination"
                                                                        value="combination"
                                                                        {...register1("livingRoom", { required: 'Please select atleast one of them.' })}
                                                                        type="checkbox"
                                                                        id="check11"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Formal"
                                                                        value="formal"
                                                                        {...register1("livingRoom", { required: 'Please select atleast one of them.' })}
                                                                        type="checkbox"
                                                                        id="check12"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Sunken"
                                                                        value="sunken"
                                                                        {...register1("livingRoom", { required: 'Please select atleast one of them.' })}
                                                                        type="checkbox"
                                                                        id="check13"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Fireplace"
                                                                        value="fireplace"
                                                                        {...register1("livingRoom", { required: 'Please select atleast one of them.' })}
                                                                        type="checkbox"
                                                                        id="check14"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Separate"
                                                                        value="separate"
                                                                        {...register1("livingRoom", { required: 'Please select atleast one of them.' })}
                                                                        type="checkbox"
                                                                        id="check15"
                                                                    />
                                                                </div>
                                                                {errors1.livingRoom && <span style={{ color: "red" }}>{errors1.livingRoom.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>


                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Exterior
                                                                </label>
                                                                <div className="mb-3 main-bath-check">
                                                                    <Form.Check
                                                                        inline
                                                                        label="Balcony"
                                                                        value="balcony"
                                                                        {...register1('exterior', { required: "Please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox11"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Grill"
                                                                        value="grill"
                                                                        {...register1('exterior', { required: "Please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox21"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Deck"
                                                                        value="deck"
                                                                        {...register1('exterior', { required: "Please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox31"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Patio"
                                                                        value="patio"
                                                                        {...register1('exterior', { required: "Please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox41"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Fence"
                                                                        value='fence'
                                                                        {...register1('exterior', { required: "Please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox51"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Shed Sprinkle system"
                                                                        value="shed sprinkle system"
                                                                        {...register1('exterior', { required: "Please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox61"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Garage door opener"
                                                                        value="garage door opener"
                                                                        {...register1('exterior', { required: "Please select atleast one of them." })}
                                                                        type="checkbox"
                                                                        id="checkbox71"
                                                                        className="w-100"
                                                                    />
                                                                </div>
                                                                {errors1.exterior && <span style={{ color: "red" }}>{errors1.exterior.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>


                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Master bedroom
                                                                </label>

                                                                <div className="mb-3 main-bath-check">
                                                                    <Form.Check
                                                                        inline
                                                                        label="Dressing room"
                                                                        value="dressing_room"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check21"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Jacuzzi"
                                                                        value="jacuzzi"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check22"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Fireplace"
                                                                        value="fireplace"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check23"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Suite"
                                                                        value="suite"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check24"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Private bath"
                                                                        value="private_bath"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check25"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Walk in closet"
                                                                        value="walk_in_closet"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check26"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Shared bathroom"
                                                                        value="shared_bathroom"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check27"
                                                                        className="w-100"
                                                                    />
                                                                </div>
                                                                {errors1.masterBedRoom && <span style={{ color: "red" }}>{errors1.masterBedRoom.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Pool
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="pool"
                                                                    rules={{ required: "Please select atleast one of them." }}                                                                
                                                                    render={({ field: { onChange } }) => (
                                                                <div className="mb-3 main-bath-check">
                                                                    <Form.Check
                                                                        inline
                                                                        label="Above ground"
                                                                        value="above_ground"
                                                                        {...register1('pool', { required: "Please select atleast one of them." })}
                                                                        onChange={(e)=>{
                                                                            onChange(e.target.value)
                                                                            if(e.target.checked){
                                                                                document.getElementById('checkbox42').checked = false
                                                                            }
                                                                        }}
                                                                        type="checkbox"
                                                                        id="checkbox12"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="In ground"
                                                                        value='in_ground'
                                                                        {...register1('pool', { required: "Please select atleast one of them." })}
                                                                        onChange={(e)=>{
                                                                            onChange(e.target.value)
                                                                            if(e.target.checked){
                                                                                document.getElementById('checkbox42').checked = false
                                                                            }
                                                                        }}
                                                                        type="checkbox"
                                                                        id="checkbox22"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Indoor"
                                                                        value='indoor'
                                                                        {...register1('pool', { required: "Please select atleast one of them." })}
                                                                        onChange={(e)=>{
                                                                            onChange(e.target.value)
                                                                            if(e.target.checked){
                                                                                document.getElementById('checkbox42').checked = false
                                                                            }
                                                                        }}
                                                                        type="checkbox"
                                                                        id="checkbox32"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="None/No-Pools"
                                                                        value='none'
                                                                        {...register1('pool', { required: "Please select atleast one of them." })}
                                                                        onChange={(e) => {
                                                                            onChange(e.target.value)
                                                                            if(e.target.checked){
                                                                                document.getElementById('checkbox12').checked = false
                                                                                document.getElementById('checkbox22').checked = false
                                                                                document.getElementById('checkbox32').checked = false
                                                                            }
                                                                        }}
                                                                        type="checkbox"
                                                                        id="checkbox42"
                                                                    />
                                                                </div>)} />
                                                                {errors1.pool && <span style={{ color: "red" }}>{errors1.pool.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Yard
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="yard"
                                                                    rules={{ required: "Please select atleast one of them." }}
                                                                    render={({ field: { onChange } }) => (
                                                                <div className="mb-3 main-bath-check">
                                                                    <Form.Check
                                                                        inline
                                                                        label="Back"
                                                                        value="back"
                                                                        {...register1('yard', { required: "Please select atleast one of them." })}
                                                                        onChange={(e) => onChange(e.target.value)}
                                                                        type="checkbox"
                                                                        id="check31"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Side"
                                                                        value="side"
                                                                        {...register1('yard', { required: "Please select atleast one of them." })}
                                                                        onChange={(e) => onChange(e.target.value)}
                                                                        type="checkbox"
                                                                        id="check32"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Front"
                                                                        value="front"
                                                                        {...register1('yard', { required: "Please select atleast one of them." })}
                                                                        onChange={(e) => onChange(e.target.value)}
                                                                        type="checkbox"
                                                                        id="check33"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="None"
                                                                        value="none"
                                                                        {...register1('yard', { required: "Please select atleast one of them." })}
                                                                        onChange={(e) => onChange(e.target.value)}
                                                                        type="checkbox"
                                                                        id="check34"
                                                                    />
                                                                </div>)} />
                                                                {errors1.yard && <span style={{ color: "red" }}>{errors1.yard.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Sewer
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="sewer"
                                                                    rules={{ required: "Please select one." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="City"
                                                                                value="CITY"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group244"
                                                                                type="radio"
                                                                                id="radio53"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Cesspool"
                                                                                value="CESSPOOL"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group244"
                                                                                type="radio"
                                                                                id="radio54"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Private"
                                                                                value="PRIVATE"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group244"
                                                                                type="radio"
                                                                                id="radio55"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Peptic"
                                                                                value="PEPTIC"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group244"
                                                                                type="radio"
                                                                                id="radio56"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Storm"
                                                                                value="STORM"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group244"
                                                                                type="radio"
                                                                                id="radio57"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="None"
                                                                                value="NONE"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group244"
                                                                                type="radio"
                                                                                id="radio58"
                                                                            />
                                                                        </div>)} />
                                                                {errors1.sewer && <span style={{ color: "red" }}>{errors1.sewer.message}</span>}
                                                            </div>
                                                        </Col>

                                                        {/* <Col md={6}>

                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Master bedroom
                                                                </label>

                                                                <div className="mb-3 main-bath-check">
                                                                    <Form.Check
                                                                        inline
                                                                        label="Dressing room"
                                                                        value="dressing_room"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check21"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Jacuzzi"
                                                                        value="jacuzzi"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check22"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Fireplace"
                                                                        value="fireplace"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check23"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Suite"
                                                                        value="suite"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check24"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Private bath"
                                                                        value="private_bath"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check25"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Walk in closet"
                                                                        value="walk_in_closet"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check26"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Shared bathroom"
                                                                        value="shared_bathroom"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check27"
                                                                    />
                                                                </div>
                                                                {errors1.masterBedRoom && <span style={{ color: "red" }}>{errors1.masterBedRoom.message}</span>}
                                                            </div>
                                                        </Col> */}




{/* 
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Yard
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="yard"
                                                                    rules={{ required: "Please select atleast one of them." }}
                                                                    render={({ field: { onChange } }) => (
                                                                        <div className="mb-3 main-bath-check">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Back"
                                                                                value="back"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                type="checkbox"
                                                                                id="check31"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Side"
                                                                                value="side"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                type="checkbox"
                                                                                id="check32"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Front"
                                                                                value="front"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                type="checkbox"
                                                                                id="check33"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="None"
                                                                                value="none"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                type="checkbox"
                                                                                id="check34"
                                                                            />
                                                                        </div>)} />
                                                                {errors1.yard && <span style={{ color: "red" }}>{errors1.yard.message}</span>}
                                                            </div>
                                                        </Col> */}
                                                        <Col>
                                                            {/* <div className="my-3">
                                                                <label className="form-label"> */}

                                                            <div className="my-3">
                                                                <label className="form-label">

                                                                    Electric
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="electric"
                                                                    rules={{ required: "Please select one." }}
                                                                    render={({ field: { onChange } }) => (
                                                                        <div className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="110V"
                                                                                value='V110'
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group241"
                                                                                type="radio"
                                                                                id="radio131"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="220V"
                                                                                value='V220'
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                name="group241"
                                                                                type="radio"
                                                                                id="radio132"
                                                                            />
                                                                        </div>)} />
                                                                {errors1.electric && <span style={{ color: "red" }}>{errors1.electric.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <div className="my-3">
                                                                <Form.Group
                                                                    className="mb-3"
                                                                    controlId="exampleForm.ControlTextarea1"
                                                                >
                                                                    <Form.Label>Description</Form.Label>
                                                                    <Form.Control as="textarea" rows={3} maxLength={2000} {...register1("description", { required: 'Description is required.' })} />
                                                                </Form.Group>
                                                            </div>
                                                            {errors1.description && <span style={{ color: "red" }}>{errors1.description.message}</span>}
                                                            <button className="btn sign-btn fill-btn  text-white listing-submit">
                                                                submit
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </form>
                                            </Tab>
                                            <Tab eventKey="auction" title="online auction" >
                                                <form id="auction" onSubmit={handleSubmit(onSubmit)}>
                                                    <Row>
                                                        <Col md={12}>
                                                            {auctionImgUrl.length<4 &&
                                                            <div className="my-3 text-center">
                                                                <div className="upload-img">
                                                                    <Form.Control id="auc33" type="file"  onChange={(e) => handleImageUrl(e)} onClick={(e) => e.target.value = ''} />

                                                                    <img

                                                                        src={dummy}
                                                                        className="upload-img"

                                                                    ></img>
                                                                    <p>Upload {globalData.userType === "seller" ? 'property' : 'listing'} images/banners here</p>
                                                                </div>
                                                                {AucImgErr && <span style={{ color: "red" }}>{AucImgErr}</span>}
                                                            </div>}
                                                            <div className="uploaded-pictures">

                                                                {auctionImgUrl.map((img, index) =>
                                                                    <div className="picture-one-new" style={{ position: "relative" }}>
                                                                        <img
                                                                            src={img}
                                                                            className="upload-img"

                                                                        ></img>
                                                                        <i class="fa fa-times" aria-hidden="true" onClick={(e) => DeleteImages(e, index)}></i>
                                                                    </div>
                                                                )

                                                                }

                                                            </div>

                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Property Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    maxLength={20}
                                                                    {...register("name", { required: "Name is required.", })}
                                                                    placeholder="Enter Name"
                                                                />
                                                                {errors.name && <span style={{ color: "red" }}>{errors.name.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <Controller
                                                                render={({ field: { onChange, name, ref }, fieldState: { error } }) => (
                                                                    <div className="my-3">
                                                                        <label className="form-label">
                                                                            Address
                                                                        </label>
                                                                        <Autocomplete ref={ref} apiKey="AIzaSyCTg77HJ0mIEmvnMn_lOHbd_x8QEk9zD0g" className="form-control" placeholder="eg. 857 Pineknoll St. Fort Lauderdale, FL 33308" options={{ types: ["geocode", "establishment"] }} onChange={() => setValue('address', '')} onPlaceSelected={(place) => onChange(place)} />

                                                                        {error?.message && <span style={{ color: "red" }}>{error.message}</span>}
                                                                    </div>
                                                                )}
                                                                control={control}
                                                                name="address"
                                                                rules={{ required: "Please select address." }}
                                                            /></Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Property ID
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    placeholder="Enter property id"
                                                                    maxLength={40}
                                                                    {...register("propertyId", { required: "Property id is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                />
                                                                {errors.propertyId && <span style={{ color: "red" }}>{errors.propertyId.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>


                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Sale Type
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    maxLength={20}
                                                                    {...register("saleType", { required: "Sale type is required.", pattern: { value: /^[A-Za-z ]*$/, message: "Only alphabets are allowed." } })}
                                                                    placeholder="Enter sale type"
                                                                />
                                                                {errors.saleType && <span style={{ color: "red" }}>{errors.saleType.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Lot size
                                                                </label>
                                                                <input
                                                                    type="ttext"
                                                                    className="form-control"
                                                                    id=""
                                                                    placeholder="Enter lot size"
                                                                    {...register("lotSize", { required: "Lot size is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                />
                                                                {errors.lotSize && <span style={{ color: "red" }}>{errors.lotSize.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Country
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    placeholder="Country"
                                                                    {...register('country', { required: 'Country is required.', pattern: { value: /^[A-Za-z]*$/, message: "Only alphabets are allowed." } })}
                                                                />
                                                                {errors.country && <span style={{ color: "red" }}>{errors.country.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Year built
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    placeholder="Enter year built"
                                                                    {...register('yearBuilt', { required: "Year built is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                />
                                                                {errors.yearBuilt && <span style={{ color: "red" }}>{errors.yearBuilt.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    MLS ID
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={20}
                                                                    placeholder="Enter MLS id"
                                                                    {...register("mlsId", { required: 'MLS ID is required.', pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                />
                                                                {errors.mlsId && <span style={{ color: "red" }}>{errors.mlsId.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Bid deposit
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    placeholder="Bid deposit"
                                                                    {...register('bidRegister', { required: "Bid deposit is required.", 
                                                                        // pattern: { value: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/, message: "Only numeric characters are allowed." }
                                                                    })}
                                                                />
                                                                {errors.bidRegister && <span style={{ color: "red" }}>{errors.bidRegister.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    SF
                                                                </label>
                                                                <Row  className="align-items-center ">
                                                                    <Col className="ps-0">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id=""
                                                                            maxLength={20}
                                                                            placeholder="Enter sqft"
                                                                            {...register("sqft", { required: "Sqft is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                        />
                                                                        {errors.sqft && <span style={{ color: "red" }}>{errors.sqft.message}</span>}
                                                                    </Col>
                                                                    <Col md={1} className="text-center x-cross">
                                                                        <span className="cross-sign">X</span>
                                                                    </Col>
                                                                    <Col className="ps-0" >
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id=""
                                                                            maxLength={20}
                                                                            placeholder="Enter dimension"
                                                                            {...register("dimension", { required: "Dimension is required.", pattern: { value: /^\-?[0-9]+(?:x[0-9]{1,18})?$/, message: "Only numeric characters are allowed." } })}

                                                                        />
                                                                        {errors.dimension && <span style={{ color: "red" }}>{errors.dimension.message}</span>}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Bedrooms
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    placeholder="Bedrooms"
                                                                    {...register("bedRooms", { required: 'Bedrooms are required.', pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                />
                                                                {errors.bedRooms && <span style={{ color: "red" }}>{errors.bedRooms.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Bath
                                                                </label>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id=""
                                                                            placeholder="Number of full bath"
                                                                            {...register("fullBath", { required: "Full bath is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                        />
                                                                        {errors.fullBath && <span style={{ color: "red" }}>{errors.fullBath.message}</span>}
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id=""
                                                                            placeholder="Number of half bath"
                                                                            {...register('halfBath', { required: "Half bath  is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                        />
                                                                        {errors.halfBath && <span style={{ color: "red" }}>{errors.halfBath.message}</span>}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Kitchen
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={2}
                                                                    id=""
                                                                    {...register('kitchenNumber', { required: "Kitchens is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                    placeholder="Enter number of kitchen"
                                                                />
                                                                {errors.kitchenNumber && <span style={{ color: "red" }}>{errors.kitchenNumber.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Balcony
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={2}
                                                                    id=""
                                                                    {...register('balconyNumber', { required: "Balcony is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })}
                                                                    placeholder="Enter number of balcony"
                                                                />
                                                                {errors.balconyNumber && <span style={{ color: "red" }}>{errors.balconyNumber.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label>
                                                                        Possession Date :
                                                                    </label>
                                                                </div>
                                                                <Controller
                                                                    control={control}
                                                                    name="possesionDate"
                                                                    rules={{ required: "Possesion date is required." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="col-md-8">
                                                                            <DatePicker className="form-control" selected={value} minDate={new Date()} placeholderText="MM/DD/YYYY" onKeyDown={(e)=>e.preventDefault()} onChange={(date) => onChange(date)} />
                                                                            {errors.possesionDate && <p style={{ color: "red", textAlign: "left" }}>{errors.possesionDate.message}</p>}
                                                                        </div>

                                                                    )}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label>
                                                                      Auction Start Date & Time:
                                                                    </label>
                                                                </div>
                                                                <Controller
                                                                    control={control}
                                                                    name="startDate"
                                                                    rules={{ required: "Start date is required." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="col-md-8">
                                                                            <DatePicker className="form-control" selected={value} placeholderText="MM/DD/YYYY" onKeyDown={(e)=>e.preventDefault()} minDate={new Date()} onChange={(date) => onChange(date)} />
                                                                            {errors.startDate && <p style={{ color: "red", textAlign: "left" }}>{errors.startDate.message}</p>}
                                                                        </div>

                                                                    )}
                                                                />

                                                                <Controller
                                                                    control={control}
                                                                    name="startTime"
                                                                    rules={{ required: "Start time is required." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="col-md-4">
                                                                            <TimePicker
                                                                                showSecond={false}
                                                                                use12Hours={true}
                                                                                className='form-control'
                                                                                onChange={(time) => onChange(time)}
                                                                                inputReadOnly
                                                                                placeholder="00:00"
                                                                                value={value}
                                                                            />
                                                                            {errors.startTime && <p style={{ color: "red" }}>{errors.startTime.message}</p>}
                                                                        </div>

                                                                    )}
                                                                />

                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label>
                                                                       Auction End Date & Time :
                                                                    </label>
                                                                </div>
                                                                <Controller
                                                                    control={control}
                                                                    name="endDate"
                                                                    rules={{ required: "End date is required.", validate: val => val >= watch("startDate") || "End date should not be less than start date." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="col-md-8">
                                                                            <DatePicker className="form-control" selected={value} placeholderText="MM/DD/YYYY" onKeyDown={(e)=>e.preventDefault()} minDate={new Date()} onChange={(date) => onChange(date)} />
                                                                            {errors.endDate && <p style={{ color: "red", textAlign: "left" }}>{errors.endDate.message}</p>}
                                                                        </div>

                                                                    )}
                                                                />
                                                                <Controller
                                                                    control={control}
                                                                    name="endTime"
                                                                    rules={{ required: "End time is required." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="col-md-4">
                                                                            <TimePicker
                                                                                showSecond={false}
                                                                                use12Hours={true}
                                                                                className='form-control'
                                                                                onChange={(time) => onChange(time)}
                                                                                inputReadOnly
                                                                                placeholder="00:00"
                                                                                value={value}
                                                                            />
                                                                            {errors.endTime && <p style={{ color: "red" }}>{errors.endTime.message}</p>}
                                                                        </div>
                                                                    )}
                                                                />


                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Occupied
                                                                </label>
                                                                <Controller
                                                                    control={control}
                                                                    name="occupied"
                                                                    rules={{ required: "Occupied is required." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="my-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Yes"
                                                                                name="group2"
                                                                                value={true}
                                                                                type="radio"
                                                                                id="radio17"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                style={{ "width": "100px" }}
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="No"
                                                                                value={false}
                                                                                name="group2"
                                                                                type="radio"
                                                                                id="radio27"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                            />
                                                                        </div>)} />
                                                                {errors.occupied && <span style={{ color: "red" }}>{errors.occupied.message}</span>}
                                                            </div>
                                                        </Col>

                                                        <Col md={6}>
                                                            <div className="my-3">

                                                                <label className="form-label">
                                                                    Cash only Purchase
                                                                </label>
                                                                <Controller
                                                                    control={control}
                                                                    name="cashOnlyPurchase"
                                                                    rules={{ required: "Cash only purchase is required." }}
                                                                    render={({ field: { onChange, value } }) => (

                                                                        <div className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Yes"
                                                                                name="group1"
                                                                                value={true}
                                                                                type="radio"
                                                                                id="radio17"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                style={{ "width": "100px" }}
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="No"
                                                                                value={false}
                                                                                name="group1"
                                                                                type="radio"
                                                                                id="radio27"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                            // {...register('cashOnlyPurchase', { required: "Cash only purchase is required." })}
                                                                            />
                                                                        </div>)} />
                                                                {errors.cashOnlyPurchase && <span style={{ color: "red" }}>{errors.cashOnlyPurchase.message}</span>}
                                                            </div>
                                                        </Col>

                                                        <Col md={12}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Financing available
                                                                </label>
                                                                <Controller
                                                                    control={control}
                                                                    name="financingAvailable"
                                                                    rules={{ required: "Finanace is required." }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <div className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Yes"
                                                                                value={true}
                                                                                name="group3"
                                                                                type="radio"
                                                                                id="radio17"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                style={{ "width": "100px" }}

                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="No"
                                                                                value={false}
                                                                                name="group3"
                                                                                type="radio"
                                                                                id="radio27"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                            />
                                                                        </div>)} />
                                                                {errors.financingAvailable && <span style={{ color: "red" }}>{errors.financingAvailable.message}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Buyers Premium
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={5}
                                                                    id=""
                                                                    placeholder="Enter buyer premium"
                                                                    {...register("buyerPremium", { required: "Buyer premium is required.", pattern: { value: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/, message: "Only numeric characters are allowed." } })}
                                                                />
                                                                {errors.buyerPremium && <span style={{ color: "red" }}>{errors.buyerPremium.message}</span>}
                                                                <Form.Text
                                                                    style={{ fontStyle: "italic" }}
                                                                    className="text-muted"
                                                                >
                                                                    *Greater of 5% of bid amount or $1000
                                                                </Form.Text>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Earnest money deposit
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    placeholder="Enter money deposit"
                                                                    {...register('earnestMoney', { required: "Earnest money deposit is required.", pattern: { value: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/, message: "Only numeric characters are allowed." } })}
                                                                />
                                                                {errors.earnestMoney && <span style={{ color: "red" }}>{errors.earnestMoney.message}</span>}
                                                                <Form.Text
                                                                    style={{ fontStyle: "italic" }}
                                                                    className="text-muted"
                                                                >
                                                                    *Lesser of 3.0% of bid amount of $15,000
                                                                </Form.Text>
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <div className="my-3">
                                                                <Form.Group
                                                                    className="mb-3"
                                                                    controlId="exampleForm.ControlTextarea1"
                                                                >
                                                                    <Form.Label>Description</Form.Label>
                                                                    <Form.Control as="textarea" rows={3} maxLength={300} {...register('description', { required: 'Description is required.' })} />
                                                                </Form.Group>
                                                                {errors.description && <span style={{ color: "red" }}>{errors.description.message}</span>}
                                                            </div>
                                                            <button
                                                                className="btn sign-btn fill-btn  text-white listing-submit"
                                                            // onClick={handleSubmit(onSubmit)}
                                                            >
                                                                submit
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </form>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="text-center otp_popup_verify pt-4">
                        <img
                            src={require("../../assets/images/successful.svg").default}
                            alt=""
                        />
                        <h5 className="mt-4">Your {globalData.userType === "seller" ? 'property' : 'listing'} is under review.</h5>
                        <p>
                            Your {globalData.userType === "seller" ? 'property' : 'listing'} information submitted to admin. Admin will review it.
                        </p>
                        <h6>Thank you</h6>
                        <button
                            className="btn okbtn text-white mb-4 mt-2"
                            onClick={handleClose}
                        >
                            OK
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default Add_property;
