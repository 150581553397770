import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { context } from "../GlobalContext/GlobalContext";
import { AddToFavourite, AgentList, InterestedProperty, media_url, PropertyDetails, web_url, ShareProperty } from "../Service/Service";
import Swal from "sweetalert2";

const Property_regular = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  // const { register, handleSubmit, formState: { errors } } = useForm()
  const navigate = useNavigate()
  const loc = useLocation()
  let [query] = useSearchParams()
  const [show, setShow] = useState(false);
  const globalData = useContext(context)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [propertyDetail, setPropertyDetail] = useState({})
  const [showsucess, setShowsucess] = useState(false);
  const [showshare, setShowshare] = useState(false);
  const [share_url, setShare_url] = useState('');
  const [copy, setCopy] = useState(false);
  const { register, handleSubmit, watch, formState: { errors }, setValue, control, reset, getValues } = useForm();
  const [agentList, setAgentList] = useState([])
  const [StartDateCountDown, setStartDateCountDown] = useState({
    "days": 0,
    "hours": 0,
    "minutes": 0,
    "seconds": 0
  })
  const [EndDateCountDown, setEndDateCountDown] = useState({
    "days": 0,
    "hours": 0,
    "minutes": 0,
    "seconds": 0
  })
  const handleCloseshare = () => setShowshare(false);
  const handleClosesucess = () => {
    setShowsucess(false);
  };
  const handleShowsucess = (e) => {

    handleClose()
    setShowsucess(true)
  }

  const GetPropertyDetails = () => {
    globalData.setLoader(true)
    if (globalData.userType !== "seller") {
      GetAgentList()
    }
    PropertyDetails(query.get('id'), globalData.token).then(response => {
      if (response.status === 200) {
        console.log("res....",response)
        globalData.setLoader(false)
        setPropertyDetail(response.data.data);
      }
    }).catch(err => {
      globalData.setLoader(false)
      if (err.response.status === 400) {
        toast.error(err.response.data.message)
      }
      if (err.response.status === 401) {
        globalData.setToken('')
        localStorage.clear()
        toast.info("You session has expired please login again!.")
        navigate("/login?path="+window?.location?.pathname+window?.location.search)
      }
    })
  }


  const AddFav = (e, id) => {
    console.log('add');
    const payload = { property: id }
    AddToFavourite(globalData.token, payload).then(response => {
      if (response.status === 200) {
        // e.target.style.color='#e83f67'
        e.target.innerHTML = response.data.data.is_favourite ? "Remove from favorite" : "Add to favorite"
        // e.target.setAttribute("disabled", true)
        e.target.setAttribute("style", "background-color:#e83f67 !important;color:#ffff !important")
      }
    })
  }


  const onSubmit = (data) => {
    data.property = query.get('id')
    // console.log(data);
    InterestedProperty(globalData.token, data).then(response => {
      if (response.status === 200) {
        document.getElementById('interested-one').classList.remove('interested-btn')
        document.getElementById('interested-one').classList.add('already-interested')

        //   e.target.setAttribute("disabled", true)
        //  e.target.setAttribute("style","background-color:#e83f67 !important;color:#ffff !important")
        handleShowsucess()
        document.getElementById('interested-one').setAttribute("disabled", true)
        document.getElementById('interested-one').innerHTML = "Already interested"
      }
    })
  }


  // calculate diffrence between today and auction start date &time

  const StartDateTimer = () => {
    setInterval(() => {
      let AuctionStartDiff = new Date(propertyDetail.show_start_time) - new Date()
      if (AuctionStartDiff) {
        var days = Math.floor(AuctionStartDiff / (1000 * 60 * 60 * 24));
        var hours = Math.floor((AuctionStartDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((AuctionStartDiff % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((AuctionStartDiff % (1000 * 60)) / 1000);
        setStartDateCountDown({ ...StartDateCountDown, days: days, hours: hours, minutes: minutes, seconds: seconds })
      }
    }, 1000)
  }

  // calculate diffrence between today and auction end date &time
  const EndDateTimer = () => {
    setInterval(() => {
      let AuctionEndDiff = new Date(propertyDetail.show_end_time) - new Date()
      if (AuctionEndDiff) {

        var days = Math.floor(AuctionEndDiff / (1000 * 60 * 60 * 24));
        var hours = Math.floor((AuctionEndDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((AuctionEndDiff % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((AuctionEndDiff % (1000 * 60)) / 1000);

        setEndDateCountDown({ ...EndDateCountDown, days: days, hours: hours, minutes: minutes, seconds: seconds })
      }
    }, 1000)
  }

  const GetAgentList = () => {
    AgentList(globalData.token).then(response => {
      if (response.status === 200) {
        console.log(response.data.data.featured_agents);
        setAgentList(response.data.data.featured_agents)
      }
    }).catch(err => {
      if (err.response.status === 400) {
        toast.error(err.response.data.message)
      }
      if (err.response.status === 401) {
        globalData.setToken('')
        localStorage.clear()
        toast.info("You session has expired please login again!.")
        navigate('/')
      }
    })
  }

  const BrokerInfo = (e, info) => {
    // Swal.fire('Contact info:',,'info')
    Swal.fire({
      icon: 'info',
      title: 'Contact:',
      text: info,

    })
  }

  function shareProperty(id, type){
    const params = {
      "dynamicLinkInfo":
      {
        "domainUriPrefix":"https://realzonapp.page.link",
      "androidInfo":{
        "androidPackageName":"com.app.realzon"
      }
        ,
      "iosInfo":{
        "iosBundleId":"com.app.RealZon",
        "iosAppStoreId":"1636682391"
      },
      "link":`https://api.realzon.net/api/share_property?property_id=${id}&propertyType=${type}`
    }}
    ShareProperty(params).then(res=>{
      setShare_url(res?.data?.shortLink)
      setShowshare(true)
    }).catch(err=>{
      console.log("error>", err)
    })
  }

  useEffect(() => {
    if (new Date(propertyDetail.show_start_time) > new Date()) {
      StartDateTimer()
    }
    else {
      EndDateTimer()
    }

  }, [propertyDetail])

  useEffect(() => {
    {
      globalData.token &&
        GetPropertyDetails()
        
    }
    if(!globalData?.token){
      window.location.href="/login?path="+window?.location?.pathname+window?.location.search;
    }
  }, [globalData.token])

  return (
    <>
      <section className="hero-banner">
        <Container>
          <p className="property-banner-heading">
            <img src={require("../assets/images/play.png")}></img>Properties
          </p>
          <h2>Properties</h2>
        </Container>
      </section>
      <div className="next-previous-tab">
        <Container>
          <p>
            <img onClick={() => navigate('/')} style={{ cursor: "pointer" }} src={require("../assets/images/home.png")}></img>
            <i className="fa fa-angle-right" aria-hidden="true"></i>
            <span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>Properties</span>
            <i className="fa fa-angle-right" aria-hidden="true"></i>
            <span className="blue-text">{propertyDetail.name}</span>
          </p>
        </Container>
      </div>
      <Container>
        {propertyDetail.images?.length > 0 &&
          <Carousel responsive={responsive} className="properties-slider" draggable={false}>
            {propertyDetail.images?.map((item) =>
              <div key={item.id}>
                <img src={media_url + item.image} alt="img" />
              </div>
            )}

          </Carousel>}
      </Container>

      <section className="house-details">
        <Container>
          <Row>
            <Col lg={8} md={12}>
              <div className="house-details-left-side">
                {(((propertyDetail?.property_type == "auction" && propertyDetail?.auction_type=="in_person") || propertyDetail.property_type == "sale" ) && (globalData.userType === "customer" || (globalData.userType === "agent" && Number(globalData.userId) !== propertyDetail.added_by?.id))) &&

                  <Button
                    type="button"
                    className={propertyDetail.is_interest ? "already-interested" : "interested-btn"}
                    variant="unset"
                    id="interested-one"
                    onClick={handleShow}
                    disabled={propertyDetail.is_interest ? true : false}
                  >
                    {propertyDetail.is_interest ? "Already interested" : "interested"}
                  </Button>}

                {(globalData.userType !== "seller" && Number(globalData.userId) !== propertyDetail.added_by?.id) &&
                  <Button
                    type="button"
                    className={propertyDetail.is_favourite ? "remove-favorite-btn" : "add-favorite-btn"}
                    variant="unset"
                    onClick={(e) => { AddFav(e, propertyDetail.id) }}
                  // disabled={propertyDetail.is_favourite ? true : false}
                  >
                    {propertyDetail.is_favourite ? "Remove from favorite" : "Add to favoritess"}
                  </Button>}
                {(propertyDetail.added_by?.id === Number(globalData.userId) && propertyDetail.is_approved !== true) &&
                  <Button
                    type="button"
                    className="listing-btn"
                    variant="unset"
                    onClick={() => navigate(`/update_property?id=${query.get('id')}`)}

                  >
                    edit listing
                  </Button>}
                  <div className="share-propty">
                      <h2>{propertyDetail.name}</h2>
                      
                      {propertyDetail?.is_approved && <span onClick={()=>{
                        shareProperty(propertyDetail.id, propertyDetail.property_type)}}>
                        Share <i class="fa fa-share" aria-hidden="true"></i>
                      </span>}
                  </div>
                <div className="row rowtext mb-4">
                  <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-6">
                    <p>
                      <img
                        src={require("../assets/images/location.png")}
                        alt="img"
                        className="me-1"
                      ></img>
                      {(propertyDetail.auction_type === "online" && (globalData.plan === "null" || globalData.plan === null)) || (propertyDetail.auction_type === "in_person" && (globalData.plan === "null" || globalData.plan === null)) ? "🔒" : propertyDetail.address}
                    </p>
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6">
                    <div className="fex_row">
                      <p><img
                        src={require("../assets/images/frame.png")}
                        alt="img"
                        className="me-1"
                      ></img>
                        {propertyDetail.total_sqft_area || propertyDetail.sf_area || propertyDetail.total_area} sqft</p>

                      <div className="d-inline-block">
                        {propertyDetail.auction_type === "in_person" &&
                          <span className="color-green">
                            <i className="fa fa-circle" aria-hidden="true"></i>
                            In-Person Auction
                          </span>}
                      </div>
                    </div>
                  </div>
                </div>
                <h6>Description</h6>
                <p style={{'overflow-wrap': 'anywhere'}}>
                  {propertyDetail.description}
                </p>
                {propertyDetail.auction_type !== "in_person" &&
                  <>
                    <hr></hr>
                    <h6>Features</h6>
                    <div className="service-property service-property-two">
                      <div className="service-one">
                        <img src={require("../assets/images/bed.png")}></img>
                        <div>
                          <p>Bedrooms</p>
                          <h6>{propertyDetail.number_of_bedrooms}</h6>
                        </div>
                      </div>
                      <div className="service-one">
                        <img src={require("../assets/images/bath.png")}></img>
                        <div>
                          <p>bathrooms</p>
                          <h6>{propertyDetail.number_of_full_bathrooms + propertyDetail.number_of_half_bathrooms}</h6>
                        </div>
                      </div>
                      <div className="service-one">
                        <img src={require("../assets/images/balcony.png")}></img>
                        <div>
                          <p>balcony</p>
                          <h6>{propertyDetail.number_of_balcony}</h6>
                        </div>
                      </div>
                      <div className="service-one">
                        <img src={require("../assets/images/food.png")}></img>
                        <div>
                          <p>kitchen</p>
                          <h6>{propertyDetail.number_of_kitchen}</h6>
                        </div>
                      </div>
                    </div>
                    {((globalData.plan === "null" || globalData.plan === null) && propertyDetail.auction_type === "online") &&
                      <p className="text-danger">"*You need to purchase the subscription plan to unlock these details."</p>}

                    <hr />
                  </>
                }
                {propertyDetail.property_type === "sale" &&
                  <>
                    <h6>Information</h6>

                    <Row className="information-table">
                      <Col lg={6} md={12} className="ps-1">
                        <Table borderless responsive>
                          <tbody>
                            <tr>
                              <td>Possession</td>
                              <td className="text-right">{moment(propertyDetail.possession_date).format("MM/DD/yyyy")}</td>
                            </tr>
                            <tr>
                              <td>Parcel ID</td>
                              <td className="text-right">{propertyDetail.parcel_id}</td>
                            </tr>
                            <tr>
                              <td>Zoning</td>
                              <td className="text-right">{propertyDetail.zoning}</td>
                            </tr>
                            <tr>
                              <td>Style</td>
                              <td className="text-right">{propertyDetail.style}</td>
                            </tr>
                            <tr>
                              <td>Year built</td>
                              <td className="text-right">{propertyDetail.built_year}</td>
                            </tr>
                            <tr>
                              <td>Taxes</td>
                              <td className="text-right">${Number(propertyDetail.taxes).toLocaleString('en-US')}</td>
                            </tr>
                            <tr>
                              <td>Cofo</td>
                              <td className="text-right">{propertyDetail.cofo === true ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                              <td>Garage (location)</td>
                              <td className="text-right">{propertyDetail.garage === true ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                              <td>Water view</td>
                              <td className="text-right">{propertyDetail.water_view === true ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                              <td>Basement</td>
                              <td className="text-right">{propertyDetail.basement === true ? (propertyDetail.basement_type === "HALF_FINISHED" ? "Half finished" : propertyDetail.basement_type === "FINISHED" ? "Finished" : propertyDetail.basement_type === "FULL" ? "Full" : '') : "No"}</td>
                            </tr>

                            <tr>
                              <td>Electric</td>
                              <td className="text-right">{propertyDetail.electricity === "V220" ? "220V" : "110V"}</td>
                            </tr>
                            <tr>
                              <td>Bath</td>
                              <td className="text-right">Full Bath :{propertyDetail.number_of_full_bathrooms}, Half Bath : {propertyDetail.number_of_half_bathrooms}</td>
                            </tr>
                            <tr>
                              <td>Exterior</td>
                              <td className="text-right">{propertyDetail.exterior?.map((item) => item === "balcony" ? "Balcony" : item === "grill" ? "Grill" : item === "deck" ? "Deck" : item === "patio" ? "Patio" : item === "fence" ? "Fence" : item === "shed_sprinkler_system" ? "Shed sprinkler system" : item === "garage_door_opener" && "Garage door opener").join(', ')}</td>
                            </tr>
                            <tr>
                              <td>Master bedroom</td>
                              <td className="text-right">{propertyDetail.master_bedroom?.map((item) => item === "dressing_room" ? "Dressing room" : item === "jacuzzi" ? "Jaccuzzi" : item === "fireplace" ? "Fireplace" : item === "suite" ? "Suite" : item === "private_bath" ? "Private bath" : item === "walk_in_closet" ? "Walk in closet" : item === "shared_bathroom" && "Shared bathroom").join(", ")}</td>
                            </tr>
                            <tr>
                              <td>Yard</td>
                              <td className="text-right">{propertyDetail.yard?.map((item) => item === "front" ? "Front" : item === "side" ? "Side" : item === "back" ? "Back" : item === "none" ? "None" : '').join(', ')}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col lg={6} md={12} className="pe-1">
                        <Table borderless responsive>
                          <tbody>
                            <tr>
                              <td>Building Sqft and Dimensions</td>
                              <td className="text-right">{propertyDetail.total_sqft_area} sqft, {propertyDetail.total_sqft_area}x{propertyDetail.dimensions} ft</td>
                            </tr>
                            <tr>
                              <td>Lot Sqft and Dimensions</td>
                              <td className="text-right">{propertyDetail.total_lot_sqft_area} sqft, {propertyDetail.total_lot_sqft_area}x{propertyDetail.lot_dimensions} ft</td>
                            </tr>
                            <tr>
                              <td>Condition</td>
                              <td className="text-right">{propertyDetail.condition}</td>
                            </tr>
                            <tr>
                              <td>Total Rooms Main unit</td>
                              <td className="text-right">{propertyDetail.total_rooms_in_main_unit}</td>
                            </tr>
                            <tr>
                              <td>Heating</td>
                              <td className="text-right">{propertyDetail.heating}</td>
                            </tr>
                            <tr>
                              <td>Cooling</td>
                              <td className="text-right">{propertyDetail.cooling}</td>
                            </tr>
                            <tr>
                              <td>AC</td>
                              <td className="text-right">{propertyDetail.ac}</td>
                            </tr>
                            <tr>
                              <td>Heat source</td>
                              <td className="text-right">{propertyDetail.heat_source}</td>
                            </tr>
                            <tr>
                              <td>Heat system</td>
                              <td className="text-right">{propertyDetail.heat_system === "FORCED_AIR" ? "Forced Air" : propertyDetail.heat_system === "STEAM" ? "Steam" : propertyDetail.heat_system === "HOT_WATER" ? "Hot water" : propertyDetail.heat_system === "OTHER" ? "Other" : ''}</td>
                            </tr>
                            <tr>
                              <td>Kitchen</td>
                              <td className="text-right">{propertyDetail.kitchen?.map((item) => item === "eat_in" ? "Eat in" : item === "yard_access" ? "Yard Access" : item === "galary" ? "gallery" : '').join(", ")}</td>
                            </tr>
                            <tr>
                              <td>Living room</td>
                              <td className="text-right">{propertyDetail.living_room?.map((item) => item === "combination" ? "Combination" : item === "formal" ? "Formal" : item === "sunken" ? "Sunken" : item === "fireplace" ? "Fireplace" : item === "separate" ? "Seperate" : '').join(", ")}</td>
                            </tr>
                            <tr>
                              <td>Main Bath</td>
                              <td className="text-right">{propertyDetail.main_bath?.map((item) => item === "3_quarter" ? "3 quarter" : item === "full_bath" ? "Full Bath" : item === "bidet" ? "Bidet" : item === "skylight" ? "Skylight" : item === "jacuzzi" ? "Jacuzzi" : item === "half_bath" ? "Half Bath" : item === "seperate_shower" ? "Seperate shower" : '').join(", ")}</td>
                            </tr>
                            <tr>
                              <td>Pool</td>
                              <td className="text-right">{propertyDetail.pool?.map((item) => item === "above_ground" ? "Above ground" : item === "in_ground" ? "In ground" : item === "indoor" ? "Indoor" : item === "none" ? "None/No-Pools" : '').join(', ')}</td>
                            </tr>
                            <tr>
                              <td>Sewer</td>
                              <td className="text-right">{propertyDetail.sewer === "PEPTIC" ? "Peptic" : propertyDetail.sewer === "CITY" ? "City" : propertyDetail.sewer === "CESSPOOL" ? "Cesspool" : propertyDetail.sewer === "PRIVATE" ? "Private" : propertyDetail.sewer === "STROM" ? 'Strom' : "None"}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </>
                }

                {/* property_online_auction */}
                {propertyDetail.auction_type === "online" &&
                  <Col lg={8} md={12}>
                    <h6>Information</h6>
                    <Row className="information-table">
                      <Col md={6} className="ps-1">
                        <Table borderless responsive>
                          <tbody>
                            <tr>
                              <td>Property ID</td>
                              <td className="text-right">{(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.listing_id}</td>
                            </tr>
                            <tr>
                              <td>Year built</td>
                              <td className="text-right">{(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.built_year}</td>
                            </tr>
                            <tr>
                              <td>Sale type</td>
                              <td className="text-right">{propertyDetail.sale_type}</td>
                            </tr>
                            <tr>
                              <td>Lot size</td>
                              <td className="text-right">{propertyDetail.lot_dimensions} sqft</td>
                            </tr>
                            <tr>
                              <td>SF</td>
                              <td className="text-right">{propertyDetail.sf_area} sqft,{propertyDetail.sf_area}x{propertyDetail.sf_dimensions} ft</td>
                            </tr>
                            <tr>
                              <td> Country</td>
                              <td className="text-right">{propertyDetail.country}</td>
                            </tr>
                            <tr>
                              <td>Financing available</td>
                              <td className="text-right">{(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.finance_available === true ? "Yes" : "No"}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col md={6} className="pe-1">
                        <Table borderless responsive>
                          <tbody>
                            <tr>
                              <td>MLS ID</td>
                              <td className="text-right">{(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.mls_id}</td>
                            </tr>
                            <tr>
                              <td>Bid deposit</td>
                              <td className="text-right">${(globalData.plan === "null" || globalData.plan === null) ? "🔒" : Number(propertyDetail.bid_deposit).toLocaleString('en-US')}</td>
                            </tr>
                            <tr>
                              <td>Buyers Premium</td>
                              <td className="text-right">${(globalData.plan === "null" || globalData.plan === null) ? "🔒" : Number(propertyDetail.buyers_premium).toLocaleString('en-US')}</td>
                            </tr>
                            <tr>
                              <td>Earnest money deposit</td>
                              <td className="text-right">${(globalData.plan === "null" || globalData.plan === null) ? "🔒" : Number(propertyDetail.earnest_money_deposit).toLocaleString('en-US')}</td>
                            </tr>
                            <tr>
                              <td>Occupied</td>
                              <td className="text-right">{(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.occupied === true ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                              <td>Cash only Purchase</td>
                              <td className="text-right">{(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.cash_only_purchase === true ? "Yes" : "No"}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Col>}
                  {((globalData.plan === "null" || globalData.plan === null) && propertyDetail.auction_type === "in_person") &&
                      <p className="text-danger">"*You need to purchase the subscription plan to unlock these details."</p>}

                {propertyDetail.auction_type === "in_person" &&
                  <>
                    <hr></hr>
                    <h6>Information</h6>
                    <Row className="information-table">
                      <Col md={6} className="ps-1">
                        <Table borderless responsive>
                          <tbody>
                            {/* <tr>
                              <td>Possession</td>
                              <td className="text-right">{moment(propertyDetail.possession_date).format("MM/DD/yyyy")}</td>
                            </tr> */}
                            <tr>
                              <td>Date Added</td>
                              <td className="text-right">
                              {moment(propertyDetail.added_time).format("MM/DD/yyyy")}
                              </td>
                            </tr>
                            <tr>
                              <td>Line</td>
                              <td className="text-right">
                              $ {(globalData.plan === "null" || globalData.plan === null) ? "🔒" : Number(propertyDetail.line).toLocaleString('en-US')}
                                </td>
                            </tr>
                            <tr>
                              <td>Plaintiff</td>
                              <td className="text-right">
                              {(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.plaintiff}
                                </td>
                            </tr>{" "}
                            <tr>
                              <td>Index number</td>
                              <td className="text-right">
                              {(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.index_number}
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>Plaintiff attorney</td>
                              <td className="text-right">
                              {(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.plaintiff_attorney}
                            </td>
                            </tr>{" "}
                            <tr>
                              <td>Auction notes</td>
                              <td className="text-right">
                              {(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.auction_note}
                                </td>
                            </tr>{" "}
                          </tbody>
                        </Table>
                      </Col>
                      <Col md={6} className="pe-1">
                        <Table borderless responsive>
                          <tbody>
                            <tr>
                              <td>Auction Date and Time</td>
                              <td className="text-right">
                              {(globalData.plan === "null" || globalData.plan === null) ? "🔒" : moment(propertyDetail.auction_date).format("MM/DD/YYYY")} | {(globalData.plan === "null" || globalData.plan === null) ? "🔒" : moment(propertyDetail.show_auction_time).format('HH:mm')}
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>Auction location</td>
                              <td className="text-right">
                              {propertyDetail.auction_location}
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>Defendant</td>
                              <td className="text-right">
                              {(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.defendant}
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>Judgment</td>
                              <td className="text-right">
                              {(globalData.plan === "null" || globalData.plan === null) ? "🔒" : moment(propertyDetail.judgment).format("MM/DD/YYYY")}
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>Referee number</td>
                              <td className="text-right">
                              {(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.referee_number}
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>Foreclosure type</td>
                              <td className="text-right">
                              {(globalData.plan === "null" || globalData.plan === null) ? "🔒" : propertyDetail.foreclosure_type}
                              </td>
                            </tr>{" "}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </>}
              </div>
            </Col>


            <Col lg={4} md={12}>
              {globalData.userType !== "seller" &&
                <div className="house-details-right-side">
                  <div className="person-profile">
                    <div className="person-profile-image">
                      <img src={web_url + propertyDetail.added_by?.image}></img>
                    </div>
                    <h6>{propertyDetail.added_by?.name||"Admin"}</h6>
                    {(propertyDetail.added_by?.user_type !== null &&propertyDetail.added_by?.user_type!=="admin") &&
                      <p>
                        <img src={require("../assets/images/location.png")} />
                        {propertyDetail.added_by?.city},{propertyDetail.added_by?.state}
                      </p>
                    }
                    <p>
                      {propertyDetail.added_by?.about}
                    </p>
                    {(propertyDetail.added_by?.user_type !== "seller" && propertyDetail.added_by?.user_type === "agent") &&
                      <>
                        <p className="text-black mb-0">License Number</p>
                        <p>{propertyDetail.added_by?.license_number}</p>
                        <p className="text-black mb-0">Company</p>
                        <p>{propertyDetail.added_by?.company_name}</p>
                      </>
                    }
                    {(propertyDetail.added_by?.user_type !== "seller" && propertyDetail.added_by?.user_type === "agent") &&
                      <div className="social-icons-box">
                        <button onClick={(e) => BrokerInfo(e, propertyDetail.added_by?.email)} className="border-0 bg-transparent mx-2">
                          <img src={require("../assets/images/message.png")} />
                        </button>
                        <button onClick={(e) => BrokerInfo(e, propertyDetail.added_by?.phone_number)} className="border-0 bg-transparent mx-2">
                          <img src={require("../assets/images/call.png")} />
                        </button>
                      </div>
                    }
                  </div>
                  {(globalData.userType !== "seller" && agentList.length > 0) &&

                    <>
                      <div className="d-flex align-items-center feature-box">
                        <h6>Featured agent</h6>
                        <Link to="/agent_listing">View More</Link>
                      </div>
                      <Row className="mt-4">
                        {agentList.map((agent) =>
                          <Col xxl={3} xl={4} lg={4} md={4} sm={4} xs={6} className="mb-3">
                            <div className="agent-profile-div">
                              <img
                                src={media_url + agent.image}
                                alt="img"
                              />
                              <p>{agent.name} </p>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </>
                  }

                </div>
              }
              {propertyDetail.property_type !== "auction" &&
                <div className={`base-price-box ${globalData.userType === "seller" && "base-price-top"}`}>
                  <p className="mb-0 text-black">List Price :</p>
                  <h5>
                    <span>$</span>{Number(propertyDetail.list_price)?.toLocaleString('en-US')}
                  </h5>
                </div>
              }

            </Col>



            <Col lg={8} md={12}>
              <hr />

            </Col>

            <Col lg={4} md={12}>

              {/* property_person_auction*/}
              <div className={`${globalData.userType === "seller" && "auction-box-top"}`}>
                {propertyDetail.property_type !== "sale" &&
                  <>
                    {new Date(propertyDetail.show_start_time) - new Date() > 0 ?
                      <div class="auction-timer-box"><p class="text-black text-center">Auction start in:</p><div class="auction-start-time"><div class="timer-box"><h3>{StartDateCountDown?.days}</h3><p>Days</p></div><div class="timer-box"><h3>{StartDateCountDown?.hours}</h3><p>Hours</p></div><div class="timer-box"><h3>{StartDateCountDown?.minutes}</h3><p>Min</p></div><div class="timer-box"><h3>{StartDateCountDown?.seconds}</h3><p>Sec</p></div></div></div>
                      :
                      <>
                        {propertyDetail.auction_type !== "in_person" &&
                          <div class="auction-timer-box"><p class="text-black text-center">Auction end in:</p><div class="auction-start-time"><div class="timer-box"><h3>{EndDateCountDown.days}</h3><p>Days</p></div><div class="timer-box"><h3>{EndDateCountDown.hours}</h3><p>Hours</p></div><div class="timer-box"><h3>{EndDateCountDown.minutes}</h3><p>Min</p></div><div class="timer-box"><h3>{EndDateCountDown.seconds}</h3><p>Sec</p></div></div></div>}</>
                    }

                  </>
                }

                {/* online-auction */}
                {propertyDetail.property_type === "auction" &&
                  <>
                  {propertyDetail.auction_type === "online" &&
                    <div className="base-price-box">
                      <div className="price-fee-box">
                        {propertyDetail.auction_type === "online" &&
                        <>
                          <div>
                            <p className="mb-0 text-black">
                              Entry Fees
                              <img className="ms-2"
                                data-tip="Note: This is not any initial payment OR token
                                    amount for the property. This is the entry
                                    amount for placing the bid for the selected
                                    property. Admin will refund this amount(if
                                    you are not the higher bidder) once the auction
                                    is over after deducting the transaction charges."
                                src={require("../assets/images/info.png")}

                              /><ReactTooltip data-className="dsf" />
                            </p>

                            <h5>
                              <span>$</span>{Number(propertyDetail.entry_fee).toLocaleString('en-US')}
                            </h5>
                          </div>
                        
                        <div>
                          <p className="mb-0 text-black">Base Price:</p>
                          <h5>
                            <span>$</span>{Number(propertyDetail.base_price).toLocaleString('en-US')}
                          </h5>
                        </div></>}
                        {propertyDetail.is_bid &&
                          <div>
                            <p className="mb-0 text-black">My Bid Amount:</p>
                            <h5>
                              <span>$</span>{Number(propertyDetail.bid_price).toLocaleString('en-US')}
                            </h5>
                          </div>
                        }
                      </div>
                      {(propertyDetail.is_bid === false && (globalData.userType === "customer" || (globalData.userType === "agent" && Number(globalData.userId) !== propertyDetail.added_by?.id)) && new Date(propertyDetail.show_end_time) > new Date() && new Date(propertyDetail.show_start_time) < new Date()) &&
                        <div className="bid-now-btn" onClick={() => { (globalData.plan === "null" || globalData.plan === null) ? Swal.fire('', "You need to purchase the subscription plan to place your bid.", "info") : navigate(`/bid?id=${propertyDetail.id}`, { state: { 'name': propertyDetail.name, 'location': propertyDetail.address, 'basePrice': propertyDetail.base_price, "possesionDate": propertyDetail.possession_date, "entry_fee": propertyDetail.entry_fee } }) }}>
                          <button type="button">Bid Now!</button>
                        </div>
                      }
                    </div>}

                  </>
                }
              </div>
            </Col>



          </Row>
        </Container>
        <div className="bottom-banner">
          <Container>
            <div className="banner-botton-content">
              <p className="property-banner-heading">
                <img src={require("../assets/images/play.png")}></img>let’s take
                a tour
              </p>
              <h2>
                Property Smarter,<br></br> Quicker & Anywhere
              </h2>
              <Link to="">
                Join now <i className="fa fa-play" aria-hidden="true"></i>
              </Link>
            </div>
          </Container>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
        className="details-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter your details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" maxLength={30} placeholder="Enter name" {...register('name', { required: "Name is required." })} />
              {errors.name && <span style={{ color: "red" }}>{errors.name.message}</span>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Phone number</Form.Label>
              <Form.Control type="text" maxLength={12} placeholder="Enter phone number"{...register('phone_number', { required: "Phone number is required.", pattern: { value: /^[0-9]*$/, message: "Only numeric characters are allowed." } })} />
              {errors.phone_number && <span style={{ color: "red" }}>{errors.phone_number.message}</span>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Email</Form.Label>
              <Form.Control type="text" placeholder="Enter enter email"{...register('email', { required: "Email address is required.", pattern: { value: /^\S+@\S+\.\S+$/, message: "Please enter valid email address." } })} />
              {errors.email && <span style={{ color: "red" }}>{errors.email.message}</span>}

              {/* <Form.Label>Address</Form.Label>
              <Form.Control type="text" placeholder="Enter address" {...register("address", { required: "Address is required." })} />
              {errors.address && <span style={{ color: "red" }}>{errors.address.message}</span>} */}
              {/* <Controller

                render={({ field: { onChange, name, ref, value }, fieldState: { error } }) => {
                  return (
                    <div className="my-4">
                      <label className="form-label">
                        Address
                      </label>
                      <Autocomplete ref={ref} apiKey="AIzaSyCTg77HJ0mIEmvnMn_lOHbd_x8QEk9zD0g" defaultValue={value} className="form-control" placeholder="eg. 857 Pineknoll St. Fort Lauderdale, FL 33308" onChange={() => setValue('address', '')} onPlaceSelected={(place) => onChange(place.formatted_address)} options={{

                        types: ["geocode", "establishment"],
                      }} />

                      {error?.message && <span style={{ color: "red" }}>{error.message}</span>}
                    </div>
                  )
                }}
                control={control}
                name="address"
                rules={{ required: "Please select address." }}
              /> */}
            </Form.Group>
            <Button variant="unset" className="submit-bt" type="submit" >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showsucess}
        onHide={handleClosesucess}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center otp_popup_verify pt-4">
            <img
              src={require("../assets/images/successful.svg").default}
              alt=""
            />
            <h5 className="mt-4">Thank you for submitting details.</h5>
            <p>Our team will reach you soon !!</p>
            <button
              className="btn okbtn text-white mb-4 mt-2"
              onClick={handleClosesucess}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showshare}
        onHide={handleCloseshare}
        animation={false}
        centered
        className="text-center meta-mask-modal share-modal-new"
      >
      <Modal.Body className="share-property-modal">
          <h3>Share Property</h3>
          {copy ? (
            <>
              <span style={{ fontSize: "12px", color: "#ff7750" }}>
                Link Copied
              </span>
              <br></br>
            </>
          ) : (
            <>
              <span style={{ fontSize: "10px" }}></span>
              <br></br>
            </>
          )}
          <input
            type="text"
            value={share_url}
            disabled={true}
            style={{ fontSize: "12px" }}
          />
          <CopyToClipboard text={share_url} onCopy={() => setCopy(true)}>
            <i
              className="fa fa-clipboard Demo__some-network__share-button"
              title="Copy to clipboard"
              aria-hidden="true"
              style={{ fontSize: "19px", cursor: "pointer" }}
            ></i>
          </CopyToClipboard>
          <br></br>
          <br></br>
          <Button
            type="button"
            className="ok-bt"
            onClick={() => setShowshare(false)}
          >
            Ok
          </Button>
        </Modal.Body>
          
      </Modal>
    </>
  );
};
export default Property_regular;
