import axios from "axios"

export const web_url = "https://api.realzon.net"
// export const web_url = "https://apirealzon.ecomempire.in"
export const media_url = "https://api.realzon.net"

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;



export const SignUp = (payload) => {
    return axios.post(web_url + '/api/sign_up/', payload)
}

export const SocialSignup = (id, token, payload) => {
    return axios.put(web_url + `/api/users/${id}`, payload, {
        headers: {
            "Authorization": "Token " + token
        }
    })
}

export const SocialLogin = (payload) => {
    return axios.post(web_url + '/api/social_login/', payload)
}

export const UserLogin = (payload) => {
    return axios.post(web_url + '/api/user_login/', payload)
}

export const ForgotPass = (payload) => {
    return axios.post(web_url + '/api/send_otp/', payload)
}
export const VerifyOtp = (payload) => {
    return axios.post(web_url + '/api/verify_otp/', payload)
}

export const ResetPassword = (payload) => {
    return axios.post(web_url + '/api/reset_password/', payload)
}

export const AddProperty = (payload, token) => {

    return axios.post(web_url + '/api/add_property/', payload, {
        headers: {
            "timeZone": timezone,
            "Authorization": "Token " + token
        }
    })
}

export const GetMyProfile = (id, token) => {
    return axios.get(web_url + `/api/users/${id}`, {
        headers: {
            "timeZone": timezone,
            "Authorization": "Token " + token,
            // 'Access-Control-Allow-Methods':'*'
        }
    }
    )
}

export const GetMortage_company = (id, token) => {
    return axios.get(web_url + `/api/partner/${id}/?type=${"mortgage"}`, {
        headers: {
            "timeZone": timezone,
            "Authorization": "Token " + token,
            // 'Access-Control-Allow-Methods':'*'
        }
    }
    )
}

export const GetAtternoys = (id, token) => {
    return axios.get(web_url + `/api/partner/${id}/?type=${"attorney"}`, {
        headers: {
            "timeZone": timezone,
            "Authorization": "Token " + token,
            // 'Access-Control-Allow-Methods':'*'
        }
    }
    )
}



export const UpdateProfile = (id, token, payload) => {
    return axios.put(web_url + `/api/users/${id}`, payload, {
        headers: {
            "Authorization": "Token " + token
        }
    })
}

export const ChangePassword = (token, payload) => {
    return axios.post(web_url + '/api/change_password/', payload, {
        headers: {
            "Authorization": "Token " + token
        }
    })
}

export const SearchProperty = (my_property, property_type, auction_type, property_status, lat, long, page, token) => {
    return axios.get(web_url + `/api/property_listing/?my_property=${my_property}&property_type=${property_type}&auction_type=${auction_type}&status=${property_status}&lat=${lat}&long=${long}&page=${page}`, {
        headers: {
            "timezone": timezone,
            "Authorization": "Token " + token,
        }

    })
}
export const GuestSearchProperty = (my_property, property_type, auction_type, property_status, lat, long, page) => {
    return axios.get(web_url + `/api/property_listing/?my_property=${my_property}&property_type=${property_type}&auction_type=${auction_type}&status=${property_status}&lat=${lat}&long=${long}&page=${page}`)
}


export const PropertyDetails = (id, token) => {
    return axios.get(web_url + `/api/property/${id}/`, {
        headers: {
            "Authorization": 'Token ' + token
        }
    })
}

export const AddToFavourite = (token, payload) => {
    return axios.post(web_url + `/api/add_favorite/`, payload, {
        headers: {
            "Authorization": "Token " + token
        }
    })
}

export const FavPropertyList = (token) => {
    return axios.get(web_url + '/api/favorite_list/', {
        headers: {
            "Authorization": "Token " + token
        }
    })
}

export const InterestedProperty = (token, payload) => {
    return axios.post(web_url + '/api/property_interest/', payload, {
        headers: {
            "Authorization": "Token " + token
        }
    })
}

export const UpdateProperty = (id, payload, token) => {
    return axios.put(web_url + `/api/property/${id}/`, payload, {
        headers: {
            "Authorization": 'Token ' + token
        }
    })
}

export const DeleteImage = (token, payload) => {
    return axios.post(web_url + '/api/remove_image/', payload, {
        headers: {
            "Authorization": 'Token ' + token
        }
    })
}

export const PlaceBid = (token, payload) => {
    return axios.post(web_url + '/api/bid/', payload, {
        headers: {
            "Authorization": 'Token ' + token
        }
    })
}

export const AgentList = () => {
    return axios.get(web_url + '/api/agent_list/',)
}

export const MotargeDetails = () => {
    return axios.get(web_url + '/api/mortgage/?page=1',)
}

export const attorneys = () => {
    return axios.get(web_url + '/api/attorney/?page=1',)
}

export const MyBids = (token) => {
    return axios.get(web_url + '/api/user_bids/', {
        headers: {
            "Authorization": 'Token ' + token
        }
    })
}

export const ContactUs = (token, payload) => {
    return axios.post(web_url + '/api/contact_us/', payload)
    
}
export const GetNotificationList =(token,page)=>{
    return axios.get(web_url +`/api/notification_list/?page=${page}`,{
        headers:{
            "Authorization":"Token " +token
        }
    })
}

export const BuySubscriptionPlan = (token,payload) =>{
    return axios.post(web_url+'/api/buy_plan/',payload,{
        headers:{
            "Authorization":"Token "+token
        }
    })
}

export const ClearAllNotifications=(token)=>{
    return axios.get(web_url+'/api/clear_notification/',{
        headers:{
            "Authorization":"Token "+token
        }
    })
}

export const cancelSubscription=(token,payload)=>{
    return axios.post(web_url+'/api/cancel_plan/',payload,{
        headers:{
            "Authorization":"Token "+token
        }
    })
}

export const RecentProperties=()=>{
    return axios.get(web_url+'/api/recent_properties/')
}

export const ShareProperty = (payload) => {
// 'AIzaSyCTg77HJ0mIEmvnMn_lOHbd_x8QEk9zD0g'
    let url=`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_DYNAMIC}`
    return axios.post(url,payload,{
        "Content-Type": "application/json"
    })
}
