import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { context } from "../GlobalContext/GlobalContext";
import { web_url } from "../Service/Service";

const Footer = () => {
  const globalData=useContext(context)
  let navigate = useNavigate();

  function Home() {
    navigate("/");
  }
  function Properties (){
    if (globalData.userType === "agent" || globalData.userType === "seller") {
      navigate(`/properties?type=my_property&myProp=sale&lat=${globalData.currentLocation.lat}&long=${globalData.currentLocation.lng}&&page=1`);
  }
  else {
      navigate(`/properties?type=sale&lat=${globalData.currentLocation.lat}&long=${globalData.currentLocation.lng}&page=1`);
  }

  }
  function About() {
    navigate("/about");
  }
  function Feature() {
    navigate("/features");
  }
  function Contact() {
    navigate("/contactus");
  }

  return (
    <>
      <footer>
        <div className="footer-color">
          <div className="container">
            <div className="row justify-content-center">
              <aside className="col-xl-6 col-lg-7 col-md-9">
                {/* <div className="footer-area">
                  <h3 className="text-light text-center">Our Newsletter</h3>
                  <p className="text-light text-center">
                    We don’t send spam so don’t worry.
                  </p>
                  <form className="footer-form my-4">
                    <input
                      type="email"
                      className="form-control"
                      id=""
                      placeholder="Enter your email address"
                    />
                    <button className="btn sign-btn fill-btn ms-0 text-white">
                      Subscribe now
                    </button>
                  </form>
                </div> */}
                <ul className="mt-4 pt-4 p-0">
                  <li>
                    <p onClick={Home}>Home</p>
                  </li>
                  <li>
                    <p  onClick={Properties}>properties</p>
                  </li>
                  <li>
                    <p onClick={About}>about Us</p>
                  </li>
                  <li>
                    <p onClick={Feature}>Features</p>
                  </li>
                  <li>
                    <p onClick={Contact}>Contact us</p>
                  </li>
                </ul>
              </aside>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <aside className="col-md-4"></aside>
              <aside className="col-lg-4 col-md-12 text-right">
                <p className="text-light text-center mb-0">
                  © 2022 Realzon. All Right Reserved.
                </p>
              </aside>
              <aside className="col-lg-4 col-md-12 text-right">
                <ul className="mb-0 p-0">
                  <li>
                    <p className="mb-0" onClick={() => navigate("/privacy")}>
                      Privacy Policy
                    </p>
                  </li>
                  <li>
                    <p className="mb-0" onClick={() => navigate("/terms")}>
                      {" "}
                      Terms &amp; Conditions
                    </p>
                  </li>
                  <li>
                    <p className="mb-0" onClick={() => navigate("/faq")}>FAQ’s</p>
                  </li>
                </ul>
              </aside>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
