import React, { useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";

const About = () => {
  return (
    <>
      <section className="hero-banner  about-bg">
        <Container>
          <p className="property-banner-heading">
            <img src={require("../assets/images/play.png")}></img>Properties
          </p>
          <h2>The Perfect Choice for your Future</h2>
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing
            <br /> and typesetting industry.{" "}
          </p> */}
        </Container>
      </section>
      <section className="capalites-area">
        <img
          src={require("../assets/images/vector.png")}
          className="vector-img"
        ></img>
        <Container>
          <Row className="align-items-center">
            <Col lg={6} col={12}>
              <div className="capalites-left-side">
                <p className="property-banner-heading text-black">
                  <img src={require("../assets/images/play.png")}></img>
                  about realzon
                </p>
                <h2>What is RealZon?</h2>
                <p>
                  RealZon is a property auction software that helps buyers and
                  sellers facilitate real estate transactions.
                </p>
                <p>
                  The platform provides a seamless, all-in-one MLS listing
                  portal for all 5 boroughs in NYC.
                </p>
              </div>
            </Col>
            <Col lg={6} col={12}>
              <div className="about-right-picture-section">
                <img
                  src={require("../assets/images/about-right-picture.png")}
                  alt="img"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="app-area">
        <Container>
          <div className="capalites-left-side app-area-heading">
            <h2>Why RealZon?</h2>
          </div>
          <Row>
            <Col md={6} lg={4}>
              <div className="gradient-border mt-3">
                <div className="capalites-right-side">
                  <div className="about-section-icons mb-3">
                    <img
                      src={require("../assets/images/data-analysis-icon-1.png")}
                      alt="img"
                    />
                  </div>

                  <h6>Real-Time Data</h6>

                  <p>
                    With real-time data, RealZon gives its users the actionable
                    insights into its properties.{" "}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="gradient-border mt-3 ">
                <div className="capalites-right-side">
                  <div className="about-section-icons mb-3">
                    <img
                      src={require("../assets/images/flexible-customizable-icon.png")}
                      alt="img"
                    />
                  </div>
                  <h6>Flexible Viewing</h6>

                  <p>
                    RealZon provides multiple viewing and filtering options to
                    help users find the right property for them.{" "}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="gradient-border mt-3 ">
                <div className="capalites-right-side">
                  <div className="about-section-icons mb-3">
                    <img
                      src={require("../assets/images/location-history-time-icon.png")}
                      alt="img"
                    />
                  </div>
                  <h6>Geolocation Search</h6>

                  <p>
                    By leveraging geolocation searching, users can search for
                    multiple properties in a single click.{" "}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="gradient-border mt-3 ">
                <div className="capalites-right-side">
                  <div className="about-section-icons mb-3">
                    <img
                      src={require("../assets/images/speed-test-icon.png")}
                      alt="img"
                    />
                  </div>
                  <h6>Speed</h6>

                  <p>
                    With RealZon’s robust database of
                    listings and market knowledge, users
                    gain actionable insights faster{" "}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="gradient-border mt-3 ">
                <div className="capalites-right-side">
                  <div className="about-section-icons mb-3">
                    <img
                      src={require("../assets/images/difference-icon.png")}
                      alt="img"
                    />
                  </div>
                  <h6>Transparency</h6>

                  <p>
                    The Company provides maximum
                    transparency for both buyers and
                    sellers so there are unwanted no
                    surprises{" "}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="gradient-border mt-3 ">
                <div className="capalites-right-side">
                  <div className="about-section-icons mb-3">
                    <img
                      src={require("../assets/images/solution-icon.png")}
                      alt="img"
                    />
                  </div>
                  <h6>End-to-End Solution</h6>

                  <p>
                    RealZon provides a full suite of
                    solutions that enables users to
                    search, list, buy and bid directly from
                    the app{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="text-center our-property-area">
        <Container>
          <h2>The Market</h2>
          <p>
            Total residential sales volume, or consideration, more than doubled,
            soaring from $6.4 billion in the second quarter of 2020 to $13.3
            billion in the second quarter of 2021 - a 109.5% year-on-year
            increase.
          </p>
          <p>
            Property sales totaled $23.7 billion in the first six months of the
            year, eclipsing the $11.5 billion in the first half of 2020. The
            second-quarter total was the largest quarterly total since Q2 2017.
            with the releas of Letraset sheets containing Lorem Ipsum passages,
          </p>
          <p>
            While 89% of people interested in buying a home start online, 52% of
            home buyers find their home online.
          </p>

          <div className="residential-property-sales">
            <div className="residential-sales">
              <img
                src={require("../assets/images/residential.png")}
                alt="img"
              />
            </div>
            <div className="property-sales">
              <img src={require("../assets/images/property-2.png")} alt="img" />
            </div>
          </div>

          <div className="text-center our-property-area streamline-process">
            <h2>Streamline the Process</h2>
            <p>
              Avoid the traditional property buying/selling process of going
              through tons of middlemen and connect with property owners &
              managers directly.
            </p>
            <Row className="align-items-center mt-5">
              <Col md={4}>
                <div className="streamline-process-pictures">
                  <img src={require("../assets/images/store.png")} />
                  <h6>Search</h6>
                </div>
              </Col>
              <Col md={4}>
                <div className="streamline-process-pictures">
                  <img src={require("../assets/images/store-2.png")} />
                  <h6>Find</h6>
                </div>
              </Col>
              <Col md={4}>
                <div className="streamline-process-pictures">
                  <img src={require("../assets/images/store-3.png")} />
                  <h6>Buy/Sell</h6>
                </div>
              </Col>
            </Row>
          </div>

          {/* <div className="text-center our-property-area streamline-process platform-section">
            <h2>The Platform</h2>
            <p>
              Need to expand on what the platform offers to the users. I know I
              put a lot of that in the why section, but would like to expand on
              it here.
            </p>
            <div className="platform-laptop-image mt-4">
              <img src={require("../assets/images/laptop.png")} />
            </div>
          </div> */}
        </Container>
      </section>

      {/* <section className="app-area">
        <Container>
          <div className="capalites-left-side app-area-heading">
            <h2>Team</h2>
            <p>
              The RealZon team is a group of Real Estate experts that have 50+
              years experience in development, auctioning and brokering.
            </p>
          </div>
          <Row>
            <Col md={3}>
              <div className="team-images">
                <img src={require("../assets/images/team-image-1.png")} />
                <h6>Shovkat Mamedov</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="team-images">
                <img src={require("../assets/images/team-image-2.png")} />
                <h6>Louis Springer </h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="team-images">
                <img src={require("../assets/images/gaurav.png")} />
                <h6>Gaurav Malhotra</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="team-images">
                <img
                  src={require("../assets/images/team-image-4.png")}
                  alt="img"
                />
                <h6>Carlo Seneca</h6>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      <section className="about-table-section">
        <Container>
          <div className="capalites-left-side app-area-heading">
            <h2>Competitive Landscape</h2>
          </div>
          <Table borderless className="table-section">
            <thead style={{ borderBottom: "1px solid #13497b" }}>
              <tr>
                <th></th>
                <th>
                  <img src={require("../assets/images/logo-1.png")} alt="img" />
                </th>
                <th>
                  {" "}
                  <img src={require("../assets/images/logo-2.png")} alt="img" />
                </th>
                <th>
                  {" "}
                  <img src={require("../assets/images/logo-3.png")} alt="img" />
                </th>
                <th>
                  <img src={require("../assets/images/logo-4.png")} alt="img" />
                </th>
                <th>
                  <img src={require("../assets/images/logo-5.png")} alt="img" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Home for sales & rent</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
              </tr>
              <tr>
                <td>E-Z Seacrh</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
              </tr>
              <tr>
                <td>Mobile App</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
              </tr>
              <tr>
                <td>Online Broker Agent</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
              </tr>
              <tr>
                <td>Online Real Estate Auction</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
              </tr>
              <tr>
                <td>Map Search</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
              </tr>
              <tr>
                <td>Customers Profiles</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
              </tr>
              <tr>
                <td>Auction/Foreclosure Listings</td>
                <td className="bg-color">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img src={require("../assets/images/like.png")} alt="img" />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
                <td className="text-center">
                  <img
                    src={require("../assets/images/dislike.png")}
                    alt="img"
                  />
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="seller-bottom-box">
            <h3>Begin your Selling Journey on Realzon</h3>
            <Button type="button" className="next-step" variant="unset">
              become a seller
            </Button>
            <img
              src={require("../assets/images/right.png")}
              className="right-vector"
              alt="img"
            ></img>
            <img
              src={require("../assets/images/left.png")}
              className="left-vector"
              alt="img"
            ></img>
          </div>
        </Container>
      </section>
    </>
  );
};
export default About;
