import './App.css';
import './assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './common/Layout';
import Routing from './Routing/Routing';
import ScrollToTop from './common/Scroll_to_top';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from "firebase/app";
import firebaseApp from "./firebase"
// import toast ,{Toaster} from "react-hot-toast";

import "firebase/messaging";
import { useContext, useEffect, useState } from 'react';
import { context } from './GlobalContext/GlobalContext';

import Toast from 'react-bootstrap/Toast';

import ReactToast from 'react-bootstrap/ToastContainer';
import { useNavigate } from 'react-router-dom';



function App() {

  let globalData = useContext(context)
  let navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [notification, setNotification] = useState({ title: '', body: '', id: '' ,type:''})
  const messaging = firebase.messaging.isSupported() ? firebase.messaging(firebaseApp) : null;


  const hnadleNotificationClick = () => {
    setShow(false)
    if (notification.type !== "buy_plan" && notification.type !== "bid" && notification.type !== "cancel_plan") {
      navigate(`/property_regular?id=${notification.id}`)
    }
  }

  useEffect(() => {
    if (messaging) {
      messaging.getToken({ vapidKey: "BHwsBwO6fg0SuP92T0uSJDQ6MnCBZLCbjYrfk4ORjNDtZfduxFUPed9Bh0pgqPBZnpdVnN1IO-j6pLw8dtpLAB4" }).then((currentToken) => {
        if (currentToken) {
          console.log("token", currentToken)
          globalData.setFireBaseToken(currentToken)
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });


      messaging.onMessage((payload) => {

        console.log(payload)
        console.log(payload.notification.body)
        setNotification({ ...notification, title: payload.notification.title, body: payload.notification.body, id: payload.data.id ,type:payload.data.type})
        setShow(true)

      })
    }
    //eslint-disable-next-line  
  }, [])

  return (
    <>
      {/* {console.log(show)} */}

      <Layout>
        <ScrollToTop />
        <Routing />
      </Layout>
      <ToastContainer theme='dark' />
      {/* <Toaster
        position="top-center"
        reverseOrder={false}
      /> */}
      <ReactToast className="p-3" position="top-center">
        <Toast show={show} delay={3500} autohide onClick={hnadleNotificationClick}>
          <Toast.Header closeButton={false}>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">{notification.title}</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>{notification.body}.</Toast.Body>
        </Toast>
      </ReactToast>
    </>
  );
}

export default App;
