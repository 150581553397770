import { useContext } from "react";
import { context } from "../GlobalContext/GlobalContext";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";
import SuccessModal from "./SuccessModal";

const Layout = (props) => {
    let globalData=useContext(context)
    return(
        <>
        {globalData.loader === true &&
            <Loader/>}
            <Header />
                <div>{props.children}</div>
               
            <Footer />
        </>
    )
}
export default Layout;