import React, { useContext, useState } from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { context } from "../GlobalContext/GlobalContext";
import { ContactUs } from "../Service/Service";

const Contactus = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  let globalData = useContext(context)
  const [show, setShow] = useState(false);
  let navigate = useNavigate()
  const handleClose = () => { navigate('/'); setShow(false) }
  const handleShow = () => setShow(true);

  const onSubmit = data => {
    ContactUs(globalData.token, data).then(response => {
      if (response.status === 200) {
        handleShow()
      }
    }).catch(err => {
      if (err.response.status === 400) {
        toast.error(err.response.data.message)
      }
      if (err.response.status === 401) {
        globalData.setToken('')
        localStorage.clear()
        toast.info("You session has expired please login again!.")
        navigate('/')
      }
    })

  }

  return (
    <>
      <section className="hero-banner  about-bg">
        <Container>
          <p className="property-banner-heading">
            <img src={require("../assets/images/play.png")}></img>contact us
          </p>
          <h2>Feel free contact us</h2>
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing
            <br /> and typesetting industry.{" "}
          </p> */}
        </Container>
      </section>
      <section className="contact-form">
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="pe-md-0 pe-sm-1">
              <div className="form-side">
                <p className="property-banner-heading">
                  <img src={require("../assets/images/play.png")}></img>contact
                  us
                </p>
                <h2>Stay in Touch</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>First name</Form.Label>
                        <Form.Control type="text" placeholder="Name" maxLength={15} {...register('name', { required: "Please enter your name." })} />
                        {errors.name && <span style={{ color: "red" }}>{errors.name.message}</span>}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="text" placeholder="Email address"  {...register('email', { required: "Email is required.", pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/, message: "Invalid email." }, setValueAs: v => v.toLowerCase() })} />
                        {errors.email && <span style={{ color: "red" }}>{errors.email.message}</span>}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Contact number</Form.Label>
                    <Form.Control type="text" placeholder="Contact number" maxLength={12} {...register('contact_number', { required: "Contact is required.", pattern: { value: /^[0-9]*$/, message: "Kindly enter numbers." } })} />
                    {errors.contact_number && <span style={{ color: "red" }}>{errors.contact_number.message}</span>}
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} maxLength={300} {...register('message', { required: "Message is required." })} />
                    {errors.message && <span style={{ color: "red" }}>{errors.message.message}</span>}
                  </Form.Group>
                  <button

                    className="contact-submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </Col>
            <Col md={6} className="ps-0">
              <div className="contact-image">
                <img
                  src={require("../assets/images/contact.png")}
                  alt="image"
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="text-center otp_popup_verify pt-4">
            <img src={require('../assets/images/successful.svg').default} alt="" />
            <h5 className="mt-4">Thank you</h5>
            <p>Your request is submitted succesfully.</p>
            <button className="btn okbtn text-white mb-4 mt-2" onClick={handleClose}>ok</button>
          </div>
        </Modal.Body>
      </Modal>

      <section className="app-store-box">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="app-store-box-left-side">
                <p className="property-banner-heading text-black">
                  <img src={require("../assets/images/play.png")}></img>
                  Available on
                </p>
                <h2>We are also available<br /> on App Stores.</h2>
                <p>
                  Mobile app gives you access to easily search, bid and win properties on the go. Find houses by location, MLS access, Court houses auction dates and location, property details and many more.
                </p>
                <Button type="button" variant="unser"><img src={require('../assets/images/googleplay.png')} alt="image"></img></Button>
                <Button type="button" variant="unser"><img src={require('../assets/images/appstore.svg').default} alt="image"></img></Button>
              </div>
            </Col>
            <Col md={6}>
              <div className="mobile-img">
                <img src={require('../assets/images/mobile2.png')} alt="image"></img>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Contactus;
