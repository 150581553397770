import Pagination from "react-js-pagination";



const Paginate = (props) => {
    
    
    return (
        <>
            <div className="pagination_row">
                <Pagination
                    activeClass="activepagination"
                    disabledClass="DisableClass"
                    itemClassFirst="d-none"
                    itemClassLast="d-none"
                    activePage={props.active}
                    itemsCountPerPage={8}
                    totalItemsCount={props.total}
                    pageRangeDisplayed={5}
                    onChange={props.HandlePagination}
                    
                />
                
            </div>
        </>
    )
}

export default Paginate;