import "firebase/messaging";
import firebase from "firebase/app";
// import toast from "react-hot-toast";

const firebaseConfig = {
  apiKey: "AIzaSyDZRGC8QJOkmZcdPxuY0gtVtVvOoDBMpLk",
  authDomain: "realzon-3c24e.firebaseapp.com",
  projectId: "realzon-3c24e",
  storageBucket: "realzon-3c24e.appspot.com",
  messagingSenderId: "863268006536",
  appId: "1:863268006536:web:f142ee8512793d92a54d3d",
  measurementId: "G-Q84S4VZF62"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);


export default firebaseApp;