import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ForgotPass} from "../Service/Service";

const Forgotpassword = () =>{
    const {register ,handleSubmit,formState: {errors} }=useForm()
    let navigate = useNavigate();
    // function otp(){
    //     navigate('/otp',{state:data})
    // }
    function signin(){
        navigate('/')
    }

    const onSubmit=(data)=>{
        
        ForgotPass(data).then(res=>{
            if(res.status===200){
                navigate('/otp',{state:data})
            }
        })
    }
    return(
        <>
        <section className="login-area forgot-area">
                <div className="container">
                    <div className="login-box-area">
                        <div className="row justify-content-center">
                            <aside className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                            <div className="login-box">
                                <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                                    <h2 className="text-center">Forgot password? </h2>
                                    <p className="text-center my-2">We will send one time password on your<br /> email address.</p>
                                    <div className="my-4 pt-3">
                                        <label  className="form-label">Email address</label>
                                        <input type="email" className="form-control" id="" placeholder="eg. adamsmith@gmail.com" {...register('email',{required:"Please enter registered email.", pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/, message: "Invalid email." } , setValueAs:v=>v.toLowerCase()})} />
                                        {errors.email && <span style={{color:"red"}}>{errors.email.message}</span>}
                                    </div>
                                    <button className="btn sign-btn fill-btn w-100 ms-0 mt-3 mb-4 text-white">submit</button>
                                </form>
                                    <hr />
                                    <p className="text-center py-2 flex-p">
                                        <img src={require('../assets/images/back.svg').default} alt="back" className="me-1" />
                                        Back to <span className="signup ms-1" onClick={()=>navigate('/login')}> Sign In</span>
                                    </p>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Forgotpassword;