import React, { Children, cloneElement, isValidElement, useContext, useEffect, useRef } from "react";
import MyMarker from "./MapMarker";
import currentLoc from "../assets/images/location25.png"
import { context } from "../GlobalContext/GlobalContext";
const MyMap = ({ center, zoom, children, mapDrag }) => {

    const ref = useRef();
    const [map, setMap] = React.useState();
    let globalData = useContext(context)
    var myStyles =[
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [
                  { visibility: "off" }
            ]
        }
    ];
    function createCenterControl(map, coords) {
        const controlButton = document.createElement("button");
        controlButton.setAttribute("id", "centerButton");
        // Set CSS for the control.

        controlButton.style.backgroundColor = "#fff";
        controlButton.style.border = "1px solid #000";
        controlButton.style.borderRadius = "3px";
        controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlButton.style.color = "rgb(25,25,25)";
        controlButton.style.cursor = "pointer";
        controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
        controlButton.style.fontSize = "16px";
        controlButton.style.lineHeight = "38px";
        controlButton.style.margin = "8px 0 22px";
        controlButton.style.padding = "0 5px";
        controlButton.style.textAlign = "center";

        controlButton.textContent = "Re-Center";
        controlButton.title = "Click to recenter the map";
        controlButton.type = "button";

        // Setup the click event listeners: simply set the map to Chicago.
        controlButton.addEventListener("click", () => {
            map.setCenter(coords);
        });
        return controlButton;
    }

    useEffect(() => {

        var center = { lat: 0, lng: 0 }


        const success = (pos) => {
            const crd = pos.coords
            center.lat = crd.latitude
            center.lng = crd.longitude
        }
        navigator.geolocation.getCurrentPosition(success)
        let map = new window.google.maps.Map(ref.current, {
            center,
            zoom,
            mapTypeId: "roadmap",
            disableDefaultUI: true,
            styles:myStyles,
            gestureHandling: "greedy"
        })
        setMap(map)
        let options = {
            map,
            'position': { lat: globalData.currentLocation.lat, lng: globalData.currentLocation.lng },
            'icon': currentLoc
        }
        let marker = new window.google.maps.Marker()
        marker.setOptions(options)

        options.map.panTo(marker.getPosition())
        options.map.setZoom(18)
        


        const centerControlDiv = document.createElement("div");
        centerControlDiv.setAttribute("id", "centerButtonDiv");
        // Create the control.
        const centerControl = createCenterControl(map, center);
        // Append the control to the DIV.
        centerControlDiv.appendChild(centerControl);

        map.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].push(centerControlDiv);


    }, []);

    // if (map) {
    //     // createCenterControl(map)
    //     // let controlButton=document.getElementById('centerButton')
    //     // console.log(controlButton);



    //     // map.addListener('dragend', () => {
    //     //     console.log('hi')
    //     //     var coodrs={
    //     //         lat:map.getCenter().lat(),
    //     //         lng:map.getCenter().lng()
    //     //     }
    //     //     // mapDrag(coodrs)
    //     // })

    // }
    return (
        <>
            <div ref={ref} id="map" />
            {Children.map(children, (child) => {

                if (isValidElement(child)) {

                    // set the map prop on the child component
                    return cloneElement(child, { map });
                }
            })}
        </>
    )
}

export default MyMap;