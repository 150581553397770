import { useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { context } from "../GlobalContext/GlobalContext";
import profile_img from "../assets/images/profile-picture.png"
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClearAllNotifications, GetNotificationList } from "../Service/Service";
import moment from "moment";
import { toast } from "react-toastify";

const Header = () => {
    const [expanded, setExpanded] = useState(false);
    let navigate = useNavigate();
    let globalData = useContext(context)
    const [notificationList, setNotificationList] = useState([])
    const [page, setPage] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const Home = () => {
        navigate('/');
    }
    const Properties = () => {
        console.log(globalData.currentLocation);
        if (globalData.userType === "agent" || globalData.userType === "seller") {
            navigate(`/properties?type=my_property&myProp=sale&lat=${globalData.currentLocation.lat}&long=${globalData.currentLocation.lng}&page=1`);
        }
        else {
            navigate(`/properties?type=sale&lat=${globalData.currentLocation.lat}&long=${globalData.currentLocation.lng}&page=1`);
        }

    }
    const About = () => {
        navigate('/about')
    }
    const Feature = () => {
        navigate('/features')
    }
    const Contact = () => {
        navigate('/contactus')
    }
    const Signin = () => {
        navigate('/login')
    }
    const Signup = () => {
        navigate('/signup',)
    }
    const Add_property = () => {
        navigate('/add_property')
    }
    const Add_listing = () => {
        navigate('/add_property')
    }
    const Agent_list = () => {
        navigate('/agent_listing')
    }
    const Search_listing = () => {
        navigate('/search-list')
    }
    const Market_report = () => {
        navigate('/market-report')
    }

    const logout = (e) => {
        e.preventDefault()
        globalData.setToken('')
        globalData.setUserType('')
        localStorage.clear()
        setPage(0)
        navigate('/login')
    }

    const allNotificationList = () => {
        console.log(notificationList.length)
        if (notificationList.length <= totalItems) {
            var temp = page
            temp += 1
            setPage(temp)


            GetNotificationList(globalData.token, temp).then(response => {
                if (response.status === 200) {
                    setTotalItems(response.data.totalItems)

                    setNotificationList(response.data.data)
                }
            }).catch(err => {
                globalData.setLoader(false)
                if (err.response.status === 400) {
                    toast.error(err.response.data.message, { position: "top-center" })
                }
                if (err.response.status === 401) {
                    globalData.setToken('')
                    localStorage.clear()
                    toast.info("You session has expired please login again!.")
                    navigate('/')
                }
            })
        }

    }

    const handleClick = (e, type, property) => {
        if (type !== "buy_plan" && type !== "bid" && type != "cancel_plan") {
            navigate(`/property_regular?id=${property}`)
        }

    }

    const ClarNotifictaions = () => {
        ClearAllNotifications(globalData.token).then(response => {

            if (response.status === 200) {
                setNotificationList([])
            }
        }
        )
    }

    useEffect(() => {
        console.log(globalData.currentLocation)
        console.log("globaldata.......",globalData)
        {
            globalData.token &&
                allNotificationList()
        }
    }, [globalData.token, globalData.currentLocation])

    return (
        <>
            <header>
                <Navbar className="p-0" expand="xl" expanded={expanded}>
                    <Container className="p-0">
                        <Navbar.Brand>
                            <img src={require('../assets/images/logo.svg').default} style={{ cursor: "pointer" }} alt="logo" onClick={() => navigate('/')} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(expanded ? false : true)} />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="ms-auto my-2 my-lg-0 align-items-center" navbarScroll>
                                {globalData.userType !== "seller" &&
                                    <Nav.Link className={window.location.pathname == ('/') ? "active" : null} onClick={() => {
                                        setExpanded(false)
                                        Home()
                                    }}>HOME</Nav.Link>}
                                <Nav.Link className={window.location.pathname == ('/properties') ? "active" : null} onClick={() => {
                                    setExpanded(false)
                                    Properties()
                                }}>properties</Nav.Link>
                                {globalData.userType !== "seller" &&
                                    <Nav.Link className={window.location.pathname == ('/agent_listing') ? "active" : null} onClick={() => {
                                        setExpanded(false)
                                        Agent_list()
                                    }}>Find</Nav.Link>}

                                {globalData.token !== '' && globalData.token !== null && globalData.userType === "seller" &&
                                    <Nav.Link className={window.location.pathname == ('/add_property') ? "active" : null} onClick={() => {
                                        setExpanded(false)
                                        Add_property()
                                    }}>add property</Nav.Link>}
                                {globalData.token !== '' && globalData.token !== null && globalData.userType === "agent" &&
                                    <Nav.Link className={window.location.pathname == ('/add_property') ? "active" : null} onClick={() => {
                                        setExpanded(false)
                                        Add_listing()
                                    }}>add listing</Nav.Link>}

                                <Nav.Link className={window.location.pathname == ('/search-list') ? "active" : null} onClick={() => {
                                    setExpanded(false)
                                    Search_listing()
                                }}>search listing</Nav.Link>
                                <Nav.Link className={window.location.pathname == ('/market-report') ? "active" : null} onClick={() => {
                                    setExpanded(false)
                                    Market_report()
                                }}>Market Report</Nav.Link>
                                <Nav.Link className={window.location.pathname == ('/features') ? "active" : null} onClick={() => {
                                    setExpanded(false)
                                    Feature()
                                }}>Features</Nav.Link>

                                <Nav.Link className={window.location.pathname == ('/about') ? "active" : null} onClick={() => {
                                    setExpanded(false)
                                    About()
                                }}>About Us</Nav.Link>
                                <Nav.Link className={window.location.pathname == ('/contactus') ? "active" : null} onClick={() => {
                                    setExpanded(false)
                                    Contact()
                                }}>Contact us</Nav.Link>
                                <Nav.Link>
                                    {(globalData.token && globalData.userType !== "seller") &&
                                        <Dropdown className="notification-drop">
                                            <Dropdown.Toggle id="dropdown-basic" variant="unset">
                                                <i class="fa fa-bell" aria-hidden="true"></i>
                                                {notificationList.filter((item) => item.is_read === false).length > 0 &&
                                                    <span className="notification-count"></span>}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="py-0 dropdown-menu-right">
                                                <div className="notification_body">
                                                    <div className="notification_header rounded" style={{ padding: "10 20px" }}>


                                                        {/* {notiList.length>3 && <h6 className="mb-0"><a onClick={showNotifications} className="View All">View All</a></h6>} */}

                                                    </div>
                                                    <div id="idNotificationDiv" className="mainotidifation" >
                                                        {notificationList.length === 0 ?
                                                            <p className="mb-0 pt-2 text-center">No notifications yet</p>
                                                            :
                                                            <InfiniteScroll
                                                                dataLength={notificationList}
                                                                next={allNotificationList}
                                                                height={200}
                                                                // inverse={true}
                                                                // loader={<h4>Loading...</h4>}
                                                                hasMore={notificationList.length < totalItems ? true : false}
                                                                scrollableTarget="idNotificationDiv"
                                                            >
                                                                <div className="notification-heading">
                                                                    <h6>Notification</h6>
                                                                    <p onClick={() => ClarNotifictaions()}>Clear All</p>
                                                                </div>
                                                                {notificationList.map((notification, index) =>
                                                                    <div className="content" key={index} >
                                                                        <span className="user_img ml-1 pt-1">
                                                                        </span>
                                                                        <div className="justify-content-between notification-new" onClick={(e) => handleClick(e, notification.type, notification.property)}>
                                                                            <p className="price1">{notification?.message}</p>
                                                                            <p className="timeng1">{moment(notification?.created_at).format("YYYY/MM/DD hh:mm")}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {/* {notificationList} */}
                                                            </InfiniteScroll>}

                                                    </div>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </Nav.Link>


                                {globalData.token !== '' && globalData.token !== null ?
                                    <div className="nav-user-profile">
                                        <div className="user-image">
                                            <img src={globalData.image || profile_img} />
                                        </div>
                                        <div className="user-info">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    {globalData?.name?.slice(0,10)}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <DropdownItem>
                                                        <Link to="/my_profile"><i class="fa fa-user" aria-hidden="true"></i>My Profile</Link></DropdownItem>
                                                    {globalData.userType !== "seller" &&
                                                        <>
                                                            <DropdownItem>
                                                                <Link to="/favorite"><i class="fa fa-heart" aria-hidden="true"></i>Favorite</Link></DropdownItem>
                                                            <DropdownItem>
                                                                <Link to={(globalData.plan === null || globalData.plan === "null") ? "/subscriptions" : '/sub-page'}><i class="fa  fa-dollar" aria-hidden="true"></i>My Subscription</Link></DropdownItem>
                                                            <DropdownItem>
                                                                <Link to="/bids_property"><i class="fa fa-bars" aria-hidden="true"></i>My Bids</Link></DropdownItem>
                                                        </>
                                                    }
                                                    <DropdownItem>

                                                        <Link to="" onClick={(e) => logout(e)}><i class="fa fa-sign-out" aria-hidden="true"></i>Logout</Link></DropdownItem>
                                                    {/* <Dropdown.Item href="#/action-2"><i class="fa fa-heart" aria-hidden="true"></i>
                                                        Favourite</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3"><i class="fa fa-bars" aria-hidden="true"></i>My Bids</Dropdown.Item>
                                                    <Dropdown.Item className="bottom-border" href="#/action-3"><i class="fa fa-sign-out" aria-hidden="true"></i>
                                                        Logout</Dropdown.Item> */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <button onClick={() => {
                                            setExpanded(false)
                                            Signin()
                                        }} className="btn fill-btn text-white margin-left" type="submit">Sign In</button>
                                        <button onClick={() => {
                                            setExpanded(false)
                                            Signup()
                                        }} className="btn fill-btn outline-btn text-white" type="submit">Sign Up</button>
                                    </>

                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    )
}
export default Header;