import { useEffect, useState } from "react";

const PropertyTimer = ({date}) => {
    // console.log(date);
    const [StartDateCountDown, setStartDateCountDown] = useState({
        "days": 0,
        "hours": 0,
        "minutes": 0,
        "seconds": 0
    })
    const [EndDateCountDown, setEndDateCountDown] = useState({
        "days": 0,
        "hours": 0,
        "minutes": 0,
        "seconds": 0
    })
    const StartDateTimer = (date) => {
        setInterval(() => {
            let AuctionStartDiff = new Date(date) - new Date()
            if (AuctionStartDiff) {
                var days = Math.floor(AuctionStartDiff / (1000 * 60 * 60 * 24));
                var hours = Math.floor((AuctionStartDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((AuctionStartDiff % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((AuctionStartDiff % (1000 * 60)) / 1000);
                setStartDateCountDown({ ...StartDateCountDown, days: days, hours: hours, minutes: minutes, seconds: seconds })
            }
        }, 1000)
    }

    // calculate diffrence between today and auction end date &time
    const EndDateTimer = (date) => {
        setInterval(() => {
            let AuctionEndDiff = new Date(date) - new Date()
            if (AuctionEndDiff) {

                var days = Math.floor(AuctionEndDiff / (1000 * 60 * 60 * 24));
                var hours = Math.floor((AuctionEndDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((AuctionEndDiff % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((AuctionEndDiff % (1000 * 60)) / 1000);

                setEndDateCountDown({ ...EndDateCountDown, days: days, hours: hours, minutes: minutes, seconds: seconds })
            }
        }, 1000)
    }

    useEffect(() => {
        if (new Date(date) > new Date()) {
            
            StartDateTimer(date)
        }
        else {
            EndDateTimer(date)
        }

    }, [date])
    return (
        <>
            {new Date(date) && new Date(date) > new Date() ?
                <>

                    <hr />
                    
                    <div className="service-property">
                        <div className="service-one">
                            <h6>
                                {StartDateCountDown.days}<span>Days</span>
                            </h6>
                        </div>
                        <div className="service-one">
                            <h6>
                                {StartDateCountDown.hours}<span>Hours</span>
                            </h6>
                        </div>
                        <div className="service-one">
                            <h6>
                                {StartDateCountDown.minutes}<span>Minutes</span>
                            </h6>
                        </div>
                        <div className="service-one">
                            <h6>
                                {StartDateCountDown.seconds}<span>Seconds</span>
                            </h6>
                        </div>
                    </div>
                    <hr />
                </>
                :
                <>

                    <hr />
                    
                    <div className="service-property">
                        <div className="service-one">
                            <h6>
                                {EndDateCountDown.days}<span>Days</span>
                            </h6>
                        </div>
                        <div className="service-one">
                            <h6>
                                {EndDateCountDown.hours}<span>Hours</span>
                            </h6>
                        </div>
                        <div className="service-one">
                            <h6>
                                {EndDateCountDown.minutes}<span>Minutes</span>
                            </h6>
                        </div>
                        <div className="service-one">
                            <h6>
                                {EndDateCountDown.seconds}<span>Seconds</span>
                            </h6>
                        </div>
                    </div>
                    <hr />
                </>
            }

        </>
    )
}
export default PropertyTimer;