import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import { media_url, SocialLogin, UserLogin } from '../Service/Service';
import { useForm } from 'react-hook-form';
import { context } from '../GlobalContext/GlobalContext';
import PopUp from '../common/SweetAlert';
import { toast } from 'react-toastify';
import AppleLogin from 'react-apple-login'
import { findRenderedDOMComponentWithClass } from 'react-dom/test-utils';



const Login = () => {
    const { register, handleSubmit, watch, formState: { errors }, setValue, control } = useForm();
    let globalData = useContext(context)
    let navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();


    const signup = () => {
        navigate('/signup')
    }

    const forgotpassword = () => {
        navigate('/forgotpassword')
    }

    let url=window.location;
    console.log("url>>>>>>>>>>>",url)

    //Google Login
    const onSignIn = (response) => {
        globalData.setLoader(true)
        console.log(response.credential);
        var userObj = jwt_decode(response.credential)
        console.log(userObj);
        let payload = {
            "social_id": userObj.sub,
            "social_type": "google",
            'device_id': globalData.fireBaseToken,
            'device_type': "web",
            "email": userObj.email,
            "name": userObj.name
        }
        SocialLogin(payload).then(response => {
            if (response.status === 200) {
                globalData.setLoader(false)
                console.log(response.data.data.token);
                globalData.setToken(response.data.data.token)
                localStorage.setItem("token", response.data.data.token)
                globalData.setName(response.data.data.name)
                localStorage.setItem('name', response.data.data.name)
                globalData.setImage(response.data.data.image !== "null" ? media_url + response.data.data.image : '')
                localStorage.setItem('image', response.data.data.image !== "null" ? media_url + response.data.data.image : '')
                globalData.setUserId(response.data.data.id)
                localStorage.setItem('userid', response.data.data.id)
                globalData.setEmail(response.data.data.email)
                localStorage.setItem('email', response.data.data.email)
                globalData.setUserType(response.data.data.user_type)
                localStorage.setItem('userType', response.data.data.user_type)
                globalData.setPlan(response.data.data.plan?.price)
                localStorage.setItem('plan', response.data.data.plan?.price)
                globalData.setPlanStartDate(response.data.data.plan?.start_date)
                localStorage.setItem('start_date', response.data.data.plan?.start_date)
                globalData.setPlanEndDate(response.data.data.plan?.end_date)
                localStorage.setItem('end_date', response.data.data.plan?.end_date)

                toast.success("User logged in successfully.")
                if (response.data.data.user_type === "seller") {
                    navigate(`/properties?type=my_property&myProp=sale&page=1`)
                }
                else {
                    if(searchParams.get("path")){
                        navigate(window.location.search.slice(6))
                    }else{
                        navigate('/')
                    }
                }

            }

        }).catch(err => {
            globalData.setLoader(false)
            toast.dismiss()
            if (err.response.status === 406) {

                navigate('/signup', { state: { "res": err.response.data.data } })
            }
            if (err.response.status === 400) {

                toast.info(err.response.data.message)
            }
        })
    }

    //Normal Login
    const onSubmit = (data) => {
        globalData.setLoader(true)
        data.device_id = globalData.fireBaseToken
        data.device_type = "web"
        UserLogin(data).then(res => {
            if (res.status === 200) {
                globalData.setLoader(false)
                globalData.setToken(res.data.data.token)
                localStorage.setItem("token", res.data.data.token)
                globalData.setName(res.data.data.name)
                globalData.setImage(res.data.data.image !== "null" ? media_url + res.data.data.image : '')
                localStorage.setItem('image', res.data.data.image !== "null" ? media_url + res.data.data.image : '')
                localStorage.setItem('name', res.data.data.name)
                globalData.setUserId(res.data.data.id)
                localStorage.setItem('userid', res.data.data.id)
                globalData.setEmail(res.data.data.email)
                localStorage.setItem('email', res.data.data.email)
                globalData.setUserType(res.data.data.user_type)
                localStorage.setItem('userType', res.data.data.user_type)
                globalData.setPlan(res.data.data.plan?.price)
                localStorage.setItem('plan', res.data.data.plan?.price)
                globalData.setPlanStartDate(res.data.data.plan?.start_date)
                localStorage.setItem('start_date', res.data.data.plan?.start_date)
                globalData.setPlanEndDate(res.data.data.plan?.end_date)
                localStorage.setItem('end_date', res.data.data.plan?.end_date)
                toast.success("User logged in successfully.")
                if (res.data.data.user_type === "seller") {
                    navigate(`/properties?type=my_property&myProp=sale&page=1`)
                }
                else {
                    if(searchParams.get("path")){
                        navigate(window.location.search.slice(6))
                    }else{
                        navigate('/')
                    }
                }

            }
        }).catch(err => {
            globalData.setLoader(false)
            toast.dismiss()
            if (err.response.status === 400) {
                toast.info(err.response.data.error)
            }
            if (err.response.status === 401) {
                globalData.setToken('')
                localStorage.clear()
                navigate('/login')
            }
        })
    }

    //Apple Login
    const appleLog = (data) => {
        console.log(data)
        var data1 = jwt_decode(data.authorization.id_token);
        console.log(data1);
        let payload = {
            "social_id": data1.sub,
            "social_type": "apple",
            "email": data1.email,
            'device_id': globalData.fireBaseToken,
            'device_type': "web",
            "name": ''
        }
        SocialLogin(payload).then(response => {
            if (response.status === 200) {
                globalData.setLoader(false)
                console.log(response.data.data.token);
                globalData.setToken(response.data.data.token)
                localStorage.setItem("token", response.data.data.token)
                globalData.setName(response.data.data.name)
                localStorage.setItem('name', response.data.data.name)
                globalData.setImage(response.data.data.image !== "null" ? media_url + response.data.data.image : '')
                localStorage.setItem('image', response.data.data.image !== "null" ? media_url + response.data.data.image : '')
                globalData.setUserId(response.data.data.id)
                localStorage.setItem('userid', response.data.data.id)
                globalData.setEmail(response.data.data.email)
                localStorage.setItem('email', response.data.data.email)
                globalData.setUserType(response.data.data.user_type)
                localStorage.setItem('userType', response.data.data.user_type)
                globalData.setPlan(response.data.data.plan?.price)
                localStorage.setItem('plan', response.data.data.plan?.price)
                globalData.setPlanStartDate(response.data.data.plan?.start_date)
                localStorage.setItem('start_date', response.data.data.plan?.start_date)
                globalData.setPlanEndDate(response.data.data.plan?.end_date)
                localStorage.setItem('end_date', response.data.data.plan?.end_date)
                toast.success("User logged in successfully.")
                if (response.data.data.user_type === "seller") {
                    navigate(`/properties?type=${response.data.user_type === "customer" ? "sale" : "my_property"}&page=1`)
                }
                else {

                    navigate('/')
                }

            }

        }).catch(err => {
            globalData.setLoader(false)
            toast.dismiss()
            if (err.response.status === 406) {

                navigate('/signup', { state: { "res": err.response.data.data } })
            }
            if (err.response.status === 400) {

                toast.info(err.response.data.message)
            }
        })

    }

    useEffect(() => {

        window?.google?.accounts?.id?.initialize({
            client_id: "430315170817-3hordo2ttth6ptb8a2bh9r022kr9pu08.apps.googleusercontent.com",
            callback: onSignIn
        })

        window.google.accounts.id.renderButton(
            document.getElementById("g-signin2"),
            { theme: "outline", size: "large" }

        )

        console.log("globalData?.token",globalData?.token);
        if(searchParams.get("path") && localStorage.getItem("token")){
            // let path = window.location.search.split("=")
            // console.log("ffffffffffff",path[1]+"="+path[2]);
            // window.location.href =path[1]+"="+path[2]
            navigate(window.location.search.slice(6))

        }
        
        

        
    }, [])


    return (
        <>
            <section className="login-area">
                <div className="container">
                    <div className="login-box-area">
                        <div className="row justify-content-center">
                            <aside className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                                <div className="login-box">
                                    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                                        <h2 className="text-center">Sign In</h2>
                                        <p className="text-center mb-2">Use registered email address and password.</p>
                                        <div className="my-4">
                                            <label className="form-label">Email address</label>
                                            <input type="email" className="form-control" id="" placeholder="eg. adamsmith@gmail.com" {...register("email", { required: "Please enter email.", setValueAs: v => v.toLowerCase() })} />
                                            {errors.email && <span style={{ color: "red" }}>{errors.email.message}</span>}
                                        </div>
                                        <div className="my-4">
                                            <label className="form-label">Password</label>
                                            <input type="password" className="form-control" id="" placeholder="eg.  **************" {...register("password", { required: "Please enter password." })} />
                                            {errors.password && <span style={{ color: "red" }}>{errors.password.message}</span>}
                                        </div>
                                        <button className="btn sign-btn fill-btn w-100 ms-0 my-3 text-white">Sign In</button>
                                        <p className="text-center mt-2 mb-4">Or Login with</p>

                                        <div className="social-btn d-flex justify-content-center mt-3">

                                            <div className="btn position_btn me-2" >
                                                <img src={require('../assets/images/google.svg').default} alt="goggle" />

                                                Continue with Google
                                                <div id="g-signin2"></div>
                                            </div>
                                            <button className="btn">
                                                <img src={require('../assets/images/apple.svg').default} alt="goggle" />
                                                Continue with Apple
                                                <AppleLogin clientId="com.app.RealZon.sid" redirectURI="https://realzon.net/" scope='name email' responseType='code' usePopup={true} responseMode="query" callback={(data) => appleLog(data)} />

                                            </button>
                                        </div>
                                        <p className="forgot d-block text-center my-4" onClick={forgotpassword}>Forgot Password?</p>
                                    </form>
                                    <hr />
                                    <p className="text-center py-2">Don’t have account?<span className="signup" onClick={signup}> Sign Up</span></p>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Login;