import { useContext, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { context } from "../GlobalContext/GlobalContext";
import { GetMyProfile, media_url, UpdateProfile } from "../Service/Service";
import Autocomplete from "react-google-autocomplete";
import SuccessModal from "../common/SuccessModal";
import { toast } from "react-toastify";
import profile_image from '../assets/images/update-profile-user.png'
const Update_profile = () => {
    let navigate = useNavigate();
    let loc = useLocation()
    const globalData = useContext(context)
    const [imgUrl, setImgUrl] = useState('')
    const [show, setShow] = useState(false);
    const [userType, setUserType] = useState('')
    const { register, handleSubmit, setValue, formState: { errors }, control, setFocus } = useForm()
    const handleClose = () => { setShow(false); navigate('/my_profile') }
    const handleShow = () => {
        setShow(true)

    }

    const onSubmit = data => {
        console.log(data);
        globalData.setLoader(true)
        const payload = new FormData()
        if (data.image.length > 0) {
            payload.append("image", data.image[0])
        }
        payload.append("name", data.name)
        payload.append("email", data.email)
        payload.append("phone_number", data.contact)
        payload.append("address", data.address?.geometry?.location?.lat() ? data.address?.formatted_address : data.address)
        payload.append("latitude", data.address?.geometry ? data.address?.geometry?.location?.lat() : '')
        payload.append("longitude", data.address?.geometry ? data.address?.geometry?.location?.lng() : '')
        payload.append("license_number", data.licNumber)
        payload.append("company_name", data.company)
        payload.append('about',data.description)
        if(globalData.userType==='agent'){
            data.address?.address_components?.map((address) =>
                    (address.types[0] === 'locality' || address.types[0] === 'sublocality') ? payload.append('city', address.long_name) : address.types[0] === "administrative_area_level_1" ? payload.append('state', address.long_name) : ''
                )
        }

        UpdateProfile(loc.state.id, globalData.token, payload).then(res => {
            if (res.status === 200) {
                globalData.setLoader(false)
                if (res.data.data.image !== null) {
                    globalData.setImage(media_url + res.data.data.image)
                    localStorage.setItem('image', media_url + res.data.data.image)
                }

                handleShow()
            }
        }).catch(err => {
            globalData.setLoader(false)
            toast.error(err.response.data.message)
        })
    }
    const getProfile = () => {
        globalData.setLoader(true)
        GetMyProfile(loc.state.id, globalData.token).then(res => {
            if (res.status === 200) {
                globalData.setLoader(false)
                setUserType(res.data.data.user_type)
                if (res.data.data.image != null) {
                    setImgUrl(media_url + res.data.data.image)
                }
                setValue('name', res.data.data.name)
                setValue("email", res.data.data.email)
                setValue("contact", res.data.data.phone_number)
                setValue("address", res.data.data.address)
                setValue("licNumber", res.data.data.license_number)
                setValue("company", res.data.data.company_name)
                setValue('description',res.data.data.about)
            }
        }).catch(err => {
            globalData.setLoader(false)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                globalData.setToken('')
                localStorage.clear()
                toast.info("You session has expired please login again!.")
                navigate('/login')
            }
        })
    }
    useEffect(() => {
        console.log(userType);
        
                getProfile()
               
        
    }, [globalData.token])
    return (
        <>
            <section className="login-area forgot-area">
                <div className="container">
                    <div className="login-box-area">
                        <div className="row justify-content-center">
                            <aside className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                                <div className="login-box">
                                    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                                        <h2 className="text-center">Update Profile</h2>
                                        <div className="user_update-profile">
                                            <Form.Control type="file"  {...register('image', { onChange: e => setImgUrl(URL.createObjectURL(e.target.files[0])) })} />
                                            <img src={imgUrl ? imgUrl : profile_image} alt="" />
                                            <div className="edit-profile">
                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="my-4 pt-3">
                                            <label className="form-label">Name</label>
                                            <input type="text" className="form-control" id="" placeholder="Enter Name" {...register("name", { required: "Name is required." })} />
                                            {errors.name && <span style={{ color: "red" }}>{errors.name.message}</span>}
                                        </div>
                                        <div className="my-4">
                                            <label className="form-label">Email Address</label>
                                            <input type="text" disabled className="form-control" id="" placeholder="Enter Email Address" {...register("email", { required: "Email is required." })} />
                                            {errors.email && <span style={{ color: "red" }}>{errors.email.message}</span>}
                                        </div>
                                        {(userType === "agent" || userType === "seller") &&
                                            <>
                                                <div className="my-4">
                                                    <label className="form-label">Contact</label>
                                                    <input type="text" className="form-control" id="" placeholder="Enter Contact Number" {...register("contact", { required: "Phone number is required." })} />
                                                    {errors.contact && <span style={{ color: "red" }}>{errors.contact.message}</span>}
                                                </div>

                                                <Controller

                                                    render={({ field: { onChange, name, ref, value }, fieldState: { error } }) => {
                                                        return (
                                                            <div className="my-4">
                                                                <label className="form-label">
                                                                    Address
                                                                </label>
                                                                <Autocomplete ref={ref} apiKey="AIzaSyCTg77HJ0mIEmvnMn_lOHbd_x8QEk9zD0g" defaultValue={value} className="form-control" placeholder="eg. 857 Pineknoll St. Fort Lauderdale, FL 33308" options={{ types: ["geocode", "establishment"], }} onChange={() => setValue('address', '')} onPlaceSelected={(place) => onChange(place)} />

                                                                {error?.message && <span style={{ color: "red" }}>{error.message}</span>}
                                                            </div>
                                                        )
                                                    }}
                                                    control={control}
                                                    name="address"
                                                    rules={{ required: "Please select address." }}
                                                />
                                            </>
                                        }
                                        {userType === "agent" &&
                                            <>
                                                <div className="my-4">
                                                    <label className="form-label"> License Number</label>
                                                    <input type="text" className="form-control" id="" placeholder="Enter License Number" {...register("licNumber", { required: "License Number is required." })} />
                                                    {errors.licNumber && <span style={{ color: "red" }}>{errors.licNumber.message}</span>}
                                                </div>
                                                <div className="my-4">
                                                    <label className="form-label">Company</label>
                                                    <input type="text" className="form-control" id="" placeholder="Enter Company" {...register("company", { required: "Company is required." })} />
                                                    {errors.company && <span style={{ color: "red" }}>{errors.company.message}</span>}
                                                </div>
                                            </>}
                                            {(userType=== "seller" || userType=== "agent") &&
                                            <div className="my-3">
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlTextarea1"
                                                >
                                                    <Form.Label>About</Form.Label>
                                                    <Form.Control as="textarea" rows={3} maxLength={250} {...register('description', { required: "About field can't be empty." })} />
                                                </Form.Group>
                                                {errors.description && <span style={{ color: "red" }}>{errors.description.message}</span>}
                                            </div>
                                        }

                                        <button className="btn sign-btn fill-btn w-100 ms-0 mt-3 mb-4 text-white" >update profile</button>
                                        <button onClick={() => navigate('/my_profile')} className="subscription-btn mb-5 mt-0 w-100">
                                            <p>back to profile</p>
                                        </button>
                                    </form>

                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <SuccessModal show={show} onHide={() => handleClose()} message="Profile updated successfully." />
            {/* <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="text-center otp_popup_verify pt-4">
                        <img src={require('../assets/images/successful.svg').default} alt="" />
                        <h5 className="mt-4">Successfully</h5>
                        <p>Password reset successfully</p>
                        <button className="btn okbtn text-white mb-4 mt-2" onClick={handleClose}>OK</button>
                    </div>
                </Modal.Body>
            </Modal> */}
        </>
    )
}
export default Update_profile;