const Loader = () => {
    return (
        <div className="bg-cls">
            <div className="loader-box">
                <div class="circle-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}
export default Loader;