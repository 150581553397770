import { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { context } from "../GlobalContext/GlobalContext";
import { GetMyProfile, media_url, ShareProperty } from "../Service/Service";
import img from "../assets/images/profile-picture.png";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";

const My_profile = () => {
    const [show, setShow] = useState(false);
    const [share_url, setShare_url] = useState('');
    const [showshare, setShowshare] = useState(false);
    const [copy, setCopy] = useState(false);
    const handleCloseshare = () => setShowshare(false);
    const globalData = useContext(context)
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);
    const [myProfile, setMyProfile] = useState({})
    let navigate = useNavigate();

    const getProfile = () => {
        globalData.setLoader(true)
        GetMyProfile(globalData.userId, globalData.token).then(res => {
            if (res.status === 200) {
                globalData.setLoader(false)
                setMyProfile(res.data.data)
            }
        }).catch(err => {
            globalData.setLoader(false)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                globalData.setToken('')
                localStorage.clear()
                toast.info("You session has expired please login again!.")
                navigate('/login')
            }
        })
    }

    useEffect(() => {

        let url=window.location;
        console.log("url.........",url)

        {
            globalData.token &&
                getProfile()
                
        }
        let data=globalData?.userId;
        console.log("data........",data)
        shareAgentprofile(data)
        //eslint-disable-next-line
    }, [globalData.token])

    function shareAgentprofile(id){
        const params = {
          "dynamicLinkInfo":
          {
            "domainUriPrefix":"https://realzonapp.page.link",
          "androidInfo":{
            "androidPackageName":"com.app.realzon"
          }
            ,
          "iosInfo":{
            "iosBundleId":"com.app.RealZon",
            "iosAppStoreId":"1636682391"
          },
          "link":`https://api.realzon.net/api/share_agent?agent_id=${id}`
        }}
        ShareProperty(params).then(res=>{
          setShare_url(res?.data?.shortLink)
        //   setShowshare(true)
        }).catch(err=>{
          console.log("error>", err)
        })
      }
      // console.log("bbbbbbbbbbbbb",globalData.userType)

    return (
        <>
            <section className="login-area forgot-area">
                <div className="container">
                    <div className="login-box-area">
                        <div className="row justify-content-center">
                            <aside className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                                <div className="login-box login-form bids-form">
                                    <h2 className="text-center mb-5">My Profile</h2>
                                    <div className="share-propty">
                      
                  </div>
                                    {myProfile.user_type === "agent" &&
                                        <div className="user-profile">
                                            <img src={media_url + myProfile.image || img} alt="" />
                                        </div>}
                                      {globalData?.userType=="agent" &&  <h6 className="text-center share-bt" onClick={()=>setShowshare(true)}> Share <i class="fa fa-share" aria-hidden="true" ></i></h6>}

                                    <div className="bid-page-area">
                                        <div className="area-bid-one">
                                            <p>Name</p>
                                            <p>Email Address</p>
                                            {(myProfile.user_type === "agent" || myProfile.user_type === "seller") &&
                                                <>
                                                    <p>Contact</p>
                                                    <p>Address</p>
                                                </>
                                            }
                                            {myProfile.user_type === 'agent' &&
                                                <>
                                                    <p>License Number</p>
                                                    <p>Company</p>
                                                </>}
                                        </div>
                                        <div className="text-right text-black">
                                            <p>{myProfile.name}</p>
                                            <p>{myProfile.email}</p>
                                            {(myProfile.user_type === "agent" || myProfile.user_type === "seller") &&
                                                <>
                                                    <p>{myProfile.phone_number}</p>
                                                    <p>{myProfile.city},{myProfile.state}</p>
                                                </>
                                            }
                                            {myProfile.user_type === 'agent' &&
                                                <>
                                                    <p>{myProfile.license_number}</p>
                                                    <p>{myProfile.company_name}</p>
                                                </>}
                                        </div>
                                    </div>
                                    <button onClick={() => navigate('/change_password')} className="password-btn w-100">
                                        <p>change password</p>
                                    </button>
                                    {/* <button className="subscription-btn m-0 w-100">
                                        <p>manage subscription plan</p>
                                    </button> */}
                                    <p onClick={() => navigate('/update_profile', { state: { "id": myProfile.id } })} className="btn sign-btn fill-btn w-100 ms-0 mt-3 mb-4 text-white">
                                        edit profile
                                    </p>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="text-center otp_popup_verify pt-4">
                        <img
                            src={require("../assets/images/successful.svg").default}
                            alt=""
                        />
                        <h5 className="mt-4">Successfully</h5>
                        <p>Password reset successfully</p>
                        <button
                            className="btn okbtn text-white mb-4 mt-2"
                            onClick={handleClose}
                        >
                            OK
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
        show={showshare}
        onHide={handleCloseshare}
        animation={false}
        centered
        className="text-center meta-mask-modal share-modal-new"
      >
      <Modal.Body className="share-property-modal">
          <h3>My Profile</h3>
          {copy ? (
            <>
              <span style={{ fontSize: "12px", color: "#ff7750" }}>
                Link Copied
              </span>
              <br></br>
            </>
          ) : (
            <>
              <span style={{ fontSize: "10px" }}></span>
              <br></br>
            </>
          )}
          <input
            type="text"
            value={share_url}
            disabled={true}
            style={{ fontSize: "12px" }}
          />
          <CopyToClipboard text={share_url} onCopy={() => setCopy(true)}>
            <i
              className="fa fa-clipboard Demo__some-network__share-button"
              title="Copy to clipboard"
              aria-hidden="true"
              style={{ fontSize: "19px", cursor: "pointer" }}
            ></i>
          </CopyToClipboard>
          <br></br>
          <br></br>
          <Button
            type="button"
            className="ok-bt"
            onClick={() => setShowshare(false)}
          >
            Ok
          </Button>
        </Modal.Body>
          
      </Modal>
        </>
    );
};
export default My_profile;
