import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import About from "../component/About";
import Agent_listing from "../component/Agent_listing";
import Agent_profile from "../component/Agent_profile";
import Bid from "../component/Bid";
import Bids_details from "../component/Bids_details";
import Bid_property from "../component/Bids_property";
import Add_listing from "../component/Broker_interface/Add_listing";
import Broker_subscription from "../component/Broker_interface/Subscriptions";
import Singupbroker from "../component/Broker_interface/Signup_broker";
import Update_listing from "../component/Broker_interface/Update_listing";
import Change_password from "../component/Change_password";
import Contactus from "../component/Contactus";
import Createpassword from "../component/Createpassword";
import Faq from "../component/Faq";
import Favorite from "../component/Favorite";
import Features from "../component/Features";
import Forgotpassword from "../component/Forgotpassword";
import Home from "../component/Home";
import Login from "../component/Login";
import My_profile from "../component/My_profile";
import Otp from "../component/Otp";
import Payment_process from "../component/Payment_process";
import Privacypolicy from "../component/Privacy_policy";
import Properties from "../component/Properties";
import Property_online_auction from "../component/Property_online_auction";
import Property_person_auction from "../component/Property_person_auction";
import Property_regular from "../component/Property_regular";
import Add_property from "../component/Seller_interface/Add_property";
import Singupseller from "../component/Seller_interface/Signup_seller";
import Update_property from "../component/Seller_interface/Update_property";
import Singup from "../component/Signup";
import Subscription from "../component/Subscription";
import Terms from "../component/Terms_condition";
import Update_profile from "../component/Update_profile";
import { context } from "../GlobalContext/GlobalContext";
import Subscriptions from "../component/Broker_interface/Subscriptions";
import BuySubscription from "../component/BuySubscription";
import SubPage from "../component/AfterLoginSub";
import SearchList from "../component/SearchList";
import MarketReport from "../component/MarketReport";
import Mortage_company from "../component/Seller_interface/Mortage_company";
import Attorney from "../component/Seller_interface/Attorney";

const Routing = () => {
    const globalData = useContext(context)
    // console.log(globalData.token);
    return (
        <>

            <Routes>

                <>

                    <Route path="/" element={<Home />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route path="/signup" element={<Singup />} />
                    <Route path="/forgotpassword" element={<Forgotpassword />} />
                    <Route path="/otp" element={<Otp />} />
                    <Route path="/createpassword" element={<Createpassword />} />
                    
                    <Route path="/property_regular" element={<Property_regular />} />
                    <Route path="/property_person_auction" element={<Property_person_auction />} />
                    <Route path="/property_online_auction" element={<Property_online_auction />} />
                    <Route path="/bid" element={<Bid />} />
                    <Route path="/payment_process" element={<Payment_process />} />
                    <Route path="/favorite" element={<Favorite />} />
                    <Route path="/bids_property" element={<Bid_property />} />
                    <Route path="/bids_details" element={<Bids_details />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/features" element={<Features />} />
                    <Route path="/contactus" element={<Contactus />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/privacy" element={<Privacypolicy />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/my_profile" element={<My_profile />} />
                    <Route path="/change_password" element={<Change_password />} />
                    <Route path="/update_profile" element={<Update_profile />} />
                    <Route path="/agent_profile" element={<Agent_profile />} />
                    <Route path="/agent_listing" element={<Agent_listing />} />
                    <Route path="/mortgage_company" element={<Mortage_company />} />
                    <Route path="/attorney" element ={<Attorney />} />
                    <Route path="/properties" element={<Properties />} />
                    {/* <Route path="/singupbroker" element={<Singupbroker />} /> */}
                    <Route path='/subscriptions'element={<Subscriptions/>}/>
                    <Route path='/sub-page'element={<SubPage/>}/>
                    <Route path="/buy_subscription" element={<BuySubscription />} />
                    <Route path="/add_listing" element={<Add_listing />} />
                    <Route path="/update_listing" element={<Update_listing />} />
                    {/* <Route path="/signupseller" element={<Singupseller />} /> */}
                    <Route path="/add_property" element={<Add_property />} />
                    <Route path="/update_property" element={<Update_property />} />
                    <Route path="/search-list" element={<SearchList />} />
                    <Route path="/market-report" element={<MarketReport />} />
                </>
                {/* :
                    <Route path="*" element={<Login />}/>  */}

            </Routes>

        </>
    )
}
export default Routing;