import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { context } from "../GlobalContext/GlobalContext";
import { GetMyProfile, media_url, ShareProperty } from "../Service/Service";
import CopyToClipboard from "react-copy-to-clipboard";
import profile from '../assets/images/profile-picture.png';
const Agent_profile = () => {
    const [query] = useSearchParams()
    let globalData = useContext(context)
    const navigate = useNavigate()
    const [showshare, setShowshare] = useState(false);
    const [agentDetails, setAgentDetails] = useState([])
    const handleCloseshare = () => setShowshare(false);
    const [share_url, setShare_url] = useState('');
    const [copy, setCopy] = useState(false);
    const [id, setId]=useState('')

    const GetAgentProfile = () => {
        globalData.setLoader(true)
      
        GetMyProfile(query.get('id'), globalData.token).then(response => {
            if (response.status === 200) {
                globalData.setLoader(false)
                setAgentDetails(response.data.data)
            }
        }).catch(err => {
            globalData.setLoader(false)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                globalData.setToken('')
                localStorage.clear()
                toast.info("You session has expired please login again!.")
                navigate('/login?path='+window?.location?.pathname+ window?.location?.search)
            }
        })
    }

    useEffect(() => {
      globalData.setLoader(true)
      console.log("...........",window?.location?.pathname+ window?.location?.search)
        {
            globalData.token && GetAgentProfile()

        }
       

        if(query.get('id')== globalData?.userId){
            navigate("/my_profile");
        }
        let data=query.get('id');
        shareAgentprofile(data)
        if(!globalData.token){
          window.location.href="/login?path="+window?.location?.pathname+ window?.location?.search;
        }
    }, [globalData.token])


    function shareAgentprofile(id){
        const params = {
          "dynamicLinkInfo":
          {
            "domainUriPrefix":"https://realzonapp.page.link",
          "androidInfo":{
            "androidPackageName":"com.app.realzon"
          }
            ,
          "iosInfo":{
            "iosBundleId":"com.app.RealZon",
            "iosAppStoreId":"1636682391"
          },
          "link":`https://api.realzon.net/api/share_agent?agent_id=${id}&profile_type=${"agent"}`
        }}
        ShareProperty(params).then(res=>{
          setShare_url(res?.data?.shortLink)
          console.log("res?.data?.shortLink===>",res?.data?.shortLink);
        //   setShowshare(true)
        }).catch(err=>{
          console.log("error>", err)
        })
      }

    return (
        <>
            <section class="login-area forgot-area">
                <div class="container">
                    <div class="login-box-area">
                        <div class="row justify-content-center">
                            <aside class="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                                <div class="login-box login-form bids-form">
                                    <h2 className="text-center mb-5">Agent Profile</h2>
                                    <div className="user-profile" >
                                        <img src={agentDetails.image?media_url+agentDetails.image:profile} alt="" />
                                    </div>
                                    <h6 className="text-center share-bt" onClick={() =>setShowshare(true)}>
                        Share <i class="fa fa-share" aria-hidden="true"></i></h6>
                                    <div className="bid-page-area">
                                        <div className="bid-page-content">
                                          <p>Name</p>
                                          <p>{agentDetails.name}</p>
                                        </div>
                                        <div className="bid-page-content">
                                          <p>Email Address</p>
                                          <p>{agentDetails.email}</p>
                                        </div>
                                        <div className="bid-page-content">
                                            <p>Address</p>
                                            <p>{agentDetails.city},{agentDetails.state}</p>
                                        </div>
                                        <div className="bid-page-content">
                                            <p>License Number</p>
                                            <p>{agentDetails.license_number}</p>
                                        </div>
                                        <div className="bid-page-content">
                                            <p>Company</p>
                                            <p>{agentDetails.company_name}</p>
                                        </div>
                                        <div className="bid-page-content">
                                            <p>Contact number</p>
                                            <p>{agentDetails.phone_number}</p>
                                        </div>
                                        </div>
                                        
                                    {/* <button className="password-btn mb-5 w-100">
                                        <p>back to profile</p>
                                    </button> */}

                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
        show={showshare}
        onHide={handleCloseshare}
        animation={false}
        centered
        className="text-center meta-mask-modal share-modal-new"
      >
      <Modal.Body className="share-property-modal">
          <h3>Agent Profile</h3>
          {copy ? (
            <>
              <span style={{ fontSize: "12px", color: "#ff7750" }}>
                Link Copied
              </span>
              <br></br>
            </>
          ) : (
            <>
              <span style={{ fontSize: "10px" }}></span>
              <br></br>
            </>
          )}
          <input
            type="text"
            value={share_url}
            disabled={true}
            style={{ fontSize: "12px" }}
          />
          <CopyToClipboard text={share_url} onCopy={() => setCopy(true)}>
            <i
              className="fa fa-clipboard Demo__some-network__share-button"
              title="Copy to clipboard"
              aria-hidden="true"
              style={{ fontSize: "19px", cursor: "pointer" }}
            ></i>
          </CopyToClipboard>
          <br></br>
          <br></br>
          <Button
            type="button"
            className="ok-bt"
            onClick={() => setShowshare(false)}
          >
            Ok
          </Button>
        </Modal.Body>
          
      </Modal>


        </>
    );
};
export default Agent_profile;
