import { useContext, useEffect, useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { context } from "../GlobalContext/GlobalContext";
import { cancelSubscription, GetMyProfile, media_url } from "../Service/Service";
import profile from '../assets/images/profile-picture.png';
import moment from "moment";
import Swal from "sweetalert2";

const SubPage = () => {

    const globalData = useContext(context)
    const navigate = useNavigate()

    const cancelSub = () => {
        Swal.fire({
            title: `Are you sure you want to cancel your subscription plan?`,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#11dfed',
            cancelButtonColor: '#d33',

        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const payload = {
                    "plan_type": Number(globalData.plan) === 19.99 ? "Basic" : Number(globalData.plan) === 219.99 ? "Business" : Number(globalData.plan) === 399.99 ? "Pro" : ''
                }
                cancelSubscription(globalData.token, payload).then(res => {
                    if (res.status === 200) {
                        globalData.setPlan(null)
                        localStorage.setItem("plan", null)
                        toast.info("Your subscription plan has been cancelled successfully..")
                        navigate('/subscriptions')
                    }
                })
            } else if (result.isDenied) {
                Swal.close()
            }
        })

    }
    console.log(globalData.plan)
    return (
        <>
            <section class="login-area forgot-area">
                <div class="container">
                    <div class="login-box-area">
                        <div class="row justify-content-center">
                            <aside class="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                                <div class="login-box login-form bids-form">
                                    <h2 className="text-center mb-5">My subscription plan</h2>
                                    <div className="user-profile">
                                        {/* <img src={agentDetails.image ? media_url + agentDetails.image : profile} alt="" /> */}
                                    </div>
                                    <div className="bid-page-area">


                                        <Col lg={12} md={12}>
                                            <div className="basic-plan-box" id='card1'>
                                                <i class="fa fa-check select-check" aria-hidden="true"></i>
                                                {Number(globalData.plan).toLocaleString("en-US") === '19.99' ?
                                                    <div className="basic-payment">
                                                        <div className="plan-text-picture">
                                                            <div className="plan-text">
                                                                <p>Basic</p>
                                                                <h3>
                                                                    $ <b>29.99</b> /Month
                                                                </h3>
                                                                <div className="special-pricing">
                                                                    <p><span>SPECIAL PRICING</span> Until 12/31/2022</p>
                                                                    <h3>
                                                                        $ <b>19.99</b> /Month
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div className="diomand-picture">
                                                                <img
                                                                    src={require("../assets/images/gem.png")}
                                                                    alt="img"
                                                                ></img>
                                                            </div>
                                                        </div>
                                                        <hr></hr>
                                                        <ul className="plans-description">
                                                            <li>
                                                                <img
                                                                    src={require("../assets/images/check.png")}
                                                                    alt="img"
                                                                ></img>{" "}
                                                                Only information about online auctions will be included in the subscription plan.
                                                            </li>
                                                            <li>
                                                                <img
                                                                    src={require("../assets/images/check.png")}
                                                                    alt="img"
                                                                ></img>{" "}
                                                                Add bid on property detail included in the subscription plan.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    :
                                                    Number(globalData.plan).toLocaleString("en-US") === '219.99' ?
                                                        <div className="basic-payment">
                                                            <div className="plan-text-picture">
                                                                <div className="plan-text">
                                                                    <p>Business</p>
                                                                    <h3>
                                                                        $ <b>349.99</b> /Year
                                                                    </h3>
                                                                    <div className="special-pricing">
                                                                        <p><span>SPECIAL PRICING</span> Until 12/31/2022</p>
                                                                        <h3>
                                                                            $ <b>219.99</b> /Year
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                                <div className="diomand-picture">
                                                                    <img
                                                                        src={require("../assets/images/gem.png")}
                                                                        alt="img"
                                                                    ></img>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <ul className="plans-description">
                                                                <li>
                                                                    <img
                                                                        src={require("../assets/images/check.png")}
                                                                        alt="img"
                                                                    ></img>{" "}
                                                                    Only information about online auctions will be included in the subscription plan.
                                                                </li>
                                                                <li>
                                                                    <img
                                                                        src={require("../assets/images/check.png")}
                                                                        alt="img"
                                                                    ></img>{" "}
                                                                    Add bid on property detail included in the subscription plan.
                                                                </li>
                                                            </ul>
                                                        </div> :
                                                        <div className="basic-payment">
                                                            <div>
                                                                <div className="plan-text-picture">
                                                                    <div className="plan-text">
                                                                        <p>Pro</p>
                                                                        <h3 className="subscription-plan-price">
                                                                            $ <b>399.99</b> /Year
                                                                        </h3>
                                                                    </div>
                                                                    <div className="diomand-picture">
                                                                        <img
                                                                            src={require("../assets/images/gem.png")}
                                                                            alt="img"
                                                                        ></img>
                                                                    </div>
                                                                </div>
                                                                <hr></hr>
                                                                <ul className="plans-description">
                                                                    <li>
                                                                        <img
                                                                            src={require("../assets/images/check.png")}
                                                                            alt="img"
                                                                        ></img>{" "}
                                                                        Only information about online auctions will be included in the subscription plan.
                                                                    </li>
                                                                    <li>
                                                                        <img
                                                                            src={require("../assets/images/check.png")}
                                                                            alt="img"
                                                                        ></img>{" "}
                                                                        Add bid on property detail included in the subscription plan.
                                                                    </li>
                                                                    <li>
                                                                        <img
                                                                            src={require("../assets/images/check.png")}
                                                                            alt="img"
                                                                        ></img>{" "}
                                                                        Agents wish to be at the top of the list.
                                                                    </li>
                                                                    {/* <Button
                      type="button"
                      className="choose-plan-bt"
                      variant="unset"
                    >
                      choose plan
                    </Button> */}
                                                                </ul>
                                                            </div>
                                                        </div>}

                                                <hr></hr>
                                                {/* <ul className="plans-description">
                                                    <li>
                                                        <img
                                                            src={require("../assets/images/check.png")}
                                                            alt="img"
                                                        ></img>{" "}
                                                        Only information about online auctions will be included in the subscription plan.
                                                    </li>
                                                    <li>
                                                        <img
                                                            src={require("../assets/images/check.png")}
                                                            alt="img"
                                                        ></img>{" "}
                                                        Agent wish to be at the top of the list.
                                                    </li>
                                                </ul> */}
                                            </div>

                                            <div className="date-time-section mt-4">
                                                <div className="date-section">
                                                    <p>Purchase Date & Time</p >
                                                </div>
                                                <div className="time-section">
                                                    <p>{moment(globalData.planStartDate).format('MM/DD/yyyy')} | {moment(globalData.planStartDate).format("HH:mm a")}</p>
                                                </div>
                                            </div>

                                            <div className="date-time-section">
                                                <div className="date-section">
                                                    <p>Expiry Date</p >
                                                </div>
                                                <div className="time-section">
                                                    <p>{moment(globalData.planEndDate).format('MM/DD/yyyy')}</p>
                                                </div>
                                            </div>
                                            <p className="btn sign-btn fill-btn w-100 ms-0 mt-3 mb-4 text-white" onClick={cancelSub}>
                                                Cancel plan
                                            </p>

                                            {/* <button className="password-btn change-plan-btn w-100" onClick={() => navigate('/subscriptions')}>
                                                <p>change plan</p>
                                            </button> */}
                                            {/* <button className="subscription-btn m-0 w-100">
                                        <p>manage subscription plan</p>
                                    </button> */}

                                        </Col>
                                    </div>
                                    {/* <button className="password-btn mb-5 w-100">
                                        <p>back to profile</p>
                                    </button> */}

                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SubPage;