import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ForgotPass, VerifyOtp } from "../Service/Service";
import { context } from "../GlobalContext/GlobalContext";
import { toast } from "react-toastify";
const Otp = () => {
    const {control,handleSubmit ,formState:{errors}}=useForm()
    let globalData=useContext(context)
    let loc = useLocation()
    const [otp, set_otp] = useState('')
    const [show, setShow] = useState(false);
     
    const handleChange = (otp) => set_otp(otp);
    const handleClose = () => { setShow(false); navigate('/createpassword',{state:{"email":loc.state.email}}) }

    const handleShow = () => {
        setShow(true);
        // navigate('/createpassword')
    }

    let navigate = useNavigate();

   

    const onSubmit=data=>{
      data["email"]=loc.state.email
    
        VerifyOtp(data).then(res=>{
            if(res.status===200){
                handleShow()
            }
        }).catch(err=>{
            toast.dismiss()
            if(err.response.status===400){
                toast.error("You have entered incorrect otp.")
            }
        })
    }
    return (
        <>
            <section className="login-area otp-area">
                <div className="container">
                    <div className="login-box-area">
                        <div className="row justify-content-center">
                            <aside className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                                <div className="login-box">
                                    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                                        <h2 className="text-center">OTP verification</h2>
                                        <p className="text-center my-2 otp_text">Enter the OTP sent to <span>{loc.state.email}</span></p>
                                        <Controller
                                            control={control}
                                            name="otp"
                                            rules={{ required: "Please enter OTP.." }}
                                            render={({ field: { onChange, value }}) => (
                                                <div className="my-4 pt-3">
                                                    <label  className="form-label">Enter one time password</label>
                                                    <OtpInput
                                                        value={value}
                                                        onChange={(e)=>onChange(e)}
                                                        numInputs={4}
                                                        containerStyle="otp"
                                                        isInputNum="true"
                                                        hasErrored={true}
                                                        shouldAutoFocus
                                                    />
                                                    {errors.otp && <span style={{color:"red"}}>{errors.otp.message}</span>}
                                                    <p className="text-center pt-3 mb-0 flex-p">
                                                        Don’t you receive OTP ? <span className="signup ms-1" onClick={()=>{ForgotPass({"email":loc.state.email}); toast.info("OTP resent successfully to registered email.")}}> Resend OTP</span>
                                                    </p>
                                                </div>)}
                                        />
                                        <button className="btn sign-btn fill-btn w-100 ms-0 mt-1 mb-4 text-white" >verify otp</button>
                                    </form>
                                    <hr />
                                    <p className="text-center py-2 flex-p">
                                        <img src={require('../assets/images/back.svg').default} alt="back" className="me-1" />
                                        Back to <span className="signup ms-1" onClick={()=>navigate('/forgotpassword')}> Forgot password ?</span>
                                    </p>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="text-center otp_popup_verify">
                        <img src={require('../assets/images/verify.svg').default} alt="" />
                        <h5 className="mt-4">Verified</h5>
                        <p>Your email address has been verified.</p>
                        <button className="btn okbtn text-white mb-4 mt-2" onClick={handleClose}>OK</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Otp;