import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const Privacypolicy = () => {
  return (
    <>
      <section className="hero-banner  about-bg">
        <Container>
          <p className="property-banner-heading">
            <img src={require("../assets/images/play.png")}></img>privacy policy
          </p>
          <h2>Privacy policy</h2>
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing
            <br /> and typesetting industry.{" "}
          </p> */}
        </Container>
      </section>
      <Container>
        <div className="privay-content">
          <p className="mt-4">
            Realzon.net hereby grants you access to http://Realzon.net (“the
            Website”) and invites you to purchase the services offered here.
          </p>
          <p>
            Realzon is not an auctioneer. Realzon is a platform for Sellers and
            Agents/Brokers to market properties in many ways and for customers
            to place bids or offers on the properties marketed on Realzon.
            Realzon does not own or control our customers listing contact or
            information or make any representations on its accuracy or validity.
            We are not involved in the actual transaction between Buyers and
            Sellers. Realzon has no control over the ability of a prospective
            Buyer to close on the transaction.
          </p>

          <h6>Definitions and key terms</h6>
          <p>
            To help explain things as clearly as possible in this Disclaimer,
            every time any of these terms are referenced, are strictly defined
            as:
          </p>
          <ul>
            <li>
              <b>Cookie:</b> small amount of data generated by a website and
              saved by your web browser. It is used to identify your browser,
              provide analytics, remember information about you such as your
              language preference or login information.
            </li>
            <li>
              <b>Company:</b> this policy mentions “Company,” “we,” “us,” or
              “our,” it refers to Realzon, Inc., (1412 Broadway, Suite 1606, New
              York, New York 10018) that is responsible for your information
              under this Privacy Policy.
            </li>
            <li>
              <b>Country: </b> where Realzon or the owners/founders of Realzon
              are based, in this case is United States.
            </li>
            <li>
              <b>Customer: </b> refers to the company, organization or person
              that signs up to use the Realzon Service to manage the
              relationships with your consumers or service users.
            </li>
            <li>
              <b>Device: </b> any internet connected device such as a phone,
              tablet, computer or any other device that can be used to visit
              Realzon and use the services.
            </li>
            <li>
              <b>IP address: </b> Every device connected to the Internet is
              assigned a number known as an Internet protocol (IP) address.
              These numbers are usually assigned in geographic blocks. An IP
              address can often be used to identify the location from which a
              device is connecting to the Internet.
            </li>
            <li>
              <b>Personnel: </b> refers to those individuals who are employed by
              Realzon or are under contract to perform a service on behalf of
              one of the parties .
            </li>
            <li>
              <b>Personal Data: </b> any information that directly, indirectly,
              or in connection with other information — including a personal
              identification number — allows for the identification or
              identifiability of a natural person.
            </li>
            <li>
              <b>Service: </b> refers to the service provided by Realzon as
              described in the relative terms (if available) and on this
              platform.
            </li>
            <li>
              <b>Third-party service: </b> refers to advertisers, contest
              sponsors, promotional and marketing partners, and others who
              provide our content or whose products or services we think may
              interest you.
            </li>
            <li>
              <b>Website: </b> Realzon’s site, which can be accessed via this
              URL: http://Realzon.net
            </li>
            <li>
              <b>You: </b> a person or entity that is registered with Realzon to
              use the Services.
            </li>
          </ul>

          <h6>Limited liability</h6>
          <p>
            Realzon.net endeavours to update and/or supplement the content of
            the website on a regular basis. Despite our care and attention,
            content may be incomplete and/or incorrect.
          </p>
          <p>
            The materials offered on the website are offered without any form of
            guarantee or claim to their correctness. These materials can be
            changed at any time without prior notice from Realzon.net.
          </p>
          <p>
            Particularly, all prices on the website are stated subject to typing
            and programming errors. No liability is assumed for the implications
            of such errors. No agreement is concluded on the basis of such
            errors.
          </p>
          <p>
            Realzon.net shall not bear any liability for hyperlinks to websites
            or services of third parties included on the website. From our
            website, you can visit other websites by following hyperlinks to
            such external sites. While we strive to provide only quality links
            to useful and ethical websites, we have no control over the content
            and nature of these sites. These links to other websites do not
            imply a recommendation for all the content found on these sites.
            Site owners and content may change without notice and may occur
            before we have the opportunity to remove a link which may have gone
            ‘bad’.
          </p>
          <p>
            Please be also aware that when you leave our website, other sites
            may have different privacy policies and terms which are beyond our
            control. Please be sure to check the Privacy Policies of these sites
            as well as their "Terms of Service" before engaging in any business
            or uploading any information.
          </p>

          <h6>Links to Other Websites Disclaimer</h6>
          <p>
            This Disclaimer applies only to the Services. The Services may
            contain links to other websites not operated or controlled by
            Realzon.net. We are not responsible for the content, accuracy or
            opinions expressed in such websites, and such websites are not
            investigated, monitored or checked for accuracy or completeness by
            us. Please remember that when you use a link to go from the Services
            to another website, our Privacy Policy is no longer in effect. Your
            browsing and interaction on any other website, including those that
            have a link on our platform, is subject to that website’s own rules
            and policies. Such third parties may use their own cookies or other
            methods to collect information about you. If You click on a third
            party link, You will be directed to that third party's site. We
            strongly advise You to review the Privacy Policy and Terms of every
            site You visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>

          <h6>Errors and Omissions Disclaimer</h6>
          <p>
            Realzon.net is not responsible for any content, code or any other
            imprecision.
          </p>
          <p>Realzon.net does not provide warranties or guarantees.</p>
          <p>
            In no event shall Realzon.net be liable for any special, direct,
            indirect, consequential, or incidental damages or any damages
            whatsoever, whether in an action of contract, negligence or other
            tort, arising out of or in connection with the use of the Service or
            the contents of the Service. Realzon.net reserves the right to make
            additions, deletions, or modifications to the contents on the
            Service at any time without prior notice.
          </p>

          <h6>General Disclaimer</h6>
          <p>
            The Realzon.net Service and its contents are provided "as is" and
            "as available" without any warranty or representations of any kind,
            whether express or implied. Realzon.net is a distributor and not a
            publisher of the content supplied by third parties; as such,
            Realzon.net exercises no editorial control over such content and
            makes no warranty or representation as to the accuracy, reliability
            or currency of any information, content, service or merchandise
            provided through or accessible via the Realzon.net Service. Without
            limiting the foregoing, Realzon.net specifically disclaims all
            warranties and representations in any content transmitted on or in
            connection with the Realzon.net Service or on sites that may appear
            as links on the Realzon.net Service, or in the products provided as
            a part of, or otherwise in connection with, the Realzon.net Service,
            including without limitation any warranties of merchantability,
            fitness for a particular purpose or non-infringement of third party
            rights. No oral advice or written information given by Realzon.net
            or any of its affiliates, employees, officers, directors, agents, or
            the like will create a warranty. Price and availability information
            is subject to change without notice. Without limiting the foregoing,
            Realzon.net does not warrant that the Realzon.net Service will be
            uninterrupted, uncorrupted, timely, or error- free.
          </p>

          <h6>Copyright Disclaimer</h6>
          <p>
            All intellectual property rights concerning these materials are
            vested in Realzon.net. Copying, distribution and any other use of
            these materials is not permitted without the written permission of
            Realzon.net, except and only to the extent otherwise provided in
            regulations of mandatory law (such as the right to quote), unless
            otherwise stated for certain materials.
          </p>

          <h6>Affiliate Links Disclosure</h6>
          <p>
            Realzon.net has affiliate links and in this section of the
            Disclaimer we will address how we use those affiliate links from
            other websites/companies and products. These “affiliate links” are
            specific URLs that contain the affiliate's ID or username.
          </p>
          <p>
            In compliance with the FTC guidelines, please assume the following
            about links and posts on this site:
          </p>
          <ul>
            <li>
              Any/all of the links on Realzon.net are affiliate links of which
              we receive a small commission from sales of certain items, but the
              price is the same for you. As Realzon.net has grown, so have costs
              associated with running and maintaining it, and affiliate links
              are a way we help offset these costs.
            </li>
            <li>
              If we post an affiliate link to a product, it is something that we
              personally use, support and would recommend without an affiliate
              link.
            </li>
            <li>
              Unless otherwise noted, all reviews are of items we have purchased
              and we are not paid or compensated in any way.
            </li>
            <h6>Advertising Disclosure</h6>
            <p>
              This website may contain third party advertisements and links to
              third party sites. Realzon.net does not make any representation as
              to the accuracy or suitability of any of the information contained
              in those advertisements or sites and does not accept any
              responsibility or liability for the conduct or content of those
              advertisements and sites and the offerings made by the third
              parties.
            </p>
            <p>
              Advertising keeps Realzon.net and many of the websites and
              services you use free of charge. We work hard to make sure that
              ads are safe, unobtrusive, and as relevant as possible.
            </p>
            <p>
              Third party advertisements and links to other sites where goods or
              services are advertised are not endorsements or recommendations by
              Realzon.net of the third party sites, goods or services.
              Realzon.net takes no responsibility for the content of any of the
              ads, promises made, or the quality/reliability of the products or
              services offered in all advertisements.
            </p>

            <h6>Testimonials Disclosure</h6>
            <p>
              Any testimonials provided on this platform are opinions of those
              providing them. The information provided in the testimonials is
              not to be relied upon to predict results in your specific
              situation. The results you experience will be dependent on many
              factors including but not limited to your level of personal
              responsibility, commitment, and abilities, in addition to those
              factors that you and/or Realzon.net may not be able to anticipate.
            </p>
            <p>
              We will give honest testimonials to our visitors regardless of any
              discount. Any product or service that we test are individual
              experiences, reflecting real life experiences. The testimonials
              could be displayed on audio, text or video and are not necessarily
              representative of all of those who will use our products and/or
              services.
            </p>
            <p>
              Realzon.net does not guarantee the same results as the
              testimonials given on our platform. Testimonials presented on
              Realzon.net are applicable to the individuals writing them, and
              may not be indicative of future success of any other individuals
            </p>
            <p>
              Please don’t hesitate to contact us if you would like to know more
              about testimonials, discounts, or any of the products/services
              that we review.
            </p>
          </ul>
          <h6>Your Consent</h6>
          <p>
            We've updated our Disclaimer to provide you with complete
            transparency into what is being set when you visit our site and how
            it's being used. By using our website, registering an account, or
            making a purchase, you hereby consent to our Disclaimer and agree to
            its terms.
          </p>
          <h6>Changes To Our Disclaimer</h6>
          <p>
            Should we update, amend or make any changes to this document so that
            they accurately reflect our Service and policies. Unless otherwise
            required by law, those changes will be prominently posted here.
            Then, if you continue to use the Service, you will be bound by the
            updated Disclaimer. If you do not want to agree to this or any
            updated Disclaimer, you can delete your account.
          </p>
          <h6>Contact Us</h6>
          <p>
            Don't hesitate to contact us if you have any questions regarding
            this Disclaimer.
          </p>
          <ul>
            <li>Via Email: info@Realzon.net</li>
          </ul>
        </div>
      </Container>
    </>
  );
};
export default Privacypolicy;
