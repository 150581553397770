import React, { useState } from "react";
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";

const Faq = () => {
  return (
    <>
      <section className="hero-banner  about-bg">
        <Container>
          <p className="property-banner-heading">
            <img src={require("../assets/images/play.png")}></img>FAq’s
          </p>
          <h2>Frequently asked questions</h2>
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing
            <br /> and typesetting industry.{" "}
          </p> */}
        </Container>
      </section>
      <section className="faq-area-box">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>What is Realzon?</Accordion.Header>
            <Accordion.Body>
            REALZON is real estate platform that helps buyers and sellers find both auction and MLS listings in the
              New York City area.
              Key Features:
              <ul>
                <li>Remote bid; Register and bid on bank owned properties from your device. </li>
                <li>In person Auction ; Register and get all auction dates, court Location, Type of foreclosure, lien
                  amount and all details in all 5 boroughs.</li>
                <li>Access your favorite or saved properties from any device when you log in to your profile.</li>
                <li>Review property details, photos, map location on property detail page.</li>
                <li>Receive push notifications for all types of auction status or news for your favorite properties.
                </li>
                <li>Reach Brokers and Agents for more information or help.</li>
                <li>Access exclusive MLS properties in all 5 boroughs. </li>
                <li>Register to list your own property.</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>   How do I subscribe?</Accordion.Header>
            <Accordion.Body>
            You can learn more about our membership benefits and how to subscribe by clicking on this <a href="https://realzon.ecomempire.in" target="_blank">link</a>.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>What is the membership fee? What is included?</Accordion.Header>
            <Accordion.Body>
            Realzon Membership offers access to all Auction/Foreclosure and bank owned property details. Members can
              Bid online as well as create their own profile to list their own properties or choose if they would like
              to work with Broker or Agent.<br />

              To learn more please click on this <a href="https://realzon.ecomempire.in" target="_blank">link</a>.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>What types of properties are available for sale on realzon.net?</Accordion.Header>
            <Accordion.Body>
            We have exclusive MLS properties and Foreclosure, Auction and Bank owned properties in NYC.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Who provides the property details?</Accordion.Header>
            <Accordion.Body>
            The information for each property comes directly from the Seller. In some cases public records are used in the property details, such as square footage of a building or lot size. It is the buyers responsibility to verify all information.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Do I need to register to Bid online?</Accordion.Header>
            <Accordion.Body>
            Yes, you will need to register plus verify your credentials to make an offer.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>Why do you verify my information?</Accordion.Header>
            <Accordion.Body>
            As an online real estate market place, its imperative that REALZON knows our sellers and buyers are valid parties who can be contacted during the bidding, offer and closing process. Verifying protects everyone in this transaction.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>What do you do with the information I enter?</Accordion.Header>
            <Accordion.Body>
            The information is used to verify your identity. 
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>What is the “Favorite” feature?</Accordion.Header>
            <Accordion.Body>
            The “Favorite” found on the property details page keeps you updated about changes to property status with real time email notifications.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>What is the difference between Foreclosure/Auction and Bank Owned properties?</Accordion.Header>
            <Accordion.Body>
            Foreclosure/Auction properties are properties that are sold at live public auction after the lender has gone through the necessary legal steps to engage in the sale of the property.
             <br /> Bank Owned properties are owned by a bank or other financial institution. The bank may have assumed ownership of the property after foreclosure.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>Do I need a Real Estate Broker/Agent to bid/offer on property?</Accordion.Header>
            <Accordion.Body>
            No, you can place a bid on REALZON with or without Broker/agent. Please include agent information, if any, on your bid to ensure they receive their commission.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header>What does MLS stand for? </Accordion.Header>
            <Accordion.Body>
            MLS is Multiple Listing Service – it is a database established by cooperating real estate brokers and agents to provide data about exclusive properties for sale. An MLS allows Brokers to see one another’s listings of properties for sale with the goal of connecting Homebuyers to sellers.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header>Do I need to register for membership to see MLS properties?</Accordion.Header>
            <Accordion.Body>
            No, our MLS “regular” properties are available for Free.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13">
            <Accordion.Header>What is the “list price”?</Accordion.Header>
            <Accordion.Body>
            “List price” is the price the seller believes their property is worth. 
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="14">
            <Accordion.Header>What is E-signature?</Accordion.Header>
            <Accordion.Body>
            An electronic signature, or e-signature, is data that is logically associated with other data and which is used by the signatory to sign the associated data. 
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="15">
            <Accordion.Header>What is DocuSign?</Accordion.Header>
            <Accordion.Body>
            DocuSign Is leading company of web-based for E-signatures. Technology that facilitates exchanges of contracts, documents all legal materials. DocuSign keeping track of all documents by eliminating the need for the mailing of paper contracts.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="16">
            <Accordion.Header>What is  Foreclosure Auction?</Accordion.Header>
            <Accordion.Body>
            Foreclosure Auction are live, in-person auctions to purchase properties when an owner has defaulted on the mortgage loan. Foreclosure auctions typically occur at the courthouses in the country where the property is located.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="17">
            <Accordion.Header>How to contact Us?</Accordion.Header>
            <Accordion.Body>
            You can contact us by E-mailing to info@realzon.net
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="18">
            <Accordion.Header>Can I cancel my membership?</Accordion.Header>
            <Accordion.Body>
            Yes, you can cancel your monthly membership at anytime. However if you prepaid for the first year, cancellation will only become effective at the end of the membership period.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>

    </>
  );
};
export default Faq;
