import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SuccessModal from "../common/SuccessModal";
import { context } from "../GlobalContext/GlobalContext";
import { ChangePassword } from "../Service/Service";
import {toast} from 'react-toastify'
const Change_password = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); navigate('/my_profile') }
    const handleShow = () => setShow(true);
    const {register , handleSubmit , formState:{errors} , watch}=useForm()
    let navigate = useNavigate();
    let globalData=useContext(context)
    const onSubmit=data=>{
        const payload={
            "old_password":data.old,
            "new_password":data.new,
            "confirm_password":data.confirm
        }
        ChangePassword(globalData.token , payload).then(res=>{
            if(res.status===200){
                handleShow()
            }
        }).catch(err=>{
            if(err.response.status===400){
                 toast.info(err.response.data.message)
            }
        })
    }
   
    return (
        <>
            <section className="login-area forgot-area">
                <div className="container">
                    <div className="login-box-area">
                        <div className="row justify-content-center">
                            <aside className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                                <div className="login-box">
                                    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                                        <h2 className="text-center">Change Password </h2>

                                        <div className="my-4 pt-3">
                                            <label  className="form-label">Old password</label>
                                            <input type="password" className="form-control" id="" placeholder="Enter old password" {...register('old',{required:"Old password is required."})} />
                                            {errors.old && <span style={{color:"red"}}>{errors.old.message}</span>}
                                        </div>
                                        <div className="my-4">
                                            <label  className="form-label">New password</label>
                                            <input type="password" className="form-control" id="" placeholder="Enter new password" {...register('new',{required:"New password is required."})} />
                                            {errors.new && <span style={{color:"red"}}>{errors.new.message}</span>}
                                        </div>
                                        <div className="my-4">
                                            <label  className="form-label">Confirm password</label>
                                            <input type="password" className="form-control" id="" placeholder="Enter confirm password" {...register('confirm',{required:"Confirm password is required." , validate:v=>watch('new')==v || "Password didn't match"})} />
                                            {errors.confirm && <span style={{color:"red"}}>{errors.confirm.message}</span>}
                                        </div>
                                        <button className="btn sign-btn fill-btn w-100 ms-0 mt-3 mb-4 text-white" >change password</button>
                                        <button onClick={()=>navigate('/my_profile')} className="subscription-btn mb-5 mt-0 w-100">
                                            <p>back to profile</p>
                                        </button>
                                    </form>

                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <SuccessModal show={show}  onHide={()=>handleClose()} message="Password reset successfully." />
        </>
    )
}
export default Change_password;